import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import bgimg from "../../assets/ProfileCardimg/bg.jpg";
import profile from "../../assets/ProfileCardimg/profile-1.jpg.avif";
import icon1 from "../../assets/icons/adpatient-1.svg";
import icon2 from "../../assets/icons/addpatient-2.svg";
import sidevector from "../../assets/sidevector.svg";
import { translations } from "../../translation/translate";

export default function AddPatient() {
  const langData = useSelector((state) => state.langSlice.langData);
  const navigate = useNavigate();

  const handlePatientClick = () => {
    navigate("/add-patientdetails");
  };

  return (
    <div className="flex flex-row items-center w-full min-h-screen justify-between relative px-[5%]">
      {/* Title Section */}
      <div className="flex flex-row text-center mb-[20px] gap-5">
        <div className="font-poppins text-[48.08px] font-semibold leading-[72.12px] text-[#845BB3]">
          WE PROVE
        </div>
        <div className="font-poppins text-[48.08px] font-semibold leading-[72.12px] text-[#8E3D0A]">
          FASTER REPORTS
        </div>
      </div>

      {/* Profile Card */}
      <div className="flex flex-col mt-6 z-10">
        <div className="h-[596px] bg-[#ffffff] rounded-3xl shadow-md relative">
          <div className="relative">
            <img
              className="w-[360px] h-[202px] m-[10px] object-cover rounded-3xl"
              src={bgimg}
              alt="Background"
            />
          </div>

          {/* Profile Image */}
          <div className="absolute w-[184.59px] h-[174.59px] top-[152.58px] left-1/2 transform -translate-x-1/2 border-[#845BB3] rounded-full overflow-hidden border-4 shadow-md">
            <img
              className="object-cover w-full h-full"
              src={profile}
              alt="Profile"
            />
          </div>

          {/* Patient Details */}
          <div className="absolute top-[345px] left-1/2 transform -translate-x-1/2 space-y-1 text-center">
            <div className="flex flex-col items-center space-y-2">
              <div className="font-poppins text-[23.47px] font-semibold leading-[35.21px] text-[#845BB3]">
                Sample
              </div>

              <div className="flex items-center space-x-2 font-poppins text-[17.61px] font-semibold leading-[26.41px] text-[#845BB3]">
                <span>{translations[langData].age}:</span>
                <div>{21}</div>
              </div>

              <div className="font-poppins truncate max-w-[250px] text-[14.67px] font-semibold leading-[22.01px] text-[#845BB3] space-y-1">
                <div className="flex items-center justify-center space-x-2">
                  <span className="text-[14.67px] font-semibold leading-[22px] text-[#845BB3]">
                    {translations[langData].gender}:
                  </span>
                  <div className="text-[14.67px] font-semibold leading-[22px] text-[#845BB3]">
                    {"Male"}
                  </div>
                </div>

                <div className="flex items-center justify-center space-x-2">
                  <span className="text-[14.67px] font-semibold leading-[22px] text-[#845BB3]">
                    {translations[langData].email}
                  </span>
                  <div className="text-[14.67px] font-semibold leading-[22px] text-[#845BB3]">
                    {"abhishekshaxxxxx@gmail.com"}
                  </div>
                </div>

                <div className="flex items-center justify-center space-x-2">
                  <span className="text-[14.67px] font-semibold leading-[22px] text-[#845BB3]">
                    {translations[langData].patientid}:
                  </span>
                  <div className="text-[14.67px] font-semibold leading-[22px] text-[#845BB3]">
                    {"ddj12345678"}
                  </div>
                </div>

                <div className="flex items-center justify-center space-x-2">
                  <span className="text-[14.67px] font-semibold leading-[22px] text-[#845BB3]">
                    {translations[langData].phone}
                  </span>
                  <div className="text-[14.67px] font-semibold leading-[22px] text-[#845BB3]">
                    {"99999xxxx99"}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Button */}
          <div className="absolute bottom-6 left-1/2 transform -translate-x-1/2 flex justify-center items-center w-full">
            <button
              onClick={handlePatientClick}
              className="bg-[#845BB3] w-[250px] h-[40px] text-[20px] leading-[30px] text-white py-2 px-2 rounded-md shadow-md hover:bg-[#6b3e9f]"
            >
              {translations[langData].addPatient}
            </button>
          </div>
        </div>

        <div className="relative flex justify-center items-center mt-4">
          {/* ----------System Button----- */}
          {/* <img
            className="w-[60px] h-[60px] mb-[10px] object-cover rounded-tl-[12px] rounded-tr-[12px] rounded-bl-[12px] rounded-br-[12px]"
            src={icon1}
            alt="Icon 1"
          /> */}
          <Link to="/faq">
            <img
              className="w-[60px] h-[60px] mb-[10px] object-cover rounded-tl-[12px] rounded-tr-[12px] rounded-bl-[12px] rounded-br-[12px]"
              src={icon2}
              alt="Icon 2"
            />
          </Link>
        </div>
      </div>

      {/* Background Image */}
      <div className="absolute bottom-0 z-0 right-0 w-full lg:w-auto">
        <img src={sidevector} alt="sidevector" className="w-full lg:w-auto" />
      </div>
    </div>
  );
}
