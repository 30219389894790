import React, { useState, useEffect, useRef } from "react";
import reload from "../../assets/icons/reload.svg";
import beaker1 from "../../assets/icons/beaker-1.svg";
import beaker2 from "../../assets/icons/beaker-2.svg";
import report from "../../assets/icons/report.svg";
import compass from "../../assets/icons/compass.svg";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addTests, addReferedBy } from "../../Redux_Services/slices/testSlice";
import axios from "axios";
import CoughModal from "../../Components/CoughModal";
import CameraModal from "../../Components/CameraModal";
import { translations } from "../../translation/translate";
import CoughTestModal from "./CoughTestModal";
import { toast } from "react-toastify";

const GeneralTest = () => {
  const [testCodeFields, setTestCodeFields] = useState([]);
  const dispatch = useDispatch();
  const tests = useSelector((state) => state.testSlice.testsData || []);
  console.log("____________________ tests", tests);
  const referedBy = useSelector((state) => state.testSlice.referedBy || "");
  const langData = useSelector((state) => state.langSlice.langData || []);
  const loginUser = useSelector((state) => state.authSlice.user);
  console.log("__login", loginUser);

  const [testData, setTestData] = useState(tests);
  const [referby, setReferby] = useState(referedBy);
  const [results, setResults] = useState([]);
  const [hbA1cValue, setHbA1cValue] = useState(null);
  const hdlValue = useRef();
  const ldlValue = useRef();
  const [hdlldlvalue, setHdlldlvalue] = useState(0);
  const [eAGValue, setEAGValue] = useState(0);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState(null); // Track which modal to open
  const [capturedImage, setCapturedImage] = useState(null); // State to hold captured image
  const [loading, setLoading] = useState(false);

  const handleCapture = (image) => {
    setCapturedImage(image);
    // Optionally, you can do something with the captured image here,
    // like sending it to a server or displaying it elsewhere
    console.log("Captured Image:", image);
  };

  const openModal = (fieldName) => {
    setActiveModal(fieldName);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setActiveModal(null); // Reset active modal when closing
  };
  const machineId = loginUser.MachineID;
  console.log("machineId", machineId);
  useEffect(() => {
    setTestData(Array.isArray(tests) ? tests : []);
    setReferby(referedBy || "");
  }, [tests, referedBy]);

  // useEffect(() => {
  //   setHdlldlvalue((hdlValue / ldlValue).toFixed(2));
  //   const updatedTestData = testData.map((test) => ({
  //     ...test,
  //     fields: test.fields.map((field) => {
  //       if (field.fieldName === "HDL/LDL") {
  //         return { ...field, value: hdlldlvalue };
  //       }
  //       return field;
  //     }),
  //   }));
  //   setTestData(updatedTestData);
  // }, [hdlValue, ldlValue, hdlldlvalue]);
  console.log("74 = ", eAGValue);

  const calEAG = (data) => {
    const eAG = Math.round(28.7 * +data - 46.7).toFixed(2);
    setEAGValue(eAG);
    console.log("94", data);
    const updatedTestData = testData.map((test) => ({
      ...test,
      fields: test.fields.map((field) => {
        field.subFields.map((subField) => {
          if (subField.fieldName === "eAG") {
            return { ...field, value: eAG };
          }
          return subField;
        });
        return field;
      }),
    }));
    setTestData(updatedTestData);
  };

  const handleInputChange = (sectionIndex, fieldIndex, value) => {
    const updatedTestData = testData.map((test, sIndex) => {
      if (sIndex === sectionIndex) {
        return {
          ...test,
          fields: test.fields.map((field, fIndex) => {
            if (field.fieldName === "HDL/LDL") {
              const val = (hdlValue.current / ldlValue.current).toFixed(2);
              return {
                ...field,
                value: val,
              };
            } else if (fIndex === fieldIndex) {
              return {
                ...field,
                value: value,
              };
            }
            return field;
          }),
        };
      }
      return test;
    });
    setTestData(updatedTestData);
  };
  const handleSubTestInputChange = (
    sectionIndex,
    fieldIndex,
    subFieldIndex,
    value
  ) => {
    const updatedTestData = testData.map((test, sIndex) => {
      if (sIndex === sectionIndex) {
        return {
          ...test,
          fields: test.fields.map((field, fIndex) => {
            if (
              fIndex === fieldIndex &&
              field.subFields &&
              Array.isArray(field.subFields)
            ) {
              return {
                ...field,
                subFields: field.subFields.map((subField, subIndex) => {
                  if (subField.fieldName === "eAG") {
                    const val = Math.round(28.7 * +value - 46.7).toFixed(2);
                    return {
                      ...subField,
                      value: val,
                    };
                  } else if (subField.fieldName === "HDL/LDL") {
                    const val = (hdlValue.current / ldlValue.current).toFixed(
                      2
                    );
                    return {
                      ...subField,
                      value: val,
                    };
                  } else if (subIndex === subFieldIndex) {
                    return {
                      ...subField,
                      value: value,
                    };
                  }
                  return subField;
                }),
              };
            }
            return field;
          }),
        };
      }
      return test;
    });

    setTestData(updatedTestData);
  };

  //  console.log("testData = " , testData.fields[0].fieldName)
  // console.log("92 = ", testData[0].fields[0].subFields);
  const handleReferBy = (e) => {
    setReferby(e.target.value);
  };

  const fieldIds = testData.flatMap((test) =>
    test.fields.map((field) => field.fieldId)
  );

  let fieldName1 = false;
  tests[0].fields.map((field) => {
    if (field.fieldName === "Complete Blood Count") {
      fieldName1 = true;
    }
  });
  console.log(fieldName1);
  const handlePreviousResults = async () => {
    setLoading(true);
    try {
      console.log("testData = ", testData);

      if (fieldName1 !== true) {
        const test_codes = [];

        // Collect all valid fieldIds from testData
        testData.forEach((test) => {
          if (test.fields && Array.isArray(test.fields)) {
            test.fields.forEach((field) => {
              // Add the fieldId if it exists and is valid
              if (field.fieldId && field.fieldId.trim() !== "") {
                test_codes.push(field.fieldId);
              }

              // Check for subFields and add their fieldId if they exist
              if (field.subFields && Array.isArray(field.subFields)) {
                field.subFields.forEach((subField) => {
                  if (subField.fieldId && subField.fieldId.trim() !== "") {
                    test_codes.push(subField.fieldId);
                  }
                });
              }
            });
          }
        });

        console.log("test_codes", test_codes);

        try {
          // API call to fetch test results
          const response = await axios.post(
            `https://api.superceuticals.in/test-two/get-latest-legacy-test/${machineId}`,
            { test_codes },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoiVEVTVE5PVyIsIlBhdGllbnRJRCI6IlNDMjNGMDAwMDAyIiwiY29udGFjdE51bWJlciI6Ijg4MDU5OTEwNzkiLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE3MjcxMDQzMTd9.rW70KCsDPR9DU8SDRPDxtTFcX2mT2nb_1lZoYgoVzEk",
              },
            }
          );

          console.log("211", response.data);

          const data = response.data.getTest || []; // Ensure `getTest` exists

          // Update testData fields with the new result values
          const updatedFields = testData.map((test) => {
            if (!test.fields || !Array.isArray(test.fields)) {
              console.error("Invalid fields in test:", test);
              return test; // Return the test unchanged if fields are invalid
            }

            const updatedTestFields = test.fields.map((field) => {
              // Clone the field to avoid direct mutation
              const updatedField = { ...field };

              // Iterate through the API response data

              data.forEach((testItem) => {
                console.log("testItem", testItem);

                if (Array.isArray(testItem.mydata)) {
                  testItem.mydata.forEach((testDataItem) => {
                    // Check if fieldId matches test_code from API response
                    if (updatedField.fieldId === testDataItem.test_code) {
                      console.log("testDataItem.result", testDataItem.result);
                      updatedField.value = testDataItem.result; // Update the field's value
                      console.log("updatedField.value", updatedField.value);

                      // Update the state while preserving the existing values
                      setTestCodeFields((prevFields) => [
                        ...prevFields,
                        {
                          fieldName: updatedField.fieldName,
                          testCode: testDataItem.result,
                        },
                      ]);
                    }
                  });
                }
              });

              // Update subFields if present
              if (
                updatedField.subFields &&
                Array.isArray(updatedField.subFields)
              ) {
                updatedField.subFields = updatedField.subFields.map(
                  (subField) => {
                    const updatedSubField = { ...subField };
                    data.forEach((testItem) => {
                      if (Array.isArray(testItem.mydata)) {
                        testItem.mydata.forEach((testDataItem) => {
                          if (
                            updatedSubField.fieldId === testDataItem.test_code
                          ) {
                            updatedSubField.value = testDataItem.result; // Update subField value
                          }
                        });
                      }
                    });
                    return updatedSubField;
                  }
                );
              }

              return updatedField;
            });
            console.log("290", updatedTestFields);

            return {
              ...test,
              fields: [...updatedTestFields], // Update the test's fields
            };
          });

          // Update the state with the modified testData
          setTestData([...updatedFields]);
          console.log("305", updatedFields);

          // Extract specific result (e.g., HbA1cValue)
          const hbA1cResult =
            response.data.getTest[0]?.mydata[0]?.result || null;
          if (hbA1cResult) {
            setHbA1cValue(hbA1cResult);
            calEAG(hbA1cResult); // Perform additional calculations
          }
        } catch (error) {
          console.error("Error fetching or updating test data:", error);
        }
      } else {
        const response = await axios.get(
          "https://api.superceuticals.in/test-one/get-latest-legacy-test",

          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoiVEVTVE5PVyIsIlBhdGllbnRJRCI6IlNDMjNGMDAwMDAyIiwiY29udGFjdE51bWJlciI6Ijg4MDU5OTEwNzkiLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE3MjcxMDQzMTd9.rW70KCsDPR9DU8SDRPDxtTFcX2mT2nb_1lZoYgoVzEk",
            },
          }
        );

        const data = response.data;
        console.log("Full data:", data);

        if (Array.isArray(data.getTest) && data.getTest.length > 0) {
          const resultMap = {};

          // Extracting results into a map for quick lookup
          data.getTest.forEach((testItem) => {
            if (Array.isArray(testItem.mydata) && testItem.mydata.length > 0) {
              testItem.mydata.forEach((testDataItem) => {
                const testCode = testDataItem.test_code;
                const result = testDataItem.result;
                resultMap[testCode] = result;
              });
            }
          });

          // Updating testData

          const updatedTestData = testData.map((test) => {
            return {
              ...test,
              fields: test.fields.map((field) => {
                return {
                  ...field,
                  subFields: field.subFields.map((subField) => {
                    if (resultMap[subField.fieldId]) {
                      return {
                        ...subField,
                        value: resultMap[subField.fieldId], // Update value from resultMap
                      };
                    }
                    return subField;
                  }),
                };
              }),
            };
          });
          console.log(updatedTestData);
          setTestData(updatedTestData); // Set the updated test data
          setResults(Object.values(resultMap)); // Update results if needed
        } else {
          console.log("269");
          toast.error("No previous results found", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      }
    } catch (error) {
      console.log("274");
      console.error("Error fetching previous results:", error);
      toast.error("No previous results found", {
        position: toast.POSITION.TOP_CENTER,
      });
      if (error.response) {
        console.error("Server responded with:", error.response.data);
      }
      toast.error("No previous results found", {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setLoading(false); // Hide loader
    }
  };

  console.log("282", testCodeFields);

  const handleLatestResult = async () => {
    console.log("Fetching latest results...");
    setLoading(true);
    try {
      const test_codes = fieldIds;
      console.log("test codes:", test_codes);

      const response = await axios.post(
        "https://api.superceuticals.in/test-two/get-latest-test/MC0001",
        { test_codes: test_codes },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoiVEVTVE5PVyIsIlBhdGllbnRJRCI6IlNDMjNGMDAwMDAyIiwiY29udGFjdE51bWJlciI6Ijg4MDU5OTEwNzkiLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE3MjcxMDQzMTd9.rW70KCsDPR9DU8SDRPDxtTFcX2mT2nb_1lZoYgoVzEk",
          },
        }
      );

      const data = response.data;

      if (Array.isArray(data.getTest) && data.getTest.length > 0) {
        const resultMap = {};

        data.getTest.forEach((testItem) => {
          if (Array.isArray(testItem.mydata) && testItem.mydata.length > 0) {
            const testDataItem = testItem.mydata[0];
            const testCode = testDataItem.test_code;
            const result = testDataItem.result;
            resultMap[testCode] = result;
          }
        });

        const updatedTestData = testData.map((test) => {
          return {
            ...test,
            fields: test.fields.map((field) => {
              if (field.code && resultMap[field.code]) {
                return {
                  ...field,
                  value: resultMap[field.code] || field.value,
                };
              }
              return field;
            }),
          };
        });

        setTestData(updatedTestData);
        setResults(Object.values(resultMap));
      } else {
        toast.error("No previous results found", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      console.error("Error fetching latest results:", error);
      if (error.response) {
        console.error("Server responded with:", error.response.data);
      }
      alert("Failed to fetch latest results.");
    } finally {
      setLoading(false); // Hide loader
    }
  };

  const handleSave = () => {
    dispatch(addTests(testData));
    dispatch(addReferedBy(referby));
    navigate("/tests/testId/report");
  };

  function removeNumericWords(inputString) {
    return inputString.replace(/[^a-zA-Z/%]+/g, " ").trim();
  }

  const isAllFieldsFilled =
    testData.every((test) =>
      test.fields.every((field) => {
        // Check if the main field value is filled
        const isFieldFilled =
          field.value !== "" &&
          field.value !== undefined &&
          field.value !== null;

        // Check if subfields are present and their values are filled
        const areSubfieldsFilled =
          Array.isArray(field.subFields) &&
          field.subFields.every((subField) => {
            if (subField.fieldName === "eAG") {
              // Special case for 'eAG'
              return (
                subField.value === "" ||
                subField.value !== undefined ||
                subField.value !== null
              );
            }
            // General case for other subfields
            return (
              subField.value !== "" &&
              subField.value !== undefined &&
              subField.value !== null
            );
          });

        console.log(isFieldFilled, "Filled fields");
        console.log(areSubfieldsFilled, "Subfields");

        // Return true if either the main field or subfields are filled
        return isFieldFilled || areSubfieldsFilled;
      })
    ) && referby.trim() !== "";

  console.log(isAllFieldsFilled, "Is All Fields Filled?");

  // Corrected logic to include referby check

  return (
    <div className="flex flex-col overflow-x-hidden items-center justify-center h-screen w-screen m-0">
      <div className="flex flex-col w-[80%] h-[85%] bg-white rounded-2xl my-5 mx-auto">
        <div className="relative w-full h-[15%] bg-[#845BB3] rounded-t-2xl flex items-center justify-center">
          <div className="font-poppins text-2xl font-semibold text-white">
            {translations[langData].latestResult}
          </div>
        </div>
        <div className="w-full h-[25%] max-h-16 flex overflow-hidden">
          <div className="flex-grow-[4] border-r border-b border-[#845BB3] flex justify-center items-center">
            <img src={beaker1} alt="icon" className="w-7 h-7 mr-2" />
            <p className="text-center"> {translations[langData].testName}</p>
          </div>
          <div className="flex-grow border-r border-b border-[#845BB3] flex justify-center items-center">
            <img src={beaker2} alt="icon" className="w-6 h-6 mr-2" />
            <p className="text-center"> {translations[langData].result}</p>
          </div>
          <div className="flex-grow border-b border-[#845BB3] flex justify-center items-center">
            <img src={compass} alt="icon" className="w-6 h-6 mr-2" />
            <p className="text-center">
              {" "}
              {translations[langData].unitAndRange}
            </p>
          </div>
        </div>
        <div className="flex-grow w-full flex flex-col gap-4 p-4 overflow-y-auto">
          {testData.map((test, sectionIndex) => (
            <div key={sectionIndex} className="w-full flex flex-col gap-4">
              <div className="flex items-center mb-4">
                <button className="bg-[#D7D4F8] rounded-md text-center px-4 py-2 font-poppins font-semibold text-[#845BB3] text-xl">
                  {test.testFieldName}
                </button>
              </div>
              <div className="w-full flex flex-col gap-4">
                {test.fields &&
                  Array.isArray(test.fields) &&
                  test.fields.map((field, index) => {
                    // Skip rendering the field if fieldId is "1"
                    if (field.fieldId === "1") return null;

                    return (
                      <div key={index} className="flex items-center">
                        <div className="w-full text-left px-2 py-1 flex flex-col font-poppins text-lg font-medium text-[#845BB3] gap-6">
                          {/* Check if the field has subfields */}
                          {field.subFields &&
                          Array.isArray(field.subFields) &&
                          field.subFields.length > 0 ? (
                            // If subfields exist, display each subfield as if it were a regular field
                            field.subFields.map((subfield, subIndex) => (
                              <div
                                key={subIndex}
                                className="flex items-center mb-2"
                              >
                                {/* Subfield Name */}
                                <span className="w-3/5">
                                  {subfield.fieldName}
                                </span>

                                {/* Subfield Input */}
                                <div className="flex items-center justify-center w-1/5">
                                  {subfield.fieldName === "Cough Analysis" ||
                                  subfield.fieldName === "Cancer-Octoscope" ? (
                                    <button
                                      onClick={() =>
                                        openModal(subfield.fieldName)
                                      } // Open modal for Cancer-Octoscope test
                                      className="w-full max-w-[12rem] py-2 bg-[#845BB3] hover:bg-[#7535bd] text-white font-medium rounded-md ml-2"
                                    >
                                      Proceed for the Test
                                    </button>
                                  ) : (
                                    <input
                                      type="text"
                                      value={
                                        subfield.value !== ""
                                          ? subfield.value
                                          : subfield.fieldName === "eAG"
                                          ? eAGValue
                                          : subfield.fieldName === "HbA1c"
                                          ? hbA1cValue
                                          : subfield.fieldName === "HDL/LDL"
                                          ? hdlldlvalue
                                          : subfield.value
                                      }
                                      onChange={(e) => {
                                        if (subfield.fieldName === "HbA1c") {
                                          setHbA1cValue(e.target.value);
                                          calEAG(e.target.value);
                                        }
                                        if (
                                          subfield.fieldName ===
                                          "High Density Lipoprotein (HDL)"
                                        ) {
                                          hdlValue.current = e.target.value;
                                        }
                                        if (
                                          subfield.fieldName ===
                                          "Low Density Lipoprotein (LDL)"
                                        ) {
                                          ldlValue.current = e.target.value;
                                          setHdlldlvalue(
                                            (
                                              hdlValue.current /
                                              ldlValue.current
                                            ).toFixed(2)
                                          );
                                        }
                                        // calEAG();
                                        handleSubTestInputChange(
                                          sectionIndex,
                                          index,
                                          subIndex,
                                          e.target.value
                                        );
                                      }}
                                      className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-2 ml-2"
                                    />
                                  )}
                                </div>

                                {/* Subfield Unit and Range */}
                                <div className="w-1/5 text-right px-4 py-1 font-poppins text-sm font-medium text-[#845BB3]">
                                  {removeNumericWords(subfield.unitAndRange)}
                                </div>
                              </div>
                            ))
                          ) : (
                            // Otherwise, display the field directly if no subfields are present
                            <div className="flex items-center mb-2">
                              {/* Field Name */}
                              <span className="w-3/5">{field.fieldName}</span>

                              <div className="flex items-center justify-center w-1/5">
                                {/* Field Input or Button */}
                                {field.fieldName === "Cough Analysis" ||
                                field.fieldName === "Cancer-Octoscope" ? (
                                  <button
                                    onClick={() => openModal(field.fieldName)}
                                    className="w-full max-w-[12rem] py-2 bg-[#845BB3] hover:bg-[#7535bd] text-white font-medium rounded-md ml-2"
                                  >
                                    Proceed for the Test
                                  </button>
                                ) : (
                                  <input
                                    type="text"
                                    value={
                                      field.fieldName === "HDL/LDL"
                                        ? hdlldlvalue
                                        : field.value || ""
                                    }
                                    onChange={(e) => {
                                      if (field.fieldName === "HbA1c") {
                                        setHbA1cValue(e.target.value);
                                      }
                                      if (
                                        field.fieldName ===
                                        "High Density Lipoprotein (HDL)"
                                      ) {
                                        hdlValue.current = e.target.value;
                                      }
                                      if (
                                        field.fieldName ===
                                        "Low Density Lipoprotein (LDL)"
                                      ) {
                                        ldlValue.current = e.target.value;
                                        setHdlldlvalue(
                                          hdlValue.current / ldlValue.current
                                        );
                                      }
                                      handleInputChange(
                                        sectionIndex,
                                        index,
                                        e.target.value
                                      );
                                    }}
                                    className="w-3/5 rounded-md bg-[#D7D4F8] text-center px-3 py-2 text-base outline-1 ml-2"
                                  />
                                )}
                              </div>

                              {/* Field Unit and Range */}
                              <div className="w-1/5 text-right px-4 py-1 font-poppins text-sm font-medium text-[#845BB3]">
                                {removeNumericWords(field.unitAndRange)}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="flex items-center mt-6">
                <button
                  className="bg-[#845BB3] rounded-md text-white text-sm font-semibold py-2 px-4 flex items-center"
                  onClick={handleLatestResult}
                >
                  <span className="mr-2">
                    {translations[langData].fetchLatestResult}
                  </span>
                </button>
              </div>
              <div className="flex items-center mt-2">
                <button
                  className="bg-[#845BB3] rounded-md text-white text-sm font-semibold py-2 px-4 flex items-center"
                  onClick={handlePreviousResults}
                >
                  <span className="mr-2">Fetch Previous Results</span>
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className="flex w-full justify-between items-center py-6 px-5 border-t-2">
          <div className="flex flex-row justify-start items-center">
            <p className="text-2xl text-[#845BB3]">
              {translations[langData].referrdBY} :
            </p>
            <input
              type="text"
              onChange={handleReferBy}
              value={referby}
              className="w-1/2 rounded-md bg-[#D7D4F8] text-center ml-2 px-3 py-2 text-base outline-1"
            />
          </div>
          <div>
            <button
              onClick={handleSave}
              className={`bg-${
                isAllFieldsFilled ? "[#845BB3]" : "[#D7D4F8]"
              } rounded-md text-white text-base font-semibold py-2 px-2 flex items-center`}
              disabled={!isAllFieldsFilled}
            >
              <span className="mr-2">
                {translations[langData].generateReport}
              </span>
              <img src={report} alt="icon" className="w-6 h-6 align-middle" />
            </button>
          </div>
        </div>
      </div>
      {isModalOpen && activeModal === "Cough Analysis" && (
        <CoughTestModal
          isOpen={isModalOpen}
          onClose={closeModal}
          title="Cough Modal"
        />
      )}
      {isModalOpen && activeModal === "Cancer-Octoscope" && (
        <CameraModal
          isOpen={isModalOpen}
          onClose={closeModal}
          onCapture={handleCapture}
        />
      )}
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="w-10 h-10 border-4 border-t-red-500 border-gray-300 rounded-full animate-spin"></div>
        </div>
      )}
    </div>
  );
};

export default GeneralTest;
