import React, { useState, useRef, useEffect } from "react";
import "../../styles/CoughTestModal.css";
import RecordRTC from "recordrtc";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addDiseaseData } from "../../Redux_Services/slices/diseaseSlice";
import mic from "../../assets/icons/mic.png";
import coughbg from "../../assets/icons/cough.png";
import { useNavigate } from "react-router-dom";
import CrossIcon from "../../assets/icons/cross.svg";

const symptoms = [
  { id: 1, symptomName: "cough_at_night", value: "0", symp: "Cough at night" },
  { id: 2, symptomName: "sputum", value: "0", symp: "Sputum" },
  { id: 3, symptomName: "wheezing", value: "0", symp: "Wheezing" },
  { id: 4, symptomName: "frequent_cough", value: "0", symp: "Frequent Cough" },
  { id: 5, symptomName: "pain_in_chest", value: "0", symp: "Pain in chest" },
  {
    id: 6,
    symptomName: "shortness_of_breath",
    value: "0",
    symp: "Shortness of breath",
  },
  { id: 7, symptomName: "fatigue", value: "0", symp: "Fatigue" },
  {
    id: 8,
    symptomName: "sweat_heavy_at_night",
    value: "0",
    symp: "Sweat heavy at night",
  },
  {
    id: 9,
    symptomName: "loss_of_appetite",
    value: "0",
    symp: "Loss of appetite",
  },
  { id: 10, symptomName: "weight_loss", value: "0", symp: "Weight loss" },
  {
    id: 11,
    symptomName: "cough_with_sputum_and_blood",
    value: "0",
    symp: "Cough with sputum and blood",
  },
  {
    id: 12,
    symptomName: "evening_rise_in_temperature",
    value: "0",
    symp: "Evening rise in temperature",
  },
  {
    id: 13,
    symptomName: "known_person_having_active_tb",
    value: "0",
    symp: "Known person having active TB",
  },
  {
    id: 14,
    symptomName: "active_tb_in_the_past",
    value: "0",
    symp: "Active TB in the past",
  },
  {
    id: 15,
    symptomName: "family_history_of_asthma",
    value: "0",
    symp: "Family history of asthma",
  },
  {
    id: 16,
    symptomName: "active_asthma_in_the_past",
    value: "0",
    symp: "Active asthma in the past",
  },
  { id: 17, symptomName: "smoking", value: "0", symp: "Smoking" },
  {
    id: 18,
    symptomName: "exposure_to_any_kind_of_smoke",
    value: "0",
    symp: "Exposure to any kind of smoke",
  },
  { id: 19, symptomName: "temperature_F", value: "0", symp: "Temperature (F)" },
  { id: 20, symptomName: "pulse_rate", value: "0", symp: "Pulse rate" },
  {
    id: 21,
    symptomName: "respiratory_rate",
    value: "0",
    symp: "Respiratory rate",
  },
  {
    id: 22,
    symptomName: "oxygen_saturation",
    value: "0",
    symp: "Oxygen saturation",
  },
  { id: 23, symptomName: "blood_pressure", value: "0", symp: "Blood pressure" },
  {
    id: 24,
    symptomName: "frequent_cough_duration",
    value: "0",
    symp: "Frequent cough duration",
  },
];

const CoughTestModal = ({ isOpen, onClose, title }) => {
  const nav = useNavigate();
  const [isRecording, setIsRecording] = useState(false);
  const [sympData, setSympData] = useState([]);
  const [audioURL, setAudioURL] = useState("");
  const [audioFile, setAudioFile] = useState();
  const [coughValidationMessage, setCoughValidationMessage] = useState(null);
  const [proceed, setProceed] = useState(null);
  const [respData, setRespData] = useState([]);
  const [error, setError] = useState(null);
  const recorderRef = useRef(null); // for recording cough
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  if (!isOpen) return null;

  // Function to start recording
  const startRecording = async () => {
    try {
      // document.querySelector('.')
      // Request microphone access
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      // Use RecordRTC's StereoAudioRecorder for recording in WAV format
      const options = {
        mimeType: "audio/wav",
        sampleRate: 44100, // Set the sample rate to 44100 Hz
      };
      const recorder = new RecordRTC.StereoAudioRecorder(stream, options);
      recorderRef.current = recorder;

      recorder.record(); // Start recording
      setIsRecording(true);
      console.log("Recording started.");
    } catch (error) {
      console.error("Error accessing microphone:", error);
      alert("Microphone access is required to record audio.");
    }
  };

  // Function to stop recording
  const stopRecording = () => {
    if (recorderRef.current) {
      recorderRef.current.stop((blob) => {
        processRecording(blob); // Process the recorded audio blob
        setAudioFile(blob);
        console.log(blob);
      });
      setIsRecording(false);
      console.log("Recording stopped.");
    }
  };

  // Function to process the recorded audio blob
  const processRecording = (blob) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("coughsoundfile", blob, "audio.wav");

    // Send the recorded audio to the verifycough API
    verifyCoughSample(formData);

    // Create a URL to play back the audio
    const audioUrl = URL.createObjectURL(blob);
    setAudioURL(audioUrl);
  };

  // Function to verify cough sample by sending it to the API
  const verifyCoughSample = async (formData) => {
    try {
      const response = await axios.post(
        "https://swaasa.sandbox.swaasa.ai/api/verifycough",
        formData,
        {
          headers: {
            accessToken:
              "eyJhbGciOiJIUzUxMiIsImlhdCI6MTcyMTcxODU2OSwiZXhwIjoxOTgwOTE4NTY5fQ.eyJ1c2VySWQiOm51bGwsImxvZ2luSWQiOiJkaXNlYXNlX3ByZWRAc3dhYXNhLmFpIiwiclR5cGUiOiJhZG1pbiIsInBsYW4iOiJ2aXRhbHMscGx1cyx0Yixjb3BkLGFzdGhtYSxzcGlyb21ldHJ5IiwiYWNjZXNzVG9rZW4iOm51bGwsInVzZXJEZXRhaWxzIjpudWxsLCJwYXJlbnRJZCI6bnVsbCwiY29uZmlndXJhdGlvbiI6e319.w8l-tbdal_61Omqw6qB5lE6lsNqr-SN3HKcHsdf-xbiF61PXiWO71MzwX411gBAFk6QhWbg3BH9Mco57jyb07A", // Replace with your actual access token
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoiVEVTVE5PVyIsIlBhdGllbnRJRCI6IlNDMjNGMDAwMDAyIiwiY29udGFjdE51bWJlciI6Ijg4MDU5OTEwNzkiLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE3MjcxMDQzMTd9.rW70KCsDPR9DU8SDRPDxtTFcX2mT2nb_1lZoYgoVzEk", // Replace with your actual JWT token
          },
          // withCredentials: true, // Ensure credentials are included in the request
        }
      );

      console.log(response); // Log the entire response for debugging
      setLoading(false);
      document.querySelector(".record-sec").style.padding = "20px 20px";
      setRespData(response);
      if (
        response.data &&
        response.data.data &&
        response.data.data.isValidCough
      ) {
        setCoughValidationMessage("Cough is valid.");
        setCoughValidationMessage(true);
      } else if (!response.data.data.isValidCough) {
        setCoughValidationMessage(
          response.data.message || "Cough is not valid."
        );
        alert("Please record again!");
        resetRecordingState();
      }
    } catch (error) {
      console.error("Error verifying cough:", error);
      setCoughValidationMessage("Error verifying the cough sample.");
      setError(error);
    }
  };

  const sendDiseaseLikelyRequest = async () => {
    setLoading(true);
    const selectedSymp = symptoms.reduce((acc, { symptomName, value }) => {
      acc[symptomName] = value;
      return acc;
    }, {});
    const formData = new FormData();
    formData.append("coughsoundfile", audioFile, "audio.wav"); // 'audioFile' should be a File or Blob
    formData.append("symptoms", JSON.stringify(selectedSymp));
    formData.append("assessmentId", respData.data.data.assessmentId);
    formData.append("gender", "1");
    formData.append("age", "23");

    try {
      const response = await axios.post(
        "https://swaasa.sandbox.swaasa.ai/api/diseaselikely",
        formData,
        {
          headers: {
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoiR3V3YWh0aSBUZXN0ZXIiLCJkZXNpZ25hdGlvbiI6IlRlY2huaWNpYW4iLCJMYWJUZWNobmljaWFuSUQiOiJURUNINDMzODUzIiwicm9sZSI6InRlY2giLCJpYXQiOjE3MzA5NDg1MTF9.S4iHnPvCafaub7B7N8JpkrwLIFh4qefOWHS-_kixmTs",
            Accept: "application/json",
            accessToken:
              "eyJhbGciOiJIUzUxMiIsImlhdCI6MTcyMTcxODU2OSwiZXhwIjoxOTgwOTE4NTY5fQ.eyJ1c2VySWQiOm51bGwsImxvZ2luSWQiOiJkaXNlYXNlX3ByZWRAc3dhYXNhLmFpIiwiclR5cGUiOiJhZG1pbiIsInBsYW4iOiJ2aXRhbHMscGx1cyx0Yixjb3BkLGFzdGhtYSxzcGlyb21ldHJ5IiwiYWNjZXNzVG9rZW4iOm51bGwsInVzZXJEZXRhaWxzIjpudWxsLCJwYXJlbnRJZCI6bnVsbCwiY29uZmlndXJhdGlvbiI6e319.w8l-tbdal_61Omqw6qB5lE6lsNqr-SN3HKcHsdf-xbiF61PXiWO71MzwX411gBAFk6QhWbg3BH9Mco57jyb07A",
          },
          // withCredentials: true, // Required if you need to include cookies or other credentials
        }
      );
      // console.log("Response:", response.data);
      response.data.symptoms = selectedSymp;
      setLoading(false);
      dispatch(addDiseaseData(response.data));
      console.log(response);
      nav("/testparameter");
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      alert("Recording is not appropriate! Please record again");
      resetRecordingState();
      setLoading(false);
    }
  };

  // Function to reset the recording state
  const resetRecordingState = () => {
    document.querySelector(".record-sec").style.padding = "80px 20px";
    setIsRecording(false);
    setProceed(false);
    setAudioURL("");
    setCoughValidationMessage(null);
    if (recorderRef.current) {
      recorderRef.current = null;
    }
    symptoms.forEach((item) => {
      item.value = "0";
    });
    setSympData([]);
  };

  // Function for styling and creating selected symptoms object for /diseaseLikely endpoint
  const selectSymp = (id, symp) => {
    document.getElementById(`${id}`).classList.toggle("list-content-selected");
    if (sympData == []) {
      setSympData([symp]);
      return;
    }
    setSympData((prev) => [...prev, symp]);
    const res = symptoms.find((item) => item.id == id);
    if (res.value == "0") {
      res.value = "1";
    } else {
      res.value = "0";
    }
  };

  // to display available symptoms select
  const onProceed = () => {
    setProceed(true);
  };

  // useEffect(() => {
  //   console.log(sympData);
  //   console.log(symptoms);
  // }, [selectSymp]);

  return (
    <div className="testpage">
      <div className="testpage-modal">
        <div className="modal-cont">
          <div className="record-sec">
            <button
              onClick={() => {
                resetRecordingState();
                onClose();
                console.log("clicked");
              }}
              className="close-btn"
            >
              <img src={CrossIcon} alt="" />
            </button>
            <div className="record-head">
              <img src={mic} alt="microphone-icon" />
              <h2> Record your audio</h2>
            </div>
            <div className="action-btns">
              {!isRecording && (
                <div className="btn" onClick={startRecording}>
                  Start Recording
                </div>
              )}

              {isRecording && (
                <div className="btn stop-btn" onClick={stopRecording}>
                  Stop Recording
                </div>
              )}
            </div>
            {audioURL && (
              <div style={{ marginTop: "15px" }}>
                <audio
                  controls
                  src={audioURL}
                  style={{ width: "50%", height: "40px", marginBottom: "10px" }}
                />
              </div>
            )}
            {coughValidationMessage ? (
              <div className="auth-true-action">
                <div onClick={onProceed} className="proceed-btn">
                  Proceed
                </div>
                <div onClick={resetRecordingState} className="reset-btn">
                  Reset
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="img-cough">
              <img className="cough-bg" src={coughbg} alt="" />
            </div>
          </div>
        </div>
        {proceed ? (
          <>
            <div className="symptoms-list">
              <h2 className="symp-heading">Select symptoms</h2>
              <ul>
                {symptoms.map((a, index) => (
                  <>
                    <li
                      onClick={() => selectSymp(a.id, a.symp)}
                      id={a.id}
                      className="list-content"
                    >
                      {a.symp}
                    </li>
                  </>
                ))}
              </ul>
            </div>
            <div className="submissive-action-btn">
              <div
                onClick={sendDiseaseLikelyRequest}
                className="submissive-btn"
              >
                Submit
              </div>
              <div onClick={resetRecordingState} className="submissive-btn">
                Reset
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="w-10 h-10 border-4 border-t-red-500 border-gray-300 rounded-full animate-spin"></div>
        </div>
      )}
    </div>
  );
};

export default CoughTestModal;
