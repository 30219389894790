import React from 'react'
import { useSelector } from 'react-redux'

export default function TestPage() {

    const testData = useSelector(state => state.testSlice.testData)
    console.log(testData)

    return (
        <div>
            Test Page 
        </div>
    )
}
