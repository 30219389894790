import React, { useEffect } from "react";
import logo from "../assets/superceuticals-logo.png";
import iso27 from "../assets/iso27.png";
import iso13 from "../assets/iso13.png";
import iso14 from "../assets/iso14.png";
import iso15 from "../assets/iso15.png";
import { useSelector } from "react-redux";
import { commentsData } from "../translate/testInstruction";
// import {
//   LipidProfile,
//   HbA1c,
//   BloodPressureSummary,
// } from "../translate/testSummery";
import DynamicRender from "./dynamicRender";

const PatientReport = ({ patientDetails, testResults }) => {
  const refBy = useSelector((state) => state.testSlice.referedBy);

  let testComponent;
  console.log("______testResults", testResults);

  if (testResults?.fields.length > 1) {
    if (testResults?.fields[0].parentFieldName === "Routine Panel") {
      console.log("Patient Report Test Results: 34", testResults);
      const testFieldName = testResults?.fields[0].parentFieldName;

      console.log("67", testFieldName);
      testComponent = (
        <DynamicRender
          componentName={testFieldName?.toLowerCase().replace(/[^a-z0-9]/g, "")}
        />
      );
      console.log("Rendering component:", testComponent);
    } else {
      console.log("Patient Report Test Results:", testResults);
      const testFieldName = testResults?.testFieldName;

      console.log("67", testFieldName);
      testComponent = (
        <DynamicRender
          componentName={testFieldName?.toLowerCase().replace(/[^a-z0-9]/g, "")}
        />
      );
      console.log("Rendering component:", testComponent);
    }
  } else if (testResults?.fields.length > 1) {
    const parentField = testResults.fields[0]?.parentField;
    console.log("48", parentField);

    if (parentField) {
      console.log("Patient Report Test Results:", testResults);
      const testFieldName = testResults?.fields[0]?.parentField;

      // console.log("67", testFieldName);
      testComponent = (
        <DynamicRender
          componentName={testFieldName?.toLowerCase().replace(/[^a-z0-9]/g, "")}
        />
      );
      console.log("Rendering component:", testFieldName);
    } else {
      console.log("Patient Report Test Results:", testResults);
      const testFieldName = testResults?.testFieldName;

      console.log("67", testFieldName);
      testComponent = (
        <DynamicRender
          componentName={testFieldName?.toLowerCase().replace(/[^a-z0-9]/g, "")}
        />
      );
      console.log("Rendering component:", testComponent);
    }
  } else {
    const testFieldName = testResults?.fields[0].fieldName
      ?.toLowerCase()
      .replace(/[^a-z0-9]/g, "");
    console.log("Hello");
    console.log("67", testFieldName);
    testComponent = (
      <DynamicRender
        componentName={testFieldName?.toLowerCase().replace(/[^a-z0-9]/g, "")}
      />
    );
  }

  function removeNumericWords(inputString) {
    console.log("____inputString:", inputString);

    // Check if the inputString is a valid string
    if (typeof inputString !== "string") {
      console.warn("Invalid input passed to removeNumericWords:", inputString);
      return ""; // Return an empty string or handle this case appropriately
    }

    // Proceed with the original logic
    return inputString.replace(/[^a-zA-Z/%]+/g, " ").trim();
  }
  return (
    <div className="max-w-screen-lg mx-auto p-8 bg-white">
      {/* Header Section */}
      <div className="border p-4">
        <div className="flex justify-between items-start">
          <div>
            <img src={logo} alt="Logo" className="w-96 h-20 my-2" />
            <p className="text-sm">
              29, Infocity 2, Sector 33, Gurgaon, Haryana - 122005
            </p>
            <p className="text-sm">Gurgaon, Haryana 122022</p>
          </div>
          <div className="flex space-x-6 my-2">
            <img src={iso27} alt="ISO Icon" className="w-16 h-16" />
            <img src={iso13} alt="ISO Icon" className="w-16 h-16" />
            <img src={iso14} alt="ISO Icon" className="w-16 h-16" />
            <img src={iso15} alt="ISO Icon" className="w-16 h-16" />
          </div>
        </div>

        {/* Patient Information */}
        <div className="mt-4 grid grid-cols-3 gap-4 text-sm">
          <div>
            <p>
              <strong>Name:</strong> {patientDetails?.Name || ""}
            </p>
            <p>
              <strong>Place:</strong> {patientDetails?.Place || ""}
            </p>
            <p>
              <strong>Lab No:</strong> {patientDetails?.LabNo || ""}
            </p>
          </div>
          <div>
            <p>
              <strong>Patient ID:</strong> {patientDetails?.PatientID || ""}
            </p>
            <p>
              <strong>Age:</strong> {patientDetails?.Age || ""}
            </p>
            <p>
              <strong>Gender:</strong> {patientDetails?.Gender || ""}
            </p>
          </div>
          <div>
            <p>
              <strong>Ref By:</strong> {refBy || ""}
            </p>
            <p>
              {/* <strong>Date & Time:</strong> {patientDetails?.dateTime || ""} */}
              <strong>Date & Time:</strong>{" "}
              {patientDetails?.dateTime ||
                (() => {
                  const now = new Date();

                  // Format date
                  const day = String(now.getDate()).padStart(2, "0");
                  const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-based
                  const year = now.getFullYear();

                  // Format time
                  const hours = String(now.getHours()).padStart(2, "0");
                  const minutes = String(now.getMinutes()).padStart(2, "0");

                  // Combine date and time
                  return `${day}/${month}/${year} ${hours}:${minutes}`;
                })()}
            </p>
          </div>
        </div>
      </div>

      {/* Test Results Section */}
      {["PCT", "samplelipidprofile"].includes(
        testResults?.fields[0]?.fieldName
      ) ? null : (
        <div className="border mt-6 p-4">
          <table className="min-w-full table-auto text-left text-sm">
            <thead>
              <tr className="border-b bg-purple-200">
                <th className="px-4 py-2 font-semibold text-sm">
                  Test Category
                </th>
                <th className="px-4 py-2 font-semibold text-sm">Test Name</th>
                <th className="px-4 py-2 font-semibold text-sm">Results</th>
                <th className="px-4 py-2 font-semibold text-sm">Units</th>
                <th className="px-4 py-2 font-semibold text-sm">
                  Bio. Ref. Interval
                </th>
              </tr>
            </thead>
            <tbody>
              {testResults?.fields?.map((field, index) => (
                <tr key={index} className="border-b">
                  <td className="px-4 py-2">{testResults?.testFieldName}</td>
                  <td className="px-4 py-2">{field?.fieldName}</td>
                  <td className="px-4 py-2">{field?.value}</td>
                  <td className="px-4 py-2">
                    {removeNumericWords(field?.unitAndRange)}
                  </td>
                  <td className="px-4 py-2">
                    {field?.bioReferenceInterval || "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* 
      Comments Section
      {testResults.fields.length > 0 &&
        getTestComments(
          testResults?.testFieldName,
          testResults?.fields[0]?.fieldName
        )} */}

      {/* Display Lipid Profile and HbA1c components if applicable */}
      {/* {testResults?.testFieldName === "Lipid Profile" && <LipidProfile />}
      {testResults?.fields?.some((field) => field.fieldName === "HbA1c") && (
        <HbA1c />
      )}
      {testResults?.fields?.some(
        (field) => field.fieldName === "Blood Pressure"
      ) && <BloodPressureSummary />} */}

      {testComponent ? (
        <div className="mt-8">{testComponent}</div>
      ) : (
        <div></div> // Empty div as fallback
      )}

      {/* Important Instructions */}
      {["PCT", "samplelipidprofile"].includes(
        testResults?.fields[0]?.fieldName
      ) ? null : (
        <div className="border mt-6 p-4">
          <p className="font-bold text-sm">IMPORTANT INSTRUCTIONS</p>
          <ul className="list-disc list-inside text-sm">
            <li>Test results pertain to specimen submitted.</li>
            <li>
              All test results are dependent on the quality of the sample
              received.
            </li>
            <li>
              Investigations are only a tool to facilitate arriving at a
              diagnosis and should be clinically correlated by the referring
              physician.
            </li>
            <li>
              Report delivery may be delayed due to unforeseen conditions.
            </li>
            <li>
              Certain tests may require further testing at additional costs for
              derivation of exact value.
            </li>
            <li>Test results may show inter/Intra laboratory variations.</li>
            <li>
              The courts of Delhi shall have exclusive jurisdiction in all
              disputes/claims concerning test(s) and/or results of test(s).
            </li>
            <li>Test results are not valid for medico-legal purposes.</li>
          </ul>
        </div>
      )}

      <p className="text-center text-xs">
        --------------------End of Test--------------------
      </p>

      {/* Footer Section */}
      <div className="text-center bg-purple-200 mt-6">
        <p className="text-sm mt-2 italic">
          In case the results are unexpected or alarming, the patient is advised
          to contact the lab immediately for possible remedial action.
        </p>
        <p className="text-xs mt-1">"एक कदम स्वस्थ भारत की ओर"</p>
      </div>
    </div>
  );
};

export default PatientReport;
