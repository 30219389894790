import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateRiskScoreAPI } from "../../Redux_Services/slices/diseaseSlice";
import axios from "axios";
import { addReferedBy, addTests } from "../../Redux_Services/slices/testSlice";
import { useNavigate } from "react-router-dom";

const subFields = [
  {
    fieldId: "",
    fieldName: "TB Likely",
    fieldObjectName: "tb_likely",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "Cough Risk Score",
    fieldObjectName: "cough_risk_score",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "Cough Pattern",
    fieldObjectName: "cough_pattern",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "Weight",
    fieldObjectName: "weight",
    unitAndRange: "kg",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "Wet Cough Count",
    fieldObjectName: "wet_cough_count",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "Risk",
    fieldObjectName: "risk",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "COPD Likely",
    fieldObjectName: "COPD_likely",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "Dry Cough Count",
    fieldObjectName: "dry_cough_count",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "COPD Summary",
    fieldObjectName: "COPD_Summary",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "COPD Severity",
    fieldObjectName: "COPD_severity",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "Height",
    fieldObjectName: "height",
    unitAndRange: "cm",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "COPD Risk Score",
    fieldObjectName: "COPD_risk_score",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "Covid Risk Score",
    fieldObjectName: "covid_risk_score",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "Covid Risk",
    fieldObjectName: "covid_risk",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "Covid Summary",
    fieldObjectName: "covid_summary",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "TB Risk",
    fieldObjectName: "tb_Risk",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "TB Risk Score",
    fieldObjectName: "tb_RiskScore",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "TB Summary",
    fieldObjectName: "tb_Summary",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "Asthma Severity",
    fieldObjectName: "asthma_severity",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "Asthma Risk Score",
    fieldObjectName: "asthma_risk_score",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "Asthma Summary",
    fieldObjectName: "asthma_Summary",
    unitAndRange: "",
    value: "",
  },
  {
    fieldId: "",
    fieldName: "LHI",
    fieldObjectName: "lhi",
    unitAndRange: "",
    value: "",
  },
];

//updated test parameter page
export default function TestParameter() {
  const tests = useSelector((state) => state.testSlice.testsData || []);
  console.log("____________________ tests", tests);
  const referedBy = useSelector((state) => state.testSlice.referedBy || "");
  const [testData, setTestData] = useState(tests);
  const [referby, setReferby] = useState(referedBy);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const coughData = useSelector((state) => state.diseaseSlice);
  const assessmentId = useSelector(
    (state) => state?.diseaseSlice?.diseaseData?.assessmentId
  );
  const symptoms = useSelector(
    (state) => state?.diseaseSlice?.diseaseData?.symptoms
  );
  const [apiResponse, setApiResponse] = useState({});

  useEffect(() => {
    if (!assessmentId || !symptoms) return;

    const convertedSymptoms = Object.keys(symptoms).reduce((acc, key) => {
      acc[key] = parseInt(symptoms[key], 10); // or use Number(symptoms[key]) for conversion
      return acc;
    }, {});
    console.log(convertedSymptoms);
    const form = new FormData();
    form.append("assessmentId", assessmentId);
    form.append(
      "symptoms",
      JSON.stringify({
        cough_at_night: symptoms.cough_at_night || 0,
        sputum: symptoms.sputum || 0,
        wheezing: symptoms.wheezing || 0,
        frequent_cough: symptoms.frequent_cough || 0,
        pain_in_chest: symptoms.pain_in_chest || 0,
        shortness_of_breath: symptoms.shortness_of_breath || 0,
        fatigue: symptoms.fatigue || 0,
        sweat_heavy_at_night: symptoms.sweat_heavy_at_night || 0,
        loss_of_appetite: symptoms.loss_of_appetite || 0,
        weight_loss: symptoms.weight_loss || 0,
        cough_with_sputum_and_blood: symptoms.cough_with_sputum_and_blood || 0,
        evening_rise_in_temperature: symptoms.evening_rise_in_temperature || 0,
        known_person_having_active_tb:
          symptoms.known_person_having_active_tb || 0,
        active_tb_past: symptoms.active_tb_past || 0,
        family_history_of_asthma: symptoms.family_history_of_asthma || 0,
        active_asthma_past: symptoms.active_asthma_past || 0,
        smoking: symptoms.smoking || 0,
        exposure_to_any_kind_of_smoke:
          symptoms.exposure_to_any_kind_of_smoke || 0,
        temperature_F: symptoms.temperature_F || 0,
        pulse_rate: symptoms.pulse_rate || 0,
        respiratory_rate: symptoms.respiratory_rate || 0,
        oxygen_saturation: symptoms.oxygen_saturation || 0,
        blood_pressure: symptoms.blood_pressure || 0,
        frequent_cough_duration: symptoms.frequent_cough_duration || 0,
      })
    );

    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://swaasa.sandbox.swaasa.ai/api/riskscore_assessment",
          form,
          {
            headers: {
              Authorization:
                "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoiR3V3YWh0aSBUZXN0ZXIiLCJkZXNpZ25hdGlvbiI6IlRlY2huaWNpYW4iLCJMYWJUZWNobmljaWFuSUQiOiJURUNINDMzODUzIiwicm9sZSI6InRlY2giLCJpYXQiOjE3MzA5NTIwOTB9.nUbcXwmia3ovc6DUAQpV4iVHzGrBzI90lxQ2gYB8yD0",
              "x-api-key": "Asdwz1pKsRqIIFnXH",
              "content-type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
          console.log("Data submitted successfully", response);
          setApiResponse(response.data.data);
          // testData[0].fields[0].subFields = subFields;
          // console.log("Test Data", testData[0]);
          const updatedTestData = testData.map((test, testIndex) => {
            if (testIndex === 0) {
              // Assuming you're updating the first test's fields
              return {
                ...test,
                fields: test.fields.map((field, fieldIndex) => {
                  if (fieldIndex === 0) {
                    // Assuming you're updating the first field
                    return {
                      ...field,
                      subFields: [...subFields], // Update subFields immutably
                    };
                  }
                  return field;
                }),
              };
            }
            return test;
          });

          const updatedValue = updatedTestData.map((test, testIndex) => {
            if (testIndex === 0) {
              return {
                ...test,
                fields: test.fields.map((field, fieldIndex) => {
                  if (fieldIndex === 0) {
                    return {
                      ...field,
                      subFields: field.subFields.map(
                        (subField, subFieldIndex) => {
                          // Check if apiResponse has the key corresponding to fieldObjectName
                          const updatedValue =
                            response.data.data[subField.fieldObjectName] ||
                            subField.value;

                          return {
                            ...subField,
                            value: updatedValue, // Update the value
                          };
                        }
                      ),
                    };
                  }
                  return field; // Preserve other fields if fieldIndex !== 0
                }),
              };
            }
            return test; // Preserve other tests if testIndex !== 0
          });

          setTestData(updatedValue); // Update state
          console.log("Test Data", updatedValue[0]);
        } else {
          console.error("Error:", response.status, response.statusText);
        }
      } catch (err) {
        console.error("Error submitting data:", err);
      }
    };

    fetchData();
  }, [assessmentId, symptoms]);
  useEffect(() => {
    console.log("api data", apiResponse);
  });

  const add_subfields = () => {
    const updatedTestData = testData.map((test, testIndex) => {
      if (testIndex === 0) {
        // Assuming you're updating the first test's fields
        return {
          ...test,
          fields: test.fields.map((field, fieldIndex) => {
            if (fieldIndex === 0) {
              // Assuming you're updating the first field
              return {
                ...field,
                subFields: [...subFields], // Update subFields immutably
              };
            }
            return field;
          }),
        };
      }
      return test;
    });

    setTestData(updatedTestData); // Update state
    console.log("Test Data", updatedTestData[0]);
  };

  const handleGenerateReport = (e) => {
    e.preventDefault();
    // add_subfields();
    dispatch(addTests(testData));
    dispatch(addReferedBy(referby));
    console.log("Test Data", testData[0]);

    navigate("/tests/testId/report");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setApiResponse((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <>
      <div
        className="test-parameter my-7 flex w-full items-center justify-center"
        style={{ fontFamily: "poppins" }}
      >
        <form
          onSubmit={handleGenerateReport}
          className="w-4/5 bg-white rounded-lg shadow-sm"
        >
          {/* Header Row */}
          <div className="grid grid-cols-4 bg-purple-600 text-white text-sm py-4 px-4 rounded-t-lg">
            <div className="font-medium">TEST CATEGORY</div>
            <div className="font-medium">TEST NAME</div>
            <div className="font-medium">RESULTS</div>
            <div className="font-medium">UNIT & RANGE</div>
          </div>
          {/* Content */}
          <div className="p-4">
            {/* Table Content */}
            <table className="w-full border-separate border-spacing-0">
              <tbody>
                {/* General Body Composition Section */}
                <tr className="border-b border-blue-100">
                  {Object.entries(apiResponse).map(([key, value]) => (
                    <tr key={key}>
                      <td className="text-purple-600 py-2 w-1/4 pl-4">
                        {key.toUpperCase()}
                      </td>
                      <td className="py-2 px-4">
                        <input
                          type="text"
                          name={key}
                          value={value}
                          onChange={handleInputChange}
                          className="h-8 w-full px-3 rounded bg-purple-100 focus:bg-purple-50 focus:outline-none focus:ring-1 focus:ring-purple-400"
                          placeholder={`Enter ${key}`}
                        />
                      </td>
                    </tr>
                  ))}
                </tr>{" "}
                {/* Close the initial <tr> */}
              </tbody>
            </table>
          </div>
          {/* Footer */}
          <div className="flex items-center justify-between pt-6 mt-4 border-t border-gray-200">
            <div className="flex items-center mx-4 gap-4">
              <span className="text-purple-600">REFERRED BY:</span>
              <input
                type="text"
                name="referredBy"
                // value={formData.referredBy}
                // onChange={handleChange}
                className="w-48 h-8 px-3 rounded bg-purple-100 focus:bg-purple-50 focus:outline-none focus:ring-1 focus:ring-purple-400"
                placeholder="Enter name"
              />
            </div>
            <button
              type="submit"
              className=" m-4 bg-[#e87f7fbf]"
              style={{
                background: "red",
                padding: "1%",
                borderRadius: "10px",
                color: "white",
              }}
            >
              GENERATE REPORT
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
