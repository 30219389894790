import React from "react";
import nonotification from "../../assets/icons/noNotification2.png";
import { useNavigate } from "react-router-dom";
import { translations } from "../../translation/translate";
import { useSelector } from "react-redux";

export default function Notification() {
  const langData = useSelector((item) => item.langSlice.langData);
  const navigate = useNavigate();
  const homeClick = () => {
    navigate("/home");
  };
  return (
    <div className="bg-white flex flex-col justify-center items-center h-svh">
      <img src={nonotification} alt="No notification" />
      <p className="text-[#a6a4a9] text-xl">{translations[langData].emptyNotification}</p>
      <p
        onClick={homeClick}
        className="text-[#845BB3] font-bold text-xl cursor-pointer"
      >
        Home?
      </p>
    </div>
  );
}
