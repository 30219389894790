// src/components/PatientCard.js
import React, { useState } from "react";
import injury from "../assets/icons/injury.svg";
import exclamation from "../assets/icons/exclamation.svg";
import bgimg from "../assets/bgimg.jpg";
import profile from "../assets/patient-profile.png";
import { Link } from "react-router-dom";
import { addPatient } from "../Redux_Services/slices/patientSlice";
import { useNavigate } from "react-router-dom";
import TestRecordModal from "./TestRecordModal";
import { useSelector, useDispatch } from "react-redux";
// import { addLang } from "../Redux_Services/slices/langSlice"
import { translations } from "../translation/translate";

const PatientCard = ({ patient, labNo, handleOpenModal }) => {
  const langData = useSelector((state) => state.langSlice.langData || []);

  const {
    Name: name,
    Age: age,
    Gender: gender,
    EmailId: email,
    PatientID: id,
    contactNumber: phone,
    AcStatus: acStatus,
    imageURL: profilePic,
  } = patient;
  const labNumber = labNo;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function maskKey(key) {
    // Ensure key is a valid string
    if (!key || typeof key !== "string") {
      console.error("Invalid key provided:", key);
      return ""; // Return an empty string or handle it as per your requirements
    }

    if (key.length < 5) {
      return key;
    }

    const maskSize = key.length - 6;
    const start = Math.floor((key.length - maskSize) / 2);
    const end = start + maskSize;
    const maskedKey = key.slice(0, start) + "x".repeat(5) + key.slice(end);

    return maskedKey;
  }

  const handleSelectPatient = () => {
    const patientData = {
      ...patient, // Spread the existing patient properties
      labNo: labNumber, // Add labNo as a new property
    };

    dispatch(addPatient(patientData)); // Dispatch the updated patientData object
    navigate("/tests");
  };
  return (
    <div className="w-[274px] transform transition-transform duration-200 hover:scale-105 h-[406px] bg-[#ffffff] rounded-2xl shadow-md relative">
      <div className="relative">
        <img
          className="w-[246px] h-[138px] mt-[13px] mx-[14px] object-cover rounded-2xl"
          src={bgimg} // Placeholder for background image
          alt="Background"
        />
      </div>

      {/* Profile image */}
      <div className="absolute w-[119px] h-[119px] top-[87px] left-[28px] border-[#845BB3] rounded-full overflow-hidden border-4 shadow-md">
        <img
          className="object-cover w-full h-full"
          src={profile}
          alt="Profile"
        />
      </div>

      {/* Exclamation icon */}
      <button onClick={handleOpenModal} className="absolute left-[240px] mt-5">
        <img
          className="object-cover w-[20px] h-[20px]"
          src={exclamation} // Placeholder for exclamation icon
          alt="Exclamation"
        />
      </button>

      {/* Name and injury icon */}
      <div className="absolute top-[220px] left-[28px] bg-white flex items-start pr-2 rounded-lg">
        <div className="relative flex items-start -mt-3">
          <img
            className=" object-cover w-[20px] h-[38px] mr-2 "
            src={injury}
            alt="Injury"
          />
        </div>
        <div className="font-poppins text-[16px] font-semibold leading-[24px] text-[#845BB3] flex-shrink">
          {name || "Abhishek Sharma"}
        </div>
      </div>

      {/* patient details */}
      <div className="absolute flex flex-col top-[253px] mx-6 space-y-2">
        <div className="flex space-x-3">
          <span className="font-poppins text-[12px] font-bold leading-[18px] text-[#845BB3]">
            {translations[langData].age}:
          </span>
          <div className="font-poppins text-[12px] font-semibold leading-[18px] text-[#845BB3]">
            {age || 21}
          </div>
          <span className="font-poppins text-[12px] font-bold leading-[18px] text-[#845BB3]">
            {translations[langData].gender}:
          </span>
          <div className="font-poppins text-[12px] font-semibold leading-[18px] text-[#845BB3]">
            {gender || "Male"}
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <span className="font-poppins text-[12px] font-bold leading-[18px] text-[#845BB3]">
            {translations[langData].email}:
          </span>
          <div className="font-poppins text-[12px] font-semibold leading-[18px] text-[#845BB3] truncate max-w-[150px]">
            {maskKey(email) || "abhixxxx620703@gmail.com"}
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <span className="font-poppins text-[12px] font-bold leading-[18px] text-[#845BB3]">
            {translations[langData].patientid}:
          </span>
          <div className="font-poppins text-[12px] font-semibold leading-[18px] text-[#845BB3]">
            {id || "ddj12345678"}
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <span className="font-poppins text-[12px] font-bold leading-[18px] text-[#845BB3]">
            {translations[langData].phone}:
          </span>
          <div className="font-poppins text-[12px] font-semibold leading-[18px] text-[#845BB3]">
            {maskKey(phone) || "99xxxxxx99"}
          </div>
        </div>
        <div className="flex items-center justify-center">
          <button
            onClick={handleSelectPatient}
            className="flex items-center justify-center w-[230px] h-[32px] rounded-[4px] bg-[#845BB3]"
          >
            <div className="font-poppins text-lg font-semibold leading-[24px] text-white">
              {translations[langData].test}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PatientCard;
