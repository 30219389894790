import axios from 'axios';
import React, { useEffect, useState } from 'react';
import API_ENDPOINTS from '../service/api';
import formatDate from '../Utils/formatDate';
import { useDispatch } from 'react-redux';
import { addTests } from '../Redux_Services/slices/testSlice';
import { useNavigate } from 'react-router-dom';

export default function TestRecordModal({ isOpen, onClose }) {

    const [records, setRecords] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const resp = await axios.get(API_ENDPOINTS.getTestResults, {
                headers: {
                    Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoiR3V3YWh0aSBUZXN0ZXIiLCJkZXNpZ25hdGlvbiI6IlRlY2huaWNpYW4iLCJMYWJUZWNobmljaWFuSUQiOiJURUNINDMzODUzIiwicm9sZSI6InRlY2giLCJpYXQiOjE3MjgzNjEwMzV9.3isyIl_D11QawjIi-h-9LInwhRiRD533W7tZz4Uxtdg'
                }
            })

            if (resp.data) {
                console.log(resp.data)
                setRecords(resp.data.Test)
            }
        })();
    }, [])

    const savePatientTestResults = (tests, testId) => {
        let formattedData;

        const testNames = Object.keys(tests);

        formattedData = testNames.map(testName => {

            return {
                testFieldName: testName,
                fields: Object.keys(tests[testName]).map((testField) => {

                    return {
                        testFieldName: testField,
                        fieldName: testField,
                        value: tests[testName][testField]
                    }
                })
            }
        })
        console.log(formattedData);
        dispatch(addTests(formattedData));
        navigate(`/tests/${testId}/report`);
    };


    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
            <div className="bg-white h-2/3 max-h-70 overflow-y-scroll rounded-lg shadow-lg w-full max-w-3xl">
                <div className="flex justify-between items-center border-b border-gray-200 px-6 py-4">
                    <h2 className="text-xl font-semibold text-purple-500">Patient Test Records</h2>
                    <button
                        className="text-gray-500 hover:text-gray-700 focus:outline-none"
                        onClick={onClose}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                <div className="p-6">
                    <table className="min-w-full table-auto">
                        <thead>
                            <tr className="bg-purple-100 text-purple-600 uppercase text-sm leading-normal">
                                <th className="py-3 px-6 text-left">Sr.No</th>
                                <th className="py-3 px-6 text-left">Date</th>
                                <th className="py-3 px-6 text-left">Test Status</th>
                                <th className="py-3 px-6 text-left">Referred By</th>
                                <th className="py-3 px-6 text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-600 text-sm font-light">
                            {records.map((record, index) => (
                                <tr key={index} className="border-b border-gray-200 hover:bg-purple-50">
                                    <td className="py-3 px-6 text-left">{index + 1}</td>
                                    <td className="py-3 px-6 text-left">{formatDate(record?.createdAt)}</td>
                                    <td className="py-3 px-6 text-left">{record?.ReportStatus}</td>
                                    <td className="py-3 px-6 text-left">{record?.RefferedBy}</td>
                                    <td className="py-3 px-6 text-center">
                                        <button
                                            className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600 focus:outline-none"
                                            onClick={() => savePatientTestResults(record?.TestData, record?._id)}>
                                            Continue
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
