// src/pages/SearchPatient.js

// Import necessary components and hooks
import { useState } from "react";
import useFetchPatients from "../../Hooks/SearchPatient";
import PatientCard from "../../Components/PatientCard";
import Navbar from "../../Components/Navbar";
import sidevector from "../../assets/sidevector.svg";
import Lottie from "react-lottie";
import { animationDefaultOptions } from "../../Utils/Functions";
import TestRecordModal from "../../Components/TestRecordModal";
import { addLang } from "../../Redux_Services/slices/langSlice";
import { useSelector, useDispatch } from "react-redux";
import { translations } from "../../translation/translate";

const SearchPatient = () => {
  const [searchInput, setSearchInput] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const langData = useSelector((state) => state.langSlice.langData);
  const [loader, setLoader] = useState(false);

  // Fetch patients based on the search input
  const {
    data: patientsData,
    loading,
    status,
    error,
  } = useFetchPatients(searchInput);
  console.log("data = ", patientsData.patients);
  // console.log("23 = ", patientsData);

  // Handle input change to update search term
  const handleInputChange = (newValue, loadComp) => {
    setLoader(loadComp);
    setSearchInput(newValue); // Update the state with the new value from Navbar
  };
  const handleModalOpen = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="min-h-screen w-full bg-[#C7CEE5] relative">
      {/* Navbar */}
      <Navbar searchInput={searchInput} handleInputChange={handleInputChange} />

      {/* Main Content */}
      <div className="flex flex-col items-center justify-center py-10 mt-10">
        {searchInput.trim() === "" ? (
          // Show this message when no search term is entered
          <div className="mt-40">
            <Lottie
              isClickToPauseDisabled={true}
              height={200}
              width={200}
              options={animationDefaultOptions}
            />
            <p className="text-center mt-4 font-bold text-purple-500 text-4xl">
              {translations[langData].searchPatient}
            </p>
          </div>
        ) : loader && !status ? (
          // Show loading state
          <p className="text-center mt-96 font-bold text-purple-500 text-4xl">
            {translations[langData].loading}
          </p>
        ) : patientsData?.patients?.length > 0 ? (
          // Show patient cards

          <div className="grid grid-cols-4 gap-x-40 gap-y-10 hover: z-10 w-full max-w-screen-lg ">
            {patientsData.patients.map((patient) => (
              <PatientCard
                key={patient.PatientID}
                patient={patient}
                // patientID={patient.PatientID} // Passing patientID as a separate prop
                labNo={patientsData.labNo} // Passing labNo as a separate prop
                handleOpenModal={handleModalOpen}
              />
            ))}
          </div>
        ) : (
          // Show no patients found message
          <p className="text-center mt-96 font-bold text-purple-500 text-4xl">
            {translations[langData].noPatient}
          </p>
        )}
      </div>

      {/* Background Image */}
      <div className="absolute bottom-0 right-0 z-0 w-full lg:w-auto">
        <img src={sidevector} alt="sidevector" className="w-full lg:w-auto" />
      </div>

      <TestRecordModal isOpen={isModalOpen} onClose={handleModalOpen} />
    </div>
  );
};

export default SearchPatient;
