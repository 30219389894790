// src/Components/Navbar.js
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import home from "../assets/home.svg";
import bell from "../assets/bell.svg";
import add from "../assets/add.svg";
import logout from "../assets/logout.svg";
import search from "../assets/search.svg";
import american from "../../src/assets/american.png";
import indian from "../../src/assets/indian.png";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addLang } from "../Redux_Services/slices/langSlice";
import { translations } from "../translation/translate";
import { logoutReducer } from "../Redux_Services/slices/authSlice";

export default function Navbar({ searchInput, handleInputChange }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const langData = useSelector((state) => state.langSlice.langData || []);
  const [localSearchInput, setLocalSearchInput] = useState(searchInput); // Local state for input
  const [loadCompo, setLoader] = useState(true);

  const handleSearch = () => {
    // Log or handle search based on localSearchInput
    console.log("Searching for:", localSearchInput);
    setLoader(true);
    // Call to parent function to update global state
    handleInputChange(localSearchInput, loadCompo);
  };

  const handleToggle = () => {
    const newLang = langData === "english" ? "hindi" : "english";
    dispatch(addLang(newLang));
  };

  const homeClick = () => {
    navigate("/home");
  };

  const handlePatientClick = () => {
    navigate("/add-patientdetails");
  };

  const searchClick = () => {
    navigate("/search-patient");
  };

  const logoutClick = () => {
    localStorage.removeItem("cookieFallback");
    //add in code :- clearing the redux state to proper logout
    dispatch(logoutReducer());
    navigate("/auth/login");
  };

  return (
    <div className="bg-[#C7CEE5] pt-10 px-28">
      {/* Navbar */}
      <nav className="flex justify-between items-center px-8">
        {/* Logo and Search */}
        <div className="flex items-center">
          <div className="text-4xl text-[#9669E0] font-bold">
            {translations[langData].scAn}
          </div>

          {/* Conditionally render search input or icon based on route */}
          {location.pathname === "/search-patient" ? (
            <div className="relative max-w-xs ml-10 flex items-center">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <img src={search} alt="search icon" className="w-4 h-4" />
              </span>
              <input
                type="text"
                value={localSearchInput} // Bind the value to localSearchInput
                placeholder={translations[langData].search}
                onChange={(e) => setLocalSearchInput(e.target.value)} // Allow input change
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(); // Call the search function on Enter key
                  }
                }}
                className="pl-10 pr-4 py-2 w-full bg-transparent border-2 border-[#9669E0] rounded-lg focus:outline-none focus:ring-1 focus:ring-purple-500"
              />
              <button
                onClick={handleSearch} // Call the search function on button click
                className="ml-2 px-4 py-2  bg-[#9669E0] text-white rounded-lg hover:bg-purple-700"
              >
                {translations[langData].search}
              </button>
            </div>
          ) : (
            <button onClick={searchClick} className="flex items-center ml-6">
              <img src={search} className="w-8 h-8" alt="search icon" />
            </button>
          )}
        </div>

        {/* Icons */}
        <div className="flex items-center space-x-20">
          <Link to={"/add-patient"} onClick={homeClick}>
            <img src={home} className="w-8 h-8" alt="home icon" />
          </Link>
          <Link to={"/notifications"}>
            <img src={bell} className="w-8 h-8" alt="notification icon" />
          </Link>
          <button onClick={handlePatientClick}>
            <img src={add} className="w-8 h-8" alt="add icon" />
          </button>
          <div className="flex items-center mr-[4px] w-10">
            <label className="inline-flex relative items-center mr-5 cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={langData === "hindi"}
                onChange={handleToggle}
              />
              <div className="w-16 h-9 bg-gray-200 rounded-full peer-focus:ring-green-300 transition-colors peer-checked:bg-[#9669E0] shadow-md">
                <span
                  className={`absolute top-[3px] ${langData === "hindi" ? "left-[28px]" : "left-[3px]"
                    } flex items-center justify-center w-7 h-7 transition-transform duration-300 transform`}
                >
                  <span className="w-full h-full rounded-full overflow-hidden shadow-md">
                    {langData === "english" ? (
                      <img
                        src={american}
                        alt="English"
                        className="w-full h-full object-cover rounded-full"
                      />
                    ) : (
                      <img
                        src={indian}
                        alt="Hindi"
                        className="w-full h-full object-cover rounded-full"
                      />
                    )}
                  </span>
                </span>
              </div>
            </label>
          </div>
          <button onClick={logoutClick}>
            <img src={logout} className="w-8 h-8" alt="logout icon" />
          </button>
        </div>
      </nav>
    </div>
  );
}
