// src/hooks/useFetchPatients.js
import { useState, useEffect } from "react";
import useDebounce from "./useDebounce"; // Import the debounce hook
import { useSelector } from "react-redux";

const useFetchPatients = (searchTerm) => {
  const loginData = useSelector((state) => state.authSlice.user.CentreID);
  const [data, setData] = useState({ labNo: loginData, patients: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(false);
  // console.log("20 = ", loginData);
  // console.log("12 = ", data);

  // Debounce the searchTerm with a delay of 2000 milliseconds
  const debouncedSearchTerm = useDebounce(searchTerm, 2000);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      // Determine the URL based on whether there's a search term
      const url = `https://api.superceuticals.in/patient/search-patient/${debouncedSearchTerm}`;
      // console.log("22", url);
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoiVEVTVE5PVyIsIlBhdGllbnRJRCI6IlNDMjNGMDAwMDAyIiwiY29udGFjdE51bWJlciI6Ijg4MDU5OTEwNzkiLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE3MjcxMDQzMTd9.rW70KCsDPR9DU8SDRPDxtTFcX2mT2nb_1lZoYgoVzEk", // Replace with your correct Bearer token
          },
        });
        // console.log("33", response);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        // console.log("Response data----:", result, "result", result.patients);
        setData({ labNo: loginData, patients: result.patients || [] });
        setStatus(true);
      } catch (error) {
        console.error("Error fetching patient data:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [debouncedSearchTerm]);

  return { data, loading, error, status };
};

export default useFetchPatients;
