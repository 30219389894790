import { createSlice } from "@reduxjs/toolkit";
const storedUser = JSON.parse(localStorage.getItem("userDetails"));
const initialState = {
  user: storedUser || null,
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: "techSlice",
  initialState,
  reducers: {
    loginReducer: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    logoutReducer: (state) => {
      state.user = null;
      state.isAuthenticated = false;
    },
  },
});

export const { loginReducer, logoutReducer } = authSlice.actions;
export default authSlice.reducer;
