import React from "react";
// export const ddimer = () => {
//   return (
//     <div>
//       <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">
//         D-Dimer Summary
//       </h2>
//       <table className="min-w-full bg-white border border-gray-300 mb-4">
//         <thead>
//           <tr className="bg-[#D7D4F8] text-[#845BB3]">
//             <th className="border border-gray-300 px-4 py-2">Test Name</th>
//             <th className="border border-gray-300 px-4 py-2">Results</th>
//             <th className="border border-gray-300 px-4 py-2">Unit</th>
//             <th className="border border-gray-300 px-4 py-2">
//               Bio. Ref. Intv.
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">D-Dimer</td>
//             <td className="border border-gray-300 px-4 py-2">312</td>
//             <td className="border border-gray-300 px-4 py-2">ng/mL</td>
//             <td className="border border-gray-300 px-4 py-2">{"<"}500</td>
//           </tr>
//         </tbody>
//       </table>

//       <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
//         Interpretation
//       </h3>
//       <p className="mb-4">
//         D-Dimer is a fibrin degradation product, present after blood clots are
//         degraded. It is elevated in conditions such as deep venous thrombosis
//         (DVT), pulmonary embolism (PE), and disseminated intravascular
//         coagulation (DIC).
//       </p>

//       <h4 className="text-md font-semibold text-[#845BB3] mb-2">
//         Reference Range
//       </h4>
//       <table className="min-w-full bg-white border border-gray-300 mb-4">
//         <thead>
//           <tr className="bg-[#D7D4F8] text-[#845BB3]">
//             <th className="border border-gray-300 px-4 py-2">Condition</th>
//             <th className="border border-gray-300 px-4 py-2">Level (ng/mL)</th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">Normal</td>
//             <td className="border border-gray-300 px-4 py-2">{"<"}500</td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">High</td>
//             <td className="border border-gray-300 px-4 py-2">{">"}500</td>
//           </tr>
//         </tbody>
//       </table>
//     </div>
//   );
// };

export const cardiac = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">
        Lipid Profile Summary
      </h2>
      <p className="mb-2">
        Cholesterol is a vital chemical present in the body and performs many
        functions such as coating the cells of the body, forming the cell
        membrane, and producing certain components called bile acids which aid
        in digestion of fats.
      </p>
      <p className="mb-2">
        Additionally, it performs an imperative role in the production of
        certain vital nutrients like vitamin D and sex hormones like estrogen,
        progesterone, and testosterone (steroid hormones).
      </p>
      <p className="mb-4">
        Elevated levels of cholesterol indicate a risk for heart disease,
        atherosclerosis, and stroke.
      </p>

      {/* For Adults Table */}
      <h3 className="text-xl font-semibold text-[#845BB3] mb-4">
        Recommended by National Lipid Association 2014 (For Adults)
      </h3>
      <table className="min-w-full bg-white border border-gray-300 mb-6">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Analyte</th>
            <th className="border border-gray-300 px-4 py-2">
              Optimal (mg/dL)
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Above Optimal (mg/dL)
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Borderline High (mg/dL)
            </th>
            <th className="border border-gray-300 px-4 py-2">High (mg/dL)</th>
            <th className="border border-gray-300 px-4 py-2">
              Very High (mg/dL)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Cholesterol, Total
            </td>
            <td className="border border-gray-300 px-4 py-2">&lt;200</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
            <td className="border border-gray-300 px-4 py-2">200 – 239</td>
            <td className="border border-gray-300 px-4 py-2">&gt;=240</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Triglycerides</td>
            <td className="border border-gray-300 px-4 py-2">&lt;150</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
            <td className="border border-gray-300 px-4 py-2">150 – 199</td>
            <td className="border border-gray-300 px-4 py-2">200 – 499</td>
            <td className="border border-gray-300 px-4 py-2">&gt;=500</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">HDL</td>
            <td className="border border-gray-300 px-4 py-2">
              &gt;40 (males) &gt;50 (females)
            </td>
            <td className="border border-gray-300 px-4 py-2">-</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">LDL</td>
            <td className="border border-gray-300 px-4 py-2">&lt;100</td>
            <td className="border border-gray-300 px-4 py-2">101 – 129</td>
            <td className="border border-gray-300 px-4 py-2">130 – 159</td>
            <td className="border border-gray-300 px-4 py-2">160 – 189</td>
            <td className="border border-gray-300 px-4 py-2">&gt;=190</td>
          </tr>
        </tbody>
      </table>

      {/* For Children Table */}
      <h3 className="text-xl font-semibold text-[#845BB3] mb-4">
        Recommended by National Lipid Association 2014 (For Children)
      </h3>
      <table className="min-w-full bg-white border border-gray-300 mb-6">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Analyte</th>
            <th className="border border-gray-300 px-4 py-2">
              Optimal (mg/dL)
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Borderline High (mg/dL)
            </th>
            <th className="border border-gray-300 px-4 py-2">High (mg/dL)</th>
            <th className="border border-gray-300 px-4 py-2">
              Very High (mg/dL)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Cholesterol, Total
            </td>
            <td className="border border-gray-300 px-4 py-2">&lt;170</td>
            <td className="border border-gray-300 px-4 py-2">171 – 199</td>
            <td className="border border-gray-300 px-4 py-2">&gt;=200</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Triglycerides</td>
            <td className="border border-gray-300 px-4 py-2">&lt;150</td>
            <td className="border border-gray-300 px-4 py-2">150 – 199</td>
            <td className="border border-gray-300 px-4 py-2">200 – 499</td>
            <td className="border border-gray-300 px-4 py-2">&gt;=500</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">HDL</td>
            <td className="border border-gray-300 px-4 py-2">40 – 60</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
          </tr>
        </tbody>
      </table>

      {/* Comments Section */}
      <h3 className="text-xl font-semibold text-[#845BB3] mt-2 mb-4">
        Comments
      </h3>
      <ul className="list-disc pl-6 mb-4">
        <li>
          Measurements in the same patient can show physiological & analytical
          variations.
        </li>
        <li>
          Three serial samples 1 week apart are recommended for Total
          Cholesterol, Triglycerides, HDL & LDL Cholesterol.
        </li>
        <li>
          As per NLA-2014 guidelines, all adults above the age of 20 years
          should be screened for lipid status. Selective screening of children
          above the age of 2 years with a family history of premature
          cardiovascular disease or those with at least one parent with high
          total cholesterol is recommended.
        </li>
        <li>
          Low HDL levels are associated with increased risk for Atherosclerotic
          Cardiovascular Disease (ASCVD) due to insufficient HDL being available
          to participate in reverse cholesterol transport, the process by which
          cholesterol is eliminated from peripheral tissues.
        </li>
        <li>
          NLA-2014 identifies Non HDL Cholesterol (an indicator of all
          atherogenic lipoproteins such as LDL, VLDL, IDL, Lpa, Chylomicron
          remnants) along with LDL-cholesterol as co- primary target for
          cholesterol lowering therapy. Note that major risk factors can modify
          treatment goals for LDL & Non HDL
        </li>
        <li>
          Apolipoprotein B is an optional, secondary lipid target for treatment
          once LDL & Non HDL goals have been achieved
        </li>
        <li>
          Additional testing for Apolipoprotein B, hsCRP, Lp (a) & LP-PLA2
          should be considered among patients with moderate risk for ASCVD for
          risk refinement
        </li>
        <li>
          NCEP identifies elevated Triglycerides as an independent risk factor
          for Coronary Heart Disease (CHD).
        </li>
        <li>
          ATP III suggested the addition of Non HDL Cholesterol (Total
          Cholesterol – HDL Cholesterol) as an indicator of all atherogenic
          lipoproteins (mainly LDL & VLDL). The Non HDL Cholesterol is used as a
          secondary target of therapy in persons with triglycerides {">"}=200
          mg/dL. The goal for Non HDL Cholesterol in those with increased
          triglyceride is 30 mg/dL above that set for LDL Cholesterol.{" "}
        </li>
        <li>
          For calculation of CHD risk, history of smoking, any medication for
          hypertension & current blood pressure levels are required.
        </li>
      </ul>
    </div>
  );
};

export const lipidprofile = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">Summary</h2>
      <p className="mb-2">
        Cholesterol is a vital chemical present in the body and performs many
        functions such as coating the cells of the body, forming the cell
        membrane, and producing certain components called bile acids which aid
        in digestion of fats.
      </p>
      <p className="mb-2">
        Additionally, it performs an imperative role in the production of
        certain vital nutrients like vitamin D and sex hormones like estrogen,
        progesterone, and testosterone (steroid hormones).
      </p>
      <p className="mb-4">
        Elevated levels of cholesterol indicate a risk for heart disease,
        atherosclerosis, and stroke.
      </p>

      {/* For Adults Table */}
      <h3 className="text-xl font-semibold text-[#845BB3] mb-4">
        Recommended by National Lipid Association 2014 (For Adults)
      </h3>
      <table className="min-w-full bg-white border border-gray-300 mb-6">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Analyte</th>
            <th className="border border-gray-300 px-4 py-2">
              Optimal (mg/dL)
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Above Optimal (mg/dL)
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Borderline High (mg/dL)
            </th>
            <th className="border border-gray-300 px-4 py-2">High (mg/dL)</th>
            <th className="border border-gray-300 px-4 py-2">
              Very High (mg/dL)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Cholesterol, Total
            </td>
            <td className="border border-gray-300 px-4 py-2">&lt;200</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
            <td className="border border-gray-300 px-4 py-2">200 – 239</td>
            <td className="border border-gray-300 px-4 py-2">&gt;=240</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Triglycerides</td>
            <td className="border border-gray-300 px-4 py-2">&lt;150</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
            <td className="border border-gray-300 px-4 py-2">150 – 199</td>
            <td className="border border-gray-300 px-4 py-2">200 – 499</td>
            <td className="border border-gray-300 px-4 py-2">&gt;=500</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">HDL</td>
            <td className="border border-gray-300 px-4 py-2">
              &gt;40 (males) &gt;50 (females)
            </td>
            <td className="border border-gray-300 px-4 py-2">-</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">LDL</td>
            <td className="border border-gray-300 px-4 py-2">&lt;100</td>
            <td className="border border-gray-300 px-4 py-2">101 – 129</td>
            <td className="border border-gray-300 px-4 py-2">130 – 159</td>
            <td className="border border-gray-300 px-4 py-2">160 – 189</td>
            <td className="border border-gray-300 px-4 py-2">&gt;=190</td>
          </tr>
        </tbody>
      </table>

      {/* For Children Table */}
      <h3 className="text-xl font-semibold text-[#845BB3] mb-4">
        Recommended by National Lipid Association 2014 (For Children)
      </h3>
      <table className="min-w-full bg-white border border-gray-300 mb-6">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Analyte</th>
            <th className="border border-gray-300 px-4 py-2">
              Optimal (mg/dL)
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Borderline High (mg/dL)
            </th>
            <th className="border border-gray-300 px-4 py-2">High (mg/dL)</th>
            <th className="border border-gray-300 px-4 py-2">
              Very High (mg/dL)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Cholesterol, Total
            </td>
            <td className="border border-gray-300 px-4 py-2">&lt;170</td>
            <td className="border border-gray-300 px-4 py-2">171 – 199</td>
            <td className="border border-gray-300 px-4 py-2">&gt;=200</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Triglycerides</td>
            <td className="border border-gray-300 px-4 py-2">&lt;150</td>
            <td className="border border-gray-300 px-4 py-2">150 – 199</td>
            <td className="border border-gray-300 px-4 py-2">200 – 499</td>
            <td className="border border-gray-300 px-4 py-2">&gt;=500</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">HDL</td>
            <td className="border border-gray-300 px-4 py-2">40 – 60</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
          </tr>

          <tr>
            <td className="border border-gray-300 px-4 py-2">LDL</td>
            <td className="border border-gray-300 px-4 py-2">&lt;110</td>
            <td className="border border-gray-300 px-4 py-2">111 – 129</td>
            <td className="border border-gray-300 px-4 py-2">&gt;=500</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
          </tr>
        </tbody>
      </table>

      <p>
        <strong>Note</strong> - Reference Interval as per National Cholesterol
        Education Program (NCEP) Adult Treatment Panel III Report.
      </p>

      {/* Comments Section */}
      <h3 className="text-xl font-semibold text-[#845BB3] mt-2 mb-4">
        Comments
      </h3>
      <ul className="list-disc pl-6 mb-4">
        <li>
          Measurements in the same patient can show physiological & analytical
          variations.
        </li>
        <li>
          Three serial samples 1 week apart are recommended for Total
          Cholesterol, Triglycerides, HDL & LDL Cholesterol.
        </li>
        <li>
          As per NLA-2014 guidelines, all adults above the age of 20 years
          should be screened for lipid status. Selective screening of children
          above the age of 2 years with a family history of premature
          cardiovascular disease or those with at least one parent with high
          total cholesterol is recommended.
        </li>
        <li>
          Low HDL levels are associated with increased risk for Atherosclerotic
          Cardiovascular Disease (ASCVD) due to insufficient HDL being available
          to participate in reverse cholesterol transport, the process by which
          cholesterol is eliminated from peripheral tissues.
        </li>
        <li>
          NLA-2014 identifies Non HDL Cholesterol (an indicator of all
          atherogenic lipoproteins such as LDL, VLDL, IDL, Lpa, Chylomicron
          remnants) along with LDL-cholesterol as co- primary target for
          cholesterol lowering therapy. Note that major risk factors can modify
          treatment goals for LDL & Non HDL
        </li>
        <li>
          Apolipoprotein B is an optional, secondary lipid target for treatment
          once LDL & Non HDL goals have been achieved
        </li>
        <li>
          Additional testing for Apolipoprotein B, hsCRP, Lp (a) & LP-PLA2
          should be considered among patients with moderate risk for ASCVD for
          risk refinement
        </li>
        <li>
          NCEP identifies elevated Triglycerides as an independent risk factor
          for Coronary Heart Disease (CHD).
        </li>
        <li>
          ATP III suggested the addition of Non HDL Cholesterol (Total
          Cholesterol – HDL Cholesterol) as an indicator of all atherogenic
          lipoproteins (mainly LDL & VLDL). The Non HDL Cholesterol is used as a
          secondary target of therapy in persons with triglycerides {">"}=200
          mg/dL. The goal for Non HDL Cholesterol in those with increased
          triglyceride is 30 mg/dL above that set for LDL Cholesterol.{" "}
        </li>
        <li>
          For calculation of CHD risk, history of smoking, any medication for
          hypertension & current blood pressure levels are required.
        </li>
      </ul>
    </div>
  );
};
export const diabetic = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      {/* Interpretation Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation
      </h3>
      <table className="min-w-full bg-white border border-gray-300 mb-6">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">
              Reference Group
            </th>
            <th className="border border-gray-300 px-4 py-2">HbA1c in %</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Non Diabetic Adults
            </td>
            <td className="border border-gray-300 px-4 py-2">&lt; 5.7</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              At risk (Pre-Diabetes)
            </td>
            <td className="border border-gray-300 px-4 py-2">5.7 – 6.4</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Diagnosing Diabetic
            </td>
            <td className="border border-gray-300 px-4 py-2">&gt;= 6.5</td>
          </tr>
        </tbody>
      </table>

      {/* Therapeutic Goals */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Therapeutic Goals for Glycemic Control
      </h3>
      <div className="mb-4">
        <h4 className="text-md font-semibold text-[#845BB3]">
          Age &gt; 19 Years
        </h4>
        <p>
          Goal of therapy: <strong>&lt; 7%</strong>
        </p>
        <p>
          Action suggested: <strong>&gt; 8%</strong>
        </p>
      </div>
      <div className="mb-6">
        <h4 className="text-md font-semibold text-[#845BB3]">
          Age &lt; 19 Years
        </h4>
        <p>
          Goal of therapy: <strong>&lt; 7.5%</strong>
        </p>
      </div>

      {/* Note Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Note</h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Conditions that shorten erythrocyte life (e.g., sickle cell disease,
          pregnancy, haemodialysis) can falsely lower HbA1c levels.
        </li>
        <li>
          Glycemark tests may help identify severe hyperglycemic excursions for
          patients with HbA1c levels between 7 – 8%.
        </li>
        <li>
          Low glycated hemoglobin (&lt; 4%) in non-diabetic individuals may
          indicate systemic inflammatory diseases. Clinical correlation
          suggested.
        </li>
      </ul>

      {/* eAG Estimation Formula */}
      <h4 className="text-md font-semibold text-[#845BB3] mb-2">
        eAG Estimation Formula
      </h4>
      <p className="mb-6">
        <strong>eAG (mg/dL) = 28.7 × HbA1c - 46.7</strong>
      </p>

      {/* Monitoring Glycemic Control */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Monitoring Glycemic Control
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>Excellent Control: 6 to 7%</li>
        <li>Fair to Good Control: 7 to 8%</li>
        <li>Unsatisfactory Control: 8 to 10%</li>
        <li>Poor Control: More than 10%</li>
      </ul>

      {/* Comments */}
      <h4 className="text-md font-semibold text-[#845BB3] mb-2">Comments</h4>
      <p>
        HbA1c provides an index of average blood glucose levels over the past
        8–12 weeks, serving as a better indicator of long-term glycemic control
        than blood or urinary glucose determinations.
      </p>
      <table className="min-w-full bg-white border border-gray-300 mb-6">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">HbA1c in %</th>
            <th className="border border-gray-300 px-4 py-2">
              Mean Plasma Glucose (mg/dL)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">6</td>
            <td className="border border-gray-300 px-4 py-2">126</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">7</td>
            <td className="border border-gray-300 px-4 py-2">154</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">8</td>
            <td className="border border-gray-300 px-4 py-2">183</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">9</td>
            <td className="border border-gray-300 px-4 py-2">212</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">10</td>
            <td className="border border-gray-300 px-4 py-2">240</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">11</td>
            <td className="border border-gray-300 px-4 py-2">269</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">12</td>
            <td className="border border-gray-300 px-4 py-2">298</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

// export const bloodpressure = () => {
//   return (
//     <div className="max-w-screen-lg mx-auto p-6 bg-white rounded-lg shadow-md">
//       <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">
//         Blood Pressure Summary
//       </h2>

//       <p className="mb-4">
//         Blood pressure is the force exerted by circulating blood against the
//         walls of blood vessels, primarily the arteries. While the average BP
//         remains relatively stable, it can fluctuate throughout the
//         day—decreasing during rest and increasing during moments of excitement
//         or stress. Persistently high resting blood pressure can lead to damage,
//         such as scarring or stiffening of the arteries.
//       </p>

//       <p className="mb-4">
//         Blood pressure readings consist of two values: systolic and diastolic.
//         For example, a reading of 120/80 mm Hg indicates a systolic pressure of
//         120 mm Hg and a diastolic pressure of 80 mm Hg.
//       </p>

//       <h3 className="text-xl font-semibold text-[#845BB3] mb-2">
//         Blood Pressure Categories
//       </h3>

//       <table className="min-w-full bg-white border border-gray-300 mb-6">
//         <thead>
//           <tr className="bg-[#D7D4F8] text-[#845BB3]">
//             <th className="border border-gray-300 px-4 py-2">Category</th>
//             <th className="border border-gray-300 px-4 py-2">
//               Systolic (mm Hg)
//             </th>
//             <th className="border border-gray-300 px-4 py-2">
//               Diastolic (mm Hg)
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr className="border-b">
//             <td className="border border-gray-300 px-4 py-2">Low</td>
//             <td className="border border-gray-300 px-4 py-2">&lt; 90</td>
//             <td className="border border-gray-300 px-4 py-2">&lt; 50</td>
//           </tr>
//           <tr className="border-b">
//             <td className="border border-gray-300 px-4 py-2">Normal</td>
//             <td className="border border-gray-300 px-4 py-2">&lt; 120</td>
//             <td className="border border-gray-300 px-4 py-2">&lt; 80</td>
//           </tr>
//           <tr className="border-b">
//             <td className="border border-gray-300 px-4 py-2">
//               Pre Hypertension
//             </td>
//             <td className="border border-gray-300 px-4 py-2">120 – 139</td>
//             <td className="border border-gray-300 px-4 py-2">80 – 89</td>
//           </tr>
//           <tr className="border-b">
//             <td className="border border-gray-300 px-4 py-2">
//               High Blood Pressure (Stage 1)
//             </td>
//             <td className="border border-gray-300 px-4 py-2">140 – 159</td>
//             <td className="border border-gray-300 px-4 py-2">90 – 99</td>
//           </tr>
//           <tr className="border-b">
//             <td className="border border-gray-300 px-4 py-2">
//               High Blood Pressure (Stage 2)
//             </td>
//             <td className="border border-gray-300 px-4 py-2">160 – 179</td>
//             <td className="border border-gray-300 px-4 py-2">100 – 110</td>
//           </tr>
//           <tr className="border-b">
//             <td className="border border-gray-300 px-4 py-2">
//               Hypertensive Crisis
//             </td>
//             <td className="border border-gray-300 px-4 py-2">{">"} 180</td>
//             <td className="border border-gray-300 px-4 py-2">{">"} 110</td>
//           </tr>
//         </tbody>
//       </table>

//       <h4 className="mt-4 text-lg font-semibold text-[#845BB3]">
//         Effects of Blood Pressure
//       </h4>
//       <div className="mb-4">
//         <h5 className="font-semibold">Increased Blood Pressure:</h5>
//         <ul className="list-disc list-inside mt-2">
//           <li>Heart Attack</li>
//           <li>Stroke</li>
//           <li>Vision Loss</li>
//           <li>Heart Failure</li>
//           <li>Kidney Failure</li>
//           <li>Dementia</li>
//           <li>Erectile Dysfunction</li>
//         </ul>
//       </div>
//       <div>
//         <h5 className="font-semibold">Low Blood Pressure:</h5>
//         <ul className="list-disc list-inside mt-2">
//           <li>Dizziness</li>
//           <li>Loss of consciousness</li>
//           <li>Weakness</li>
//           <li>Sharp and sustained drop may result in a hypoxic state</li>
//         </ul>
//       </div>

//       <h4 className="mt-4 text-lg font-semibold text-[#845BB3]">
//         Key Takeaways
//       </h4>
//       <ul className="list-disc list-inside mt-2">
//         <li>
//           <strong>Normal Blood Pressure:</strong> Maintains health and reduces
//           risk of cardiovascular diseases.
//         </li>
//         <li>
//           <strong>Pre Hypertension and Hypertension:</strong> Indicates
//           increased risk; management and lifestyle changes are recommended.
//         </li>
//         <li>
//           <strong>Hypertensive Crisis:</strong> Requires immediate medical
//           attention to prevent serious health complications.
//         </li>
//       </ul>
//     </div>
//   );
// };

export const glucose = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation
      </h3>
      <table className="min-w-full bg-white border border-gray-300 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Status</th>
            <th className="border border-gray-300 px-4 py-2">
              Fasting Plasma Glucose (mg/dL)
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Post Prandial Plasma Glucose (mg/dL)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Normal</td>
            <td className="border border-gray-300 px-4 py-2">70 – 100</td>
            <td className="border border-gray-300 px-4 py-2">70 – 140</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Pre-Diabetes</td>
            <td className="border border-gray-300 px-4 py-2">101 – 125</td>
            <td className="border border-gray-300 px-4 py-2">141 – 199</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Diabetes Mellitus
            </td>
            <td className="border border-gray-300 px-4 py-2">&gt; 126</td>
            <td className="border border-gray-300 px-4 py-2">&gt; 200</td>
          </tr>
        </tbody>
      </table>

      <h4 className="text-md font-semibold text-[#845BB3] mb-2">Note:</h4>
      <ul className="list-disc ml-6 mb-4">
        <li>Very low glucose levels can cause severe CNS dysfunction.</li>
        <li>
          Glucose levels &gt; 450 mg/dL in adults may result in diabetic
          ketoacidosis and are considered critical.
        </li>
        <li>
          An individual may show a higher fasting glucose level compared to
          post-prandial glucose due to:
          <ul className="list-disc ml-6">
            <li>The glycaemic index and response to food consumed.</li>
            <li>
              Changes in body composition and increased insulin response and
              sensitivity.
            </li>
            <li>
              Alimentary hypoglycaemia, renal glycosuria, effects of oral
              hypoglycemics, and insulin treatment.
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export const hscrp = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Cardiovascular Risk Assessment
      </h3>
      <table className="min-w-full bg-white border border-gray-300 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">
              Cardio CRP in mg/L
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Cardiovascular Risk
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">&lt; 1.0</td>
            <td className="border border-gray-300 px-4 py-2">Low</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">1.0 – 3.0</td>
            <td className="border border-gray-300 px-4 py-2">Average</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">3.0 – 10.0</td>
            <td className="border border-gray-300 px-4 py-2">High</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">&gt; 10.0</td>
            <td className="border border-gray-300 px-4 py-2">
              Persistent elevation may represent Non-Cardiovascular Inflammation
            </td>
          </tr>
        </tbody>
      </table>

      <h4 className="text-md font-semibold text-[#845BB3] mb-2">Note:</h4>
      <p className="mb-2">
        To assess vascular risk, it is recommended to test hsCRP levels 2 or
        more weeks apart and calculate the average.
      </p>

      <h4 className="text-md font-semibold text-[#845BB3] mb-2">Comments:</h4>
      <p className="mb-2">
        High sensitivity C Reactive Protein (hsCRP) significantly improves
        cardiovascular risk assessment as it is a strong predictor of future
        coronary events. It reveals the risk of future Myocardial infarction and
        Stroke among healthy men and women, independent of traditional risk
        factors. It identifies patients at risk of first Myocardial infarction
        even with low to moderate lipid levels.
      </p>
      <p className="mb-2">
        The risk of recurrent cardiovascular events also correlates well with
        hsCRP levels. It is a powerful independent risk determinant in the
        prediction of incident Diabetes.
      </p>
    </div>
  );
};
export const other = () => {
  return <div></div>;
};

export const t3 = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">
        Free T3 (Triiodothyronine) Summary
      </h2>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Reference Ranges for Free T3
      </h3>
      <table className="min-w-full bg-white border border-gray-300 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">
              Reference Group
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Reference Range (pg/mL)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Adults</td>
            <td className="border border-gray-300 px-4 py-2">2.3 – 4.1</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Children</td>
            <td className="border border-gray-300 px-4 py-2">3.5 – 6.5</td>
          </tr>
        </tbody>
      </table>

      <h4 className="text-md font-semibold text-[#845BB3] mb-2">
        Interpretation:
      </h4>
      <ul className="list-disc ml-6 mb-4">
        <li>
          High Free T3 levels are indicative of hyperthyroidism or T3 toxicosis.
        </li>
        <li>
          Low Free T3 levels may occur in hypothyroidism or non-thyroidal
          illnesses.
        </li>
        <li>
          Free T3 is more specific for assessing active thyroid function
          compared to Total T3.
        </li>
      </ul>

      <h4 className="text-md font-semibold text-[#845BB3] mb-2">
        Clinical Use:
      </h4>
      <ul className="list-disc ml-6 mb-4">
        <li>Diagnosing and monitoring hyperthyroidism</li>
        <li>Assessment of thyroid function in complex cases</li>
        <li>Evaluating thyroid disorders during pregnancy</li>
      </ul>

      <h4 className="text-md font-semibold text-[#845BB3] mb-2">Note:</h4>
      <p className="mb-4">
        Biotin supplementation can interfere with some immunoassays for Free T3.
        An 8-hour gap after last biotin intake is advised before sample
        collection.
      </p>

      <div className="border-t-2 border-gray-300 my-4"></div>
      <p className="text-center text-gray-500 text-sm">
        This test result should be interpreted by a healthcare professional in
        conjunction with the patient’s clinical history, symptoms, and other
        diagnostic findings.
      </p>
    </div>
  );
};

// Component for Total T4 (Thyroxine) Summary
export const t4 = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">
        Total T4 (Thyroxine) Summary
      </h2>

      <table className="min-w-full bg-white border border-gray-300 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Test Name</th>
            <th className="border border-gray-300 px-4 py-2">Results</th>

            <th className="border border-gray-300 px-4 py-2">Unit</th>
            <th className="border border-gray-300 px-4 py-2">
              Bio. Ref. Intv.
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Total T4 (Thyroxine)
            </td>
            <td className="border border-gray-300 px-4 py-2">13.7</td>
            <td className="border border-gray-300 px-4 py-2">μg/dL</td>
            <td className="border border-gray-300 px-4 py-2">5.01 – 12.45</td>
          </tr>
        </tbody>
      </table>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Reference Ranges for Total T4
      </h3>
      <table className="min-w-full bg-white border border-gray-300 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">
              Reference Group
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Reference Range (μg/dL)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">1 – 3 Days</td>
            <td className="border border-gray-300 px-4 py-2">11.80 – 22.60</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">&gt; 15 Years</td>
            <td className="border border-gray-300 px-4 py-2">5.01 – 12.45</td>
          </tr>
        </tbody>
      </table>

      <h4 className="text-md font-semibold text-[#845BB3] mb-2">
        Interpretation:
      </h4>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Total T4 values may be affected by changes in serum proteins and
          binding sites.
        </li>
        <li>
          Free T4 levels are preferred for more accurate thyroid function
          analysis.
        </li>
      </ul>

      <h4 className="text-md font-semibold text-[#845BB3] mb-2">
        Clinical Use:
      </h4>
      <ul className="list-disc ml-6 mb-4">
        <li>Primary Hypothyroidism</li>
        <li>Hyperthyroidism</li>
        <li>Hypothalamic-Pituitary hypothyroidism</li>
        <li>Autoimmune thyroid disease</li>
      </ul>

      <h4 className="text-md font-semibold text-[#845BB3] mb-2">Note:</h4>
      <p className="mb-4">
        High-dose Biotin supplements can interfere with immunoassays. A wait
        time of 8 hours before blood draw is recommended.
      </p>
    </div>
  );
};
export const tsh = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Reference Ranges for TSH
      </h3>
      <table className="min-w-full bg-white border border-gray-300 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">
              Reference Group
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Reference Range (mIU/L)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">1 – 4 Days</td>
            <td className="border border-gray-300 px-4 py-2">1.00 – 39.00</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2"> 20 Years</td>
            <td className="border border-gray-300 px-4 py-2">0.45 – 4.50</td>
          </tr>
        </tbody>
      </table>

      <h4 className="text-md font-semibold text-[#845BB3] mb-2">
        Interpretation:
      </h4>
      <ul className="list-disc ml-6 mb-4">
        <li>
          TSH results between 4.5 to 15 show considerable variation and may
          require repeat testing.
        </li>
        <li>
          TSH results between 0.1 to 0.45 need to be correlated with clinical
          symptoms and age.
        </li>
        <li>
          Non-thyroidal illnesses (e.g., infections, liver disease, trauma) can
          alter TSH levels.
        </li>
      </ul>

      <h4 className="text-md font-semibold text-[#845BB3] mb-2">
        Clinical Use:
      </h4>
      <ul className="list-disc ml-6 mb-4">
        <li>Diagnose Hypothyroidism and Hyperthyroidism</li>
        <li>Monitor T4 replacement or T4 suppressive therapy</li>
        <li>Quantify TSH levels in the subnormal range</li>
      </ul>

      <h4 className="text-md font-semibold text-[#845BB3] mb-2">Note:</h4>
      <p className="mb-4">
        TSH levels are subject to circadian variation and may be influenced by
        medications like Biotin.
      </p>
    </div>
  );
};
// CRP (C-Reactive Protein) Summary Component
export const crp = () => {
  return (
    <div>
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Summary</h3>
      <p className="mb-4">
        CRP is an acute phase reactant produced by the liver and is used in
        inflammatory disorders for monitoring the course and effect of therapy.
      </p>

      <h4 className="text-md font-semibold text-[#845BB3] mb-2">
        C-Reactive Protein Levels in Serum (mg/L)
      </h4>
      <table className="min-w-full bg-white border border-gray-300 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Condition</th>
            <th className="border border-gray-300 px-4 py-2">Level (mg/L)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Normal</td>
            <td className="border border-gray-300 px-4 py-2">{"<"}5.0</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Above Normal</td>
            <td className="border border-gray-300 px-4 py-2">5.0 – 10.0</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">High</td>
            <td className="border border-gray-300 px-4 py-2">{">"}10.0</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

// D-Dimer Summary Component

// CK-MB (Creatine Kinase - MB) Summary Component
// export const ckmb = () => {
//   return (
//     <div>
//       <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">
//         CK-MB (Creatine Kinase – MB) Summary
//       </h2>
//       <table className="min-w-full bg-white border border-gray-300 mb-4">
//         <thead>
//           <tr className="bg-[#D7D4F8] text-[#845BB3]">
//             <th className="border border-gray-300 px-4 py-2">Test Name</th>
//             <th className="border border-gray-300 px-4 py-2">Results</th>
//             <th className="border border-gray-300 px-4 py-2">Unit</th>
//             <th className="border border-gray-300 px-4 py-2">
//               Bio. Ref. Intv.
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               CK-MB (Creatine Kinase – MB)
//             </td>
//             <td className="border border-gray-300 px-4 py-2">3.12</td>
//             <td className="border border-gray-300 px-4 py-2">ng/mL</td>
//             <td className="border border-gray-300 px-4 py-2">{"<"}5.0</td>
//           </tr>
//         </tbody>
//       </table>

//       <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
//         Interpretation
//       </h3>
//       <p className="mb-4">
//         Elevated CK-MB levels indicate heart damage, and are useful in
//         diagnosing heart attacks. CK-MB is elevated within hours of a heart
//         attack and peaks within 16-20 hours.
//       </p>
//     </div>
//   );
// };

// TnI (Troponin - I) Summary Component
// export const tnl = () => {
//   return (
//     <div>
//       <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">
//         TnI (Troponin – I) Summary
//       </h2>
//       <table className="min-w-full bg-white border border-gray-300 mb-4">
//         <thead>
//           <tr className="bg-[#D7D4F8] text-[#845BB3]">
//             <th className="border border-gray-300 px-4 py-2">Test Name</th>
//             <th className="border border-gray-300 px-4 py-2">Results</th>
//             <th className="border border-gray-300 px-4 py-2">Unit</th>
//             <th className="border border-gray-300 px-4 py-2">
//               Bio. Ref. Intv.
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               TnI (Troponin – I)
//             </td>
//             <td className="border border-gray-300 px-4 py-2">0.03</td>
//             <td className="border border-gray-300 px-4 py-2">ng/mL</td>
//             <td className="border border-gray-300 px-4 py-2">{"<"}0.05</td>
//           </tr>
//         </tbody>
//       </table>

//       <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
//         Interpretation
//       </h3>
//       <p className="mb-4">
//         Elevated Troponin-I levels indicate heart muscle injury. A significant
//         rise in levels suggests a heart attack. Normal levels can rule out heart
//         damage.
//       </p>
//     </div>
//   );
// };

// export const ntprobnp = () => {
//   return (
//     <div className="mb-8">
//       <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
//         NT-proBNP (N Terminal pro-B type Natriuretic Peptide)
//       </h3>
//       <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
//         <thead>
//           <tr>
//             <th className="border border-gray-300 px-4 py-2">Test Name</th>
//             <th className="border border-gray-300 px-4 py-2">Results</th>
//             <th className="border border-gray-300 px-4 py-2">Unit</th>
//             <th className="border border-gray-300 px-4 py-2">
//               Bio. Ref. Intv.
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">NT-proBNP</td>
//             <td className="border border-gray-300 px-4 py-2">80</td>
//             <td className="border border-gray-300 px-4 py-2">pg/mL</td>
//             <td className="border border-gray-300 px-4 py-2">{"<"}125</td>
//           </tr>
//         </tbody>
//       </table>

//       <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
//         Interpretation
//       </h3>
//       <p className="mb-4">
//         NT-proBNP value increases with age. Elevated levels indicate heart
//         failure, and low levels help rule it out in patients with acute dyspnea.
//       </p>
//       <h4 className="font-semibold">Age-Based Cut Offs</h4>
//       <ul className="list-disc pl-5">
//         <li>
//           <strong>&lt;50 Years:</strong> {">"}450 pg/mL
//         </li>
//         <li>
//           <strong>50-75 Years:</strong> {">"}900 pg/mL
//         </li>
//         <li>
//           <strong>&gt;75 Years:</strong> {">"}1800 pg/mL
//         </li>
//         <li>
//           <strong>Rule Out (All Patients):</strong>
//           {"<"}300 pg/mL
//         </li>
//       </ul>
//     </div>
//   );
// };
export const lh = () => {
  return (
    <div className="mb-8">
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation
      </h3>
      <p className="mb-4">
        LH is important in ovulation and the production of sex hormones.
        Elevated levels in both males and females may indicate gonadal failure.
      </p>
    </div>
  );
};
export const pct = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">
        PCT (Procalcitonin)
      </h2>
      <h3 className="text-lg font-semibold text-[#845BB3]">Summary</h3>
      <p className="mb-4">
        Procalcitonin is a substance produced by many types of cells in the
        body, often in response to bacterial infections and tissue injury. The
        level of procalcitonin in the blood can increase significantly in cases
        of systemic bacterial infections and sepsis. This test measures
        procalcitonin levels in the blood.
      </p>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Reference Ranges
      </h3>
      <table className="w-full text-sm border border-collapse border-gray-200 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8]">
            <th className="border border-gray-200 px-2 py-1 text-left">S No</th>
            <th className="border border-gray-200 px-2 py-1 text-left">
              Reference Range (ng/mL)
            </th>
            <th className="border border-gray-200 px-2 py-1 text-left">
              Comments
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-200 px-2 py-1">1</td>
            <td className="border border-gray-200 px-2 py-1">&lt; 0.05</td>
            <td className="border border-gray-200 px-2 py-1">
              Healthy Individual
            </td>
          </tr>
          <tr>
            <td className="border border-gray-200 px-2 py-1">2</td>
            <td className="border border-gray-200 px-2 py-1">&lt; 0.50</td>
            <td className="border border-gray-200 px-2 py-1">
              Low risk or local bacterial infection
            </td>
          </tr>
          <tr>
            <td className="border border-gray-200 px-2 py-1">3</td>
            <td className="border border-gray-200 px-2 py-1">0.50 - 0.20</td>
            <td className="border border-gray-200 px-2 py-1">
              Moderate risk for progression to severe systemic infection
              (Sepsis)
            </td>
          </tr>
          <tr>
            <td className="border border-gray-200 px-2 py-1">4</td>
            <td className="border border-gray-200 px-2 py-1">0.20 - 10.00</td>
            <td className="border border-gray-200 px-2 py-1">
              High risk for progression to severe systemic infection (Severe
              Sepsis)
            </td>
          </tr>
          <tr>
            <td className="border border-gray-200 px-2 py-1">5</td>
            <td className="border border-gray-200 px-2 py-1">&gt; 10.00</td>
            <td className="border border-gray-200 px-2 py-1">
              High likelihood of severe sepsis/septic shock
            </td>
          </tr>
        </tbody>
      </table>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretations
      </h3>
      <ul className="list-disc list-inside mb-4">
        <li>
          Low levels of procalcitonin in a seriously ill person may indicate a
          low risk of developing sepsis and progression to severe sepsis and/or
          septic shock but do not exclude it.
        </li>
        <li>
          High levels are indicative of a higher likelihood of sepsis and also
          progression to severe sepsis. High levels may also be seen in people
          with serious bacterial infections, such as meningitis.
        </li>
        <li>
          Moderate fluctuations may be because of a non-infectious condition or
          due to an early infection and, along with other findings, should be
          checked carefully. They may also be seen in children with kidney
          infections.
        </li>
        <li>
          Decreasing procalcitonin levels over a period of time, when initially
          detected as high can indicate a recovery from infections or in case of
          directed treatment it could mean a treatment response.
        </li>
      </ul>

      <p className="text-sm font-semibold text-gray-700">
        <strong>Note:</strong> For diagnostic purposes, the result should always
        be interpreted in conjunction with patient's medical history as several
        non-infectious causes can also elevate Procalcitonin levels.
      </p>

      <h3 className="text-lg font-semibold text-[#845BB3] mt-4">Comments</h3>
      <p className="mb-4">
        Procalcitonin (PCT) is a prohormone expressed by neuroendocrine cells (C
        cells of thyroid, pulmonary and pancreatic tissues) present in very low
        levels in healthy individuals. Measurement of PCT can be used as a
        marker of severe sepsis and generally grades well with the degree of
        sepsis. Evidence is emerging that PCT levels can reduce unnecessary
        antibiotic prescribing in lower respiratory tract infections.
      </p>

      <h3 className="text-lg font-semibold text-[#845BB3]">Increased Levels</h3>
      <p className="mb-4">
        <strong>Infectious:</strong> Bacterial infections leading to septic
        shock/severe sepsis, community-acquired respiratory tract infections &
        ventilator-induced pneumonia. <br />
        <strong>Non-Infectious:</strong> Severe cardiogenic shock, organ
        perfusion anomalies, small cell lung cancer or medullary C-cell
        carcinoma of thyroid, major trauma, surgical intervention & severe
        burns, treatment which stimulate the release of pro-inflammatory
        cytokines & neonates (&lt;48 hours after birth).
      </p>

      <h3 className="text-lg font-semibold text-[#845BB3]">Clinical Use</h3>
      <ul className="list-disc list-inside">
        <li>
          As a prognostic marker to support outcome prediction in sepsis
          patients.
        </li>
        <li>
          As an indicator of severity and major complications in acute
          pancreatitis.
        </li>
        <li>
          As a guide for the necessity of antibiotic therapy and to monitor
          success of treatments in patients suffering from community-acquired
          respiratory tract infections or ventilator-induced pneumonia.
        </li>
      </ul>
    </div>
  );
};
export const completebloodcount = () => {
  return (
    <div className="mb-8">
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        CBC (Complete Blood Count)
      </h3>
      <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2">Test Name</th>
            <th className="border border-gray-300 px-4 py-2">Results</th>
            <th className="border border-gray-300 px-4 py-2">Unit</th>
            <th className="border border-gray-300 px-4 py-2">
              Bio. Ref. Intv.
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">WBC</td>
            <td className="border border-gray-300 px-4 py-2">7.5</td>
            <td className="border border-gray-300 px-4 py-2">
              ×10<sup>3</sup>/μL
            </td>
            <td className="border border-gray-300 px-4 py-2">4.0 - 11.0</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">RBC</td>
            <td className="border border-gray-300 px-4 py-2">5.0</td>
            <td className="border border-gray-300 px-4 py-2">
              ×10<sup>6</sup>/μL
            </td>
            <td className="border border-gray-300 px-4 py-2">4.5 - 5.9</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Hemoglobin</td>
            <td className="border border-gray-300 px-4 py-2">15.1</td>
            <td className="border border-gray-300 px-4 py-2">g/dL</td>
            <td className="border border-gray-300 px-4 py-2">13.5 - 17.5</td>
          </tr>
        </tbody>
      </table>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation
      </h3>
      <p className="mb-4">
        CBC is used to evaluate overall health and detect a variety of disorders
        such as anemia, infection, and leukemia.
      </p>
      <h4 className="font-semibold">Key Indicators</h4>
      <ul className="list-disc pl-5">
        <li>
          <strong>Low WBC:</strong> May indicate a weakened immune system.
        </li>
        <li>
          <strong>High RBC:</strong> May suggest polycythemia or dehydration.
        </li>
        <li>
          <strong>Low Hemoglobin:</strong> May indicate anemia.
        </li>
      </ul>
    </div>
  );
};

export const lft = () => {
  return (
    <div className="mb-8">
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        LFT (Liver Function Test)
      </h3>
      <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2">Test Name</th>
            <th className="border border-gray-300 px-4 py-2">Results</th>
            <th className="border border-gray-300 px-4 py-2">Unit</th>
            <th className="border border-gray-300 px-4 py-2">
              Bio. Ref. Intv.
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">ALT (SGPT)</td>
            <td className="border border-gray-300 px-4 py-2">22</td>
            <td className="border border-gray-300 px-4 py-2">U/L</td>
            <td className="border border-gray-300 px-4 py-2">7 - 56</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">AST (SGOT)</td>
            <td className="border border-gray-300 px-4 py-2">25</td>
            <td className="border border-gray-300 px-4 py-2">U/L</td>
            <td className="border border-gray-300 px-4 py-2">10 - 40</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Bilirubin Total
            </td>
            <td className="border border-gray-300 px-4 py-2">0.8</td>
            <td className="border border-gray-300 px-4 py-2">mg/dL</td>
            <td className="border border-gray-300 px-4 py-2">0.1 - 1.2</td>
          </tr>
        </tbody>
      </table>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation
      </h3>
      <p className="mb-4">
        Abnormal LFT results may indicate liver damage or disease, such as
        hepatitis or cirrhosis.
      </p>
      <h4 className="font-semibold">Key Indicators</h4>
      <ul className="list-disc pl-5">
        <li>
          <strong>High ALT:</strong> May indicate liver inflammation.
        </li>
        <li>
          <strong>High AST:</strong> Often associated with liver and heart
          conditions.
        </li>
        <li>
          <strong>Elevated Bilirubin:</strong> Could indicate liver or bile duct
          issues.
        </li>
      </ul>
    </div>
  );
};
export const dengueduo = () => {
  return (
    <div className="mx-auto bg-white shadow-md p-6 rounded-lg">
      {/* Biological Reference Interval */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Detailed Biological Reference Interval:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          <strong>NS1 Antigen:</strong>
          <br />
          Negative: No evidence of active dengue infection.
          <br />
          Positive: Indicates early or active dengue infection (usually
          detectable within 1–7 days of fever onset).
        </li>
        <li>
          <strong>IgM (Immunoglobulin M):</strong>
          <br />
          Negative: No recent dengue infection.
          <br />
          Positive: Suggests a current or recent dengue infection, typically
          detectable from 4–5 days after fever onset.
        </li>
        <li>
          <strong>IgG (Immunoglobulin G):</strong>
          <br />
          Negative: No past exposure or immunity to dengue virus.
          <br />
          Positive: Indicates past dengue infection or long-term immunity.
        </li>
      </ul>

      {/* Interpretation */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Interpretation:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          <strong>Positive NS1 Antigen:</strong> Indicates early-stage or active
          dengue infection.
        </li>

        <li>
          <strong>Positive IgM Antibodies:</strong> Suggests an acute or recent
          dengue infection.
        </li>
        <li>
          <strong>Positive IgG Antibodies:</strong> Indicates past exposure or
          secondary infection with dengue virus.
        </li>
        <li>
          <strong>Negative for All Parameters:</strong> No evidence of dengue
          infection at the time of testing.
        </li>
      </ul>

      {/* Clinical Use */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Clinical Use:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>Detection of dengue virus infection in symptomatic patients.</li>
        <li>
          Differentiation between early-stage infection (NS1) and later-stage
          immune response (IgM/IgG).
        </li>
        <li>Monitoring during outbreaks for rapid case identification.</li>
      </ul>

      {/* Additional Notes */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Additional Notes:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          This is a qualitative immunochromatographic assay requiring 100 µL for
          NS1 and 10 µL for IgM/IgG.
        </li>
        <li>Sensitivity: NS1: 98.64%, IgM: 98.60%, IgG: 96%.</li>
        <li>Specificity: NS1: 99.08%, IgM: 100%, IgG: 98.16%.</li>
        <li>
          Positive results should be confirmed with ELISA or PCR for precise
          diagnosis.
        </li>
      </ul>

      {/* Disclaimer */}
      <div className="border-t-2 border-gray-300 my-4"></div>
      <p className="text-center text-gray-500 text-sm">
        This test result should be interpreted by a healthcare professional in
        conjunction with the patient’s clinical history, symptoms, and other
        diagnostic findings.
      </p>
    </div>
  );
};

export const urinealbumin = () => {
  return (
    <div className="mb-8">
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Urine Albumin
      </h3>
      {/* <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Test Name</th>
            <th className="border border-gray-300 px-4 py-2">Results</th>
            <th className="border border-gray-300 px-4 py-2">Unit</th>
            <th className="border border-gray-300 px-4 py-2">
              Bio. Ref. Intv.
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Urine Albumin</td>
            <td className="border border-gray-300 px-4 py-2">2.3</td>
            <td className="border border-gray-300 px-4 py-2">mg/L</td>
            <td className="border border-gray-300 px-4 py-2">&lt;20</td>
          </tr>
        </tbody>
      </table> */}

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Summary</h3>
      <p className="mb-4">
        Albumin is a protein that is present in large amounts in the blood. When
        kidneys are functioning properly, only a tiny amount of albumin leaks
        through into the urine, less than 30 mg/day. In kidney failure (the last
        stage of a slow process of decline in kidney function), large amounts of
        protein are spilled into the urine.
      </p>

      <h4 className="font-semibold text-[#845BB3]">Category</h4>
      <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2">Category</th>
            <th className="border border-gray-300 px-4 py-2">
              Reference Range in mg/L
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Normal/ Non – Diabetic
            </td>
            <td className="border border-gray-300 px-4 py-2">&lt;20</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Micro-albuminuria
            </td>
            <td className="border border-gray-300 px-4 py-2">20 – 200</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Macro-albuminuria
            </td>
            <td className="border border-gray-300 px-4 py-2">&gt;200</td>
          </tr>
        </tbody>
      </table>

      <h4 className="font-semibold text-[#845BB3]">Interpretations</h4>
      <p className="mb-4">
        Moderately increased levels in urine indicate that a person is in one of
        the very early phases of developing kidney disease. Recently, studies
        have shown that an abnormal result indicates an increased risk of
        developing heart and blood vessel (cardiovascular) complications,
        especially in people with diabetes. Low levels are an indication that
        kidney function is normal. Moderate to high, persistently elevated
        levels of albumin in urine over three to six months is a requirement for
        establishing a diagnosis.
      </p>

      <h4 className="font-semibold text-[#845BB3]">Note:</h4>
      <p className="mb-4">
        It is recommended that at least two of three specimens collected within
        a 3-6 month period be abnormal before considering a patient to be within
        a diagnostic category.
      </p>

      <h4 className="font-semibold text-[#845BB3]">Clinical Use</h4>
      <ul className="list-disc pl-5 mb-4">
        <li>Early detection of Diabetic nephropathy</li>
        <li>Therapeutic monitoring of patients with Nephropathy</li>
        <li>Routine management of patients with diabetes</li>
      </ul>

      <h4 className="font-semibold text-[#845BB3]">Additional Notes</h4>
      <p className="mb-4">
        Urine Albumin testing is often used in conjunction with creatinine to
        calculate the Albumin-to-Creatinine Ratio (ACR):
      </p>
      <ul className="list-disc pl-5 mb-4">
        <li>Normal ACR: &lt;30 mg/g</li>
        <li>Microalbuminuria ACR: 30–300 mg/g</li>
        <li>Macroalbuminuria ACR: &gt;300 mg/g</li>
      </ul>
      <p className="mb-4">
        Persistent microalbuminuria detected on two or more occasions should
        prompt further investigation for underlying conditions.
      </p>

      <h4 className="font-semibold text-[#845BB3]">Disclaimer</h4>
      <p className="mb-4">
        This test result should be interpreted by a healthcare professional in
        conjunction with the patient’s clinical history, symptoms, and other
        diagnostic findings.
      </p>
    </div>
  );
};

export const betahcg = () => {
  return (
    <div className="mb-8">
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation
      </h3>
      <h4 className="font-semibold mb-2">For females</h4>
      <p className="mb-4">Pregnancy – Weeks post LMP:</p>
      <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">
              Pregnancy Weeks
            </th>
            <th className="border border-gray-300 px-4 py-2">
              HCG levels in mIU/mL
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">4</td>
            <td className="border border-gray-300 px-4 py-2">5 – 100</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">5</td>
            <td className="border border-gray-300 px-4 py-2">200 – 3000</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">6</td>
            <td className="border border-gray-300 px-4 py-2">10000 – 80000</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">7 – 14</td>
            <td className="border border-gray-300 px-4 py-2">90000 – 500000</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">15 – 26</td>
            <td className="border border-gray-300 px-4 py-2">5000 – 80000</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">27 – 40</td>
            <td className="border border-gray-300 px-4 py-2">3000 – 15000</td>
          </tr>
        </tbody>
      </table>

      <table className="table-auto border-collapse border border-gray-300 w-full">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2 font-semibold">
              Condition
            </th>
            <th className="border border-gray-300 px-4 py-2 font-semibold">
              Range
            </th>
            <th className="border border-gray-300 px-4 py-2 font-semibold">
              Condition
            </th>
            <th className="border border-gray-300 px-4 py-2 font-semibold">
              Range
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Non – Pregnant</td>
            <td className="border border-gray-300 px-4 py-2">&lt; 5 mIU/mL</td>
            <td className="border border-gray-300 px-4 py-2">
              Trophoblastic Disease
            </td>
            <td className="border border-gray-300 px-4 py-2">
              &gt; 100000 mIU/mL
            </td>
          </tr>
        </tbody>
      </table>

      <h3 className="text-lg font-semibold text-[#845BB3] mt-2 mb-2">
        Comments
      </h3>
      <h3 className="text-lg font-semibold text-[#845BB3] mt-2 mb-2">
        Comments
      </h3>
      <p className="mb-4">
        Beta HCG levels rise geometrically in the serum in the first 8 weeks of
        pregnancy. Detectable amounts of beta HCG are present 8-11 days after
        conception. During the second to fifth week, HCG levels double in about
        1.5 days. After 5 weeks of gestation, the doubling time gradually
        increases to 2-3 days. Serial determination of HCG is helpful when
        abnormal pregnancy is suspected. In ectopic pregnancy and spontaneous
        abortion, HCG concentration increases slowly or decreases.
        Ultrasonography should detect a gestational sac in the uterus of all
        patients having HCG concentration {">"} 6500 mIU/mL. Failure to detect a
        gestational sac 24 days or more after conception is presumptive evidence
        of ectopic pregnancy. The presence of twins approximately doubles the
        HCG concentration.
      </p>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Note</h3>
      <ul className="list-disc pl-5 mb-4">
        <li>
          Consistently elevated HCG levels may be due to the presence of
          heterophilic antibodies, non-specific protein binding & HCG like
          substances.
        </li>
        <li>
          False negative / positive results may be seen in patients receiving
          mouse monoclonal antibodies for diagnosis or therapy.
        </li>
        <li>
          This test is not recommended to screen Germ cell tumors in the general
          population.
        </li>
      </ul>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Clinical Use
      </h3>
      <ul className="list-disc pl-5 mb-4">
        <li>
          Detect pregnancy & its abnormalities (ectopic & molar pregnancy).
        </li>
        <li>Screening of Down Syndrome & Trisomy 18.</li>
        <li>
          An aid in the management of Trophoblastic tumors. HCG is elevated in
          nearly all patients and correlates with tumor volume and disease
          prognosis. It is also useful in monitoring therapy. Persistent HCG
          levels following therapy indicate the presence of residual disease.
          During chemotherapy, weekly HCG measurement is recommended. After
          remission is achieved, yearly HCG measurement is recommended to detect
          relapse.
        </li>
        <li>
          Monitoring Germ cell tumors, Non seminomatous testicular tumors & less
          frequently Seminomas. HCG alone is useful in identifying Trophoblastic
          tumors, and along with AFP in detecting Non seminomatous testicular
          tumors.
        </li>
      </ul>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Increased Levels
      </h3>
      <ul className="list-disc pl-5">
        <li>Testicular tumors</li>
        <li>Ovarian Germ cell tumors</li>
        <li>Gestational Trophoblastic disease</li>
        <li>
          Non germ cell tumors – Melanoma & Carcinomas of breast, GI Tract, Lung
          & Ovary
        </li>
        <li>
          Benign conditions like Cirrhosis, Duodenal ulcer and Inflammatory
          bowel disease
        </li>
      </ul>
    </div>
  );
};

export const vectorborne = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      {/* Dengue NS1 Antigen Test */}
      <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">
        Dengue NS1 Antigen Test
      </h2>
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Summary</h3>
      <p className="mb-4">
        Dengue viruses belong to the family Flaviviridae and have 4 subtypes
        (1-4). Dengue virus is transmitted by the mosquito{" "}
        <em>Aedes aegypti</em> and <em>Aedes albopictus</em>, widely distributed
        in Tropical and Subtropical areas of the world. Dengue is considered to
        be the most important arthropod-borne viral disease due to the human
        morbidity and mortality it causes. The disease may be subclinical,
        self-limiting, febrile, or may progress to a severe form of Dengue
        haemorrhagic fever or Dengue shock syndrome.
      </p>
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretations
      </h3>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Positive:</strong> The presence of Dengue NS1 antigen confirms
          infection. The NS1 antigen is typically detectable within 24 hours of
          infection and is detectable up to a week or ten days. It is also
          detectable in secondary dengue infections.
        </li>
        <li>
          <strong>Negative:</strong> The absence of Dengue NS1 antigen confirms
          lack of infection.
        </li>
      </ul>
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Result in Index
      </h3>
      <table className="w-full text-sm border border-collapse border-gray-200 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8]">
            <th className="border border-gray-200 px-2 py-1 text-left">
              Index
            </th>
            <th className="border border-gray-200 px-2 py-1 text-left">
              Remarks
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-200 px-2 py-1">&gt;1.1</td>
            <td className="border border-gray-200 px-2 py-1">
              Presence of detectable Dengue NS1 antigen. Perform Dengue IgG &
              IgM serology assays on follow-up samples after 5-7 days of fever
              onset to confirm Dengue infection.
            </td>
          </tr>
          <tr>
            <td className="border border-gray-200 px-2 py-1">0.9 – 1.1</td>
            <td className="border border-gray-200 px-2 py-1">
              Repeat sample after 1 week.
            </td>
          </tr>
          <tr>
            <td className="border border-gray-200 px-2 py-1">&lt;0.9</td>
            <td className="border border-gray-200 px-2 py-1">
              No detectable Dengue NS1 antigen. Does not rule out infection.
              Additional sample should be tested for IgG & IgM serology in 7-14
              days.
            </td>
          </tr>
        </tbody>
      </table>

      {/* Dengue IgM/IgG Test */}
      <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">
        Dengue IgM/IgG Test
      </h2>
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Summary</h3>
      <p className="mb-4">
        Dengue viruses belong to the family Flaviviridae and have 4 subtypes
        (1-4). Dengue virus is transmitted by the mosquito{" "}
        <em>Aedes aegypti</em> and <em>Aedes albopictus</em>, widely distributed
        in Tropical and Subtropical areas of the world. Dengue is considered to
        be the most important arthropod-borne viral disease due to the human
        morbidity and mortality it causes. The disease may be subclinical,
        self-limiting, febrile, or may progress to a severe form of Dengue
        haemorrhagic fever or Dengue shock syndrome.
      </p>
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Index and Remarks
      </h3>
      <table className="w-full text-sm border border-collapse border-gray-200 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8]">
            <th className="border border-gray-200 px-2 py-1 text-left">
              Index
            </th>
            <th className="border border-gray-200 px-2 py-1 text-left">
              Remarks
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-200 px-2 py-1">&lt;0.9</td>
            <td className="border border-gray-200 px-2 py-1">
              No detectable antibodies found. Does not rule out infection.
              Additional testing required after 7-14 days if infection is
              suspected.
            </td>
          </tr>
          <tr>
            <td className="border border-gray-200 px-2 py-1">0.9 – 1.1</td>
            <td className="border border-gray-200 px-2 py-1">
              Retesting after 1 week.
            </td>
          </tr>
          <tr>
            <td className="border border-gray-200 px-2 py-1">&gt;1.1</td>
            <td className="border border-gray-200 px-2 py-1">
              Antibodies detected. Dengue confirmed.
            </td>
          </tr>
        </tbody>
      </table>
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Note</h3>
      <ul className="list-disc list-inside">
        <li>Recommended test is NS1 Antigen in the first 5 days of fever.</li>
        <li>
          After 7-10 days of fever, the recommended test is Dengue fever
          antibodies IgG & IgM.
        </li>
        <li>
          Cross-reactivity is seen in the Flavi virus group between Dengue
          virus, Murray Valley encephalitis, Japanese encephalitis, Yellow
          fever, and West Nile viruses.
        </li>
      </ul>

      {/* Chikungunya IgM/IgG Test */}
      <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">
        Chikungunya IgM/IgG Test
      </h2>
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Summary</h3>
      <p className="mb-4">
        Chikungunya is a viral infection transmitted by the bite of an infected{" "}
        <em>Aedes aegypti</em> mosquito. Symptoms are very similar to those of
        Dengue fever, but unlike Dengue, there is no haemorrhagic or shock
        syndrome. The disease is characterized by rash, fever, and severe joint
        pain (arthralgia). Laboratory diagnosis is critical to establish the
        cause and initiate a specific public health response.
      </p>
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        COI and Inference
      </h3>
      <table className="w-full text-sm border border-collapse border-gray-200 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8]">
            <th className="border border-gray-200 px-2 py-1 text-left">COI</th>
            <th className="border border-gray-200 px-2 py-1 text-left">
              Inference
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-200 px-2 py-1">&gt;1.0</td>
            <td className="border border-gray-200 px-2 py-1">Reactive</td>
          </tr>
          <tr>
            <td className="border border-gray-200 px-2 py-1">&lt;1.0</td>
            <td className="border border-gray-200 px-2 py-1">Non-Reactive</td>
          </tr>
        </tbody>
      </table>
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Clinical Utility
      </h3>
      <ul className="list-disc list-inside">
        <li>Helps in diagnosis of active Chikungunya infection.</li>
        <li>
          Positive IgM antibodies indicate a recent infection, while positive
          IgG antibodies may suggest past infection or long-term immunity.
        </li>
      </ul>
    </div>
  );
};
export const denguens1ag = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">
        Dengue NS1 Antigen Test
      </h2>
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Summary</h3>
      <p className="mb-4">
        Dengue viruses belong to the family Flaviviridae and have 4 subtypes
        (1-4). Dengue virus is transmitted by the mosquito{" "}
        <em>Aedes aegypti</em> and <em>Aedes albopictus</em>, widely distributed
        in Tropical and Subtropical areas of the world. Dengue is considered to
        be the most important arthropod-borne viral disease due to the human
        morbidity and mortality it causes. The disease may be subclinical,
        self-limiting, febrile, or may progress to a severe form of Dengue
        haemorrhagic fever or Dengue shock syndrome.
      </p>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretations
      </h3>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Positive:</strong> The presence of Dengue NS1 antigen confirms
          infection. The NS1 antigen is typically detectable within 24 hours of
          infection and is detectable up to a week or ten days. It is also
          detectable in secondary dengue infections.
        </li>
        <li>
          <strong>Negative:</strong> The absence of Dengue NS1 antigen confirms
          lack of infection.
        </li>
      </ul>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Result in Index
      </h3>
      <table className="w-full text-sm border border-collapse border-gray-200 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8]">
            <th className="border border-gray-200 px-2 py-1 text-left">
              Index
            </th>
            <th className="border border-gray-200 px-2 py-1 text-left">
              Remarks
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-200 px-2 py-1">&gt;1.1</td>
            <td className="border border-gray-200 px-2 py-1">
              Presence of detectable Dengue NS1 antigen. Perform Dengue IgG &
              IgM serology assays on follow-up samples after 5-7 days of fever
              onset to confirm Dengue infection.
            </td>
          </tr>
          <tr>
            <td className="border border-gray-200 px-2 py-1">0.9 – 1.1</td>
            <td className="border border-gray-200 px-2 py-1">
              Repeat sample after 1 week.
            </td>
          </tr>
          <tr>
            <td className="border border-gray-200 px-2 py-1">&lt;0.9</td>
            <td className="border border-gray-200 px-2 py-1">
              No detectable Dengue NS1 antigen. Does not rule out infection.
              Additional sample should be tested for IgG & IgM serology in 7-14
              days.
            </td>
          </tr>
        </tbody>
      </table>
      <div className="border-t-2 border-gray-300 my-4"></div>
      <p className="text-center text-gray-500 text-sm">
        This report should be interpreted by a qualified healthcare professional
        in conjunction with the patient's medical history and other diagnostic
        findings.
      </p>
    </div>
  );
};
export const dengueigmigg = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">
        Dengue IgM/ Ig
      </h2>
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Summary</h3>
      <p className="mb-4">
        Dengue viruses belong to the family Flaviviridae and have 4 subtypes
        (1-4). Dengue virus is transmitted by the mosquito{" "}
        <em>Aedes aegypti</em> and <em>Aedes albopictus</em>, widely distributed
        in Tropical and Subtropical areas of the world. Dengue is considered to
        be the most important arthropod-borne viral disease due to the human
        morbidity and mortality it causes. The disease may be subclinical,
        self-limiting, febrile, or may progress to a severe form of Dengue
        haemorrhagic fever or Dengue shock syndrome.
      </p>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Index and Remarks
      </h3>
      <table className="w-full text-sm border border-collapse border-gray-200 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8]">
            <th className="border border-gray-200 px-2 py-1 text-left">
              Index
            </th>
            <th className="border border-gray-200 px-2 py-1 text-left">
              Remarks
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-200 px-2 py-1">&lt;0.9</td>
            <td className="border border-gray-200 px-2 py-1">
              No detectable antibodies found. Does not rule out infection.
              Additional testing required after 7-14 days if infection is
              suspected.
            </td>
          </tr>
          <tr>
            <td className="border border-gray-200 px-2 py-1">0.9 – 1.1</td>
            <td className="border border-gray-200 px-2 py-1">
              Retesting after 1 week.
            </td>
          </tr>
          <tr>
            <td className="border border-gray-200 px-2 py-1">&gt;1.1</td>
            <td className="border border-gray-200 px-2 py-1">
              Antibodies detected. Dengue confirmed.
            </td>
          </tr>
        </tbody>
      </table>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Note</h3>
      <ul className="list-disc list-inside">
        <li>Recommended test is NS1 Antigen in the first 5 days of fever.</li>
        <li>
          After 7-10 days of fever, the recommended test is Dengue fever
          antibodies IgG & IgM.
        </li>
        <li>
          Cross-reactivity is seen in the Flavi virus group between Dengue
          virus, Murray Valley encephalitis, Japanese encephalitis, Yellow
          fever, and West Nile viruses.
        </li>
      </ul>
      <div className="border-t-2 border-gray-300 my-4"></div>
      <p className="text-center text-gray-500 text-sm">
        This report should be interpreted by a qualified healthcare professional
        in conjunction with the patient's medical history and other diagnostic
        findings.
      </p>
    </div>
  );
};
export const chikungunyaigmigg = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">
        Chikungunya IgM/ IgG
      </h2>
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Summary</h3>
      <p className="mb-4">
        Chikungunya is a viral infection transmitted by the bite of an infected{" "}
        <em>Aedes aegypti</em> mosquito. Symptoms are very similar to those of
        Dengue fever, but unlike Dengue, there is no haemorrhagic or shock
        syndrome. The disease is characterized by rash, fever, and severe joint
        pain (arthralgia). Laboratory diagnosis is critical to establish the
        cause and initiate a specific public health response.
      </p>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        COI and Inference
      </h3>
      <table className="w-full text-sm border border-collapse border-gray-200 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8]">
            <th className="border border-gray-200 px-2 py-1 text-left">COI</th>
            <th className="border border-gray-200 px-2 py-1 text-left">
              Inference
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-200 px-2 py-1">&gt;1.0</td>
            <td className="border border-gray-200 px-2 py-1">Reactive</td>
          </tr>
          <tr>
            <td className="border border-gray-200 px-2 py-1">&lt;1.0</td>
            <td className="border border-gray-200 px-2 py-1">Non-Reactive</td>
          </tr>
        </tbody>
      </table>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Clinical Utility
      </h3>
      <ul className="list-disc list-inside">
        <li>Helps in diagnosis of active Chikungunya infection.</li>
        <li>
          Positive IgM antibodies indicate a recent infection, while positive
          IgG antibodies may suggest past infection or long-term immunity.
        </li>
      </ul>
      <div className="border-t-2 border-gray-300 my-4"></div>
      <p className="text-center text-gray-500 text-sm">
        This report should be interpreted by a qualified healthcare professional
        in conjunction with the patient's medical history and other diagnostic
        findings.
      </p>
    </div>
  );
};
// export const chikungunya = () => {
//   return (
//     <div className="mb-8">
//       <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
//         Chikungunya Panel Report
//       </h3>
//       <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
//         <thead>
//           <tr className="bg-[#D7D4F8] text-[#845BB3]">
//             <th className="border border-gray-300 px-4 py-2">Parameter</th>
//             <th className="border border-gray-300 px-4 py-2">Results</th>
//             <th className="border border-gray-300 px-4 py-2">Unit</th>
//             <th className="border border-gray-300 px-4 py-2">Reference Range</th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">IgM</td>
//             <td className="border border-gray-300 px-4 py-2">Negative</td>
//             <td className="border border-gray-300 px-4 py-2">-</td>
//             <td className="border border-gray-300 px-4 py-2">Negative</td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">IgG</td>
//             <td className="border border-gray-300 px-4 py-2">Negative</td>
//             <td className="border border-gray-300 px-4 py-2">-</td>
//             <td className="border border-gray-300 px-4 py-2">Negative</td>
//           </tr>
//         </tbody>
//       </table>

//       <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
//         Detailed Biological Reference Interval (Bio. Ref. Intv.)
//       </h3>
//       <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
//         <thead>
//           <tr className="bg-[#D7D4F8] text-[#845BB3]">
//             <th className="border border-gray-300 px-4 py-2">Parameter</th>
//             <th className="border border-gray-300 px-4 py-2">Negative</th>
//             <th className="border border-gray-300 px-4 py-2">Positive</th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">IgM</td>
//             <td className="border border-gray-300 px-4 py-2">
//               No active or recent Chikungunya virus infection.
//             </td>
//             <td className="border border-gray-300 px-4 py-2">
//               Indicates a current or recent infection with Chikungunya virus.
//             </td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">IgG</td>
//             <td className="border border-gray-300 px-4 py-2">
//               No past exposure or immunity.
//             </td>
//             <td className="border border-gray-300 px-4 py-2">
//               Indicates past infection or possible immunity from prior exposure.
//             </td>
//           </tr>
//         </tbody>
//       </table>

//       <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
//         Interpretation
//       </h3>
//       <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
//         <thead>
//           <tr className="bg-[#D7D4F8] text-[#845BB3]">
//             <th className="border border-gray-300 px-4 py-2">Result</th>
//             <th className="border border-gray-300 px-4 py-2">Remark</th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               Positive IgM and Negative IgG
//             </td>
//             <td className="border border-gray-300 px-4 py-2">
//               Suggests an active or recent Chikungunya infection.
//             </td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               Positive IgM and Positive IgG
//             </td>
//             <td className="border border-gray-300 px-4 py-2">
//               Indicates ongoing infection with past exposure or recurrent
//               infection.
//             </td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               Negative IgM and Positive IgG
//             </td>
//             <td className="border border-gray-300 px-4 py-2">
//               Suggests past infection or long-term immunity from prior exposure.
//               No active infection.
//             </td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               Negative IgM and Negative IgG
//             </td>
//             <td className="border border-gray-300 px-4 py-2">
//               Indicates no evidence of current or past Chikungunya virus
//               infection.
//             </td>
//           </tr>
//         </tbody>
//       </table>

//       <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Comments</h3>
//       <p className="mb-4">
//         This test differentiates between recent infection (IgM) and past
//         exposure or immunity (IgG). It is useful for rapid screening and
//         diagnosis during outbreaks or in endemic regions.
//       </p>
//       <p className="mb-4">
//         Cross-reactivity with other arboviruses (e.g., dengue) may lead to
//         false-positive results. Confirmatory testing with RT-PCR or ELISA is
//         recommended for higher sensitivity and specificity.
//       </p>
//       <p className="mb-4">
//         Disclaimer: This test result should be interpreted by a healthcare
//         professional in conjunction with the patient’s clinical history,
//         symptoms, and other diagnostic findings.
//       </p>
//     </div>
//   );
// };

export const chikungunya = () => {
  return (
    <div className="mx-auto bg-white shadow-md p-6 rounded-lg">
      {/* Biological Reference Interval */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Detailed Biological Reference Interval:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          <strong>IgM (Immunoglobulin M):</strong>
          <ul className="list-disc pl-6">
            <li>Negative: No active or recent Chikungunya virus infection.</li>
            <li>
              Positive: Indicates a current or recent infection with Chikungunya
              virus.
            </li>
          </ul>
        </li>
        <li>
          <strong>IgG (Immunoglobulin G):</strong>
          <ul className="list-disc pl-6">
            <li>Negative: No past exposure or immunity.</li>
            <li>
              Positive: Indicates past infection or possible immunity from prior
              exposure.
            </li>
          </ul>
        </li>
      </ul>

      {/* Interpretation */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Interpretation:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          <strong>Positive IgM and Negative IgG:</strong> Suggests an active or
          recent Chikungunya infection.
        </li>
        <li>
          <strong>Positive IgM and Positive IgG:</strong> Indicates ongoing
          infection with past exposure or recurrent infection.
        </li>
        <li>
          <strong>Negative IgM and Positive IgG:</strong> Suggests past
          infection or long-term immunity from prior exposure. No active
          infection.
        </li>
        <li>
          <strong>Negative IgM and Negative IgG:</strong> Indicates no evidence
          of current or past Chikungunya virus infection.
        </li>
      </ul>

      {/* Clinical Use */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Clinical Use:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          Diagnosis of Chikungunya fever in patients with symptoms such as
          fever, joint pain, rash, or fatigue.
        </li>
        <li>
          Differentiation between recent (IgM) and past exposure (IgG) to the
          virus.
        </li>
        <li>
          Useful in endemic regions or during outbreaks for rapid screening.
        </li>
      </ul>

      {/* Additional Notes */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Additional Notes:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          This is a qualitative test and should be confirmed with
          laboratory-based diagnostic methods such as RT-PCR or ELISA for higher
          sensitivity and specificity.
        </li>
        <li>
          Cross-reactivity with other arboviruses (e.g., dengue) may lead to
          false-positive results.
        </li>
        <li>
          Positive IgM should prompt further investigation for disease
          management and public health measures.
        </li>
      </ul>

      {/* Disclaimer */}
      <div className="border-t-2 border-gray-300 my-4"></div>
      <p className="text-center text-gray-500 text-sm">
        This test result should be interpreted by a healthcare professional in
        conjunction with the patient’s clinical history, symptoms, and other
        diagnostic findings.
      </p>
    </div>
  );
};

export const ifob = () => {
  return (
    <div className="mb-8">
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        iFOB (Fecal Occult Blood)
      </h3>
      <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Test Name</th>
            <th className="border border-gray-300 px-4 py-2">Results</th>
            <th className="border border-gray-300 px-4 py-2">Unit</th>
            <th className="border border-gray-300 px-4 py-2">
              Bio. Ref. Intv.
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              iFOB (Fecal Occult Blood)
            </td>
            <td className="border border-gray-300 px-4 py-2">1.2</td>
            <td className="border border-gray-300 px-4 py-2">ng/mL</td>
            <td className="border border-gray-300 px-4 py-2">&lt;100</td>
          </tr>
        </tbody>
      </table>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Result Interpretation
      </h3>
      <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Result</th>
            <th className="border border-gray-300 px-4 py-2">Inference</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">&gt;100</td>
            <td className="border border-gray-300 px-4 py-2">
              Positive - Occult blood present
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">&lt;100</td>
            <td className="border border-gray-300 px-4 py-2">
              Negative - No occult blood
            </td>
          </tr>
        </tbody>
      </table>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Comments</h3>
      <p className="mb-4">
        This test is mainly used as screening for asymptomatic ulcerated lesions
        of the GI tract. In order to avoid false positives, certain dietary and
        drug restrictions are recommended. For 3 days before the test, avoid
        large doses of drugs like Aspirin, NSAIDs, Vitamin C, oral iron, red
        meat, poultry, fish, vegetables like cucumber, horseradish, cauliflower,
        and vigorous brushing of teeth with a hard toothbrush.
      </p>
    </div>
  );
};
export const covid19ag = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">
        COVID-19 Antigen Test
      </h2>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Summary</h3>
      <p className="mb-4">
        The novel coronaviruses belong to the β genus. COVID-19 is an acute
        respiratory infectious disease. People are generally susceptible.
        Currently, the patients infected by the novel coronavirus are the main
        source of infection; asymptomatic infected people can also be an
        infectious source. Based on the current epidemiological investigation,
        the incubation period is 1 to 14 days, mostly 3 to 7 days. The main
        manifestations include fever, fatigue, and dry cough. Nasal congestion,
        runny nose, sore throat, myalgia, and diarrhea are found in a few cases.
        <br />
        <br />
        This test is for the detection of SARS-CoV-2 nucleocapsid protein
        antigen. Antigen is generally detectable in upper respiratory specimens
        during the acute phase of infection. Rapid diagnosis of SARS-CoV-2
        infection will help healthcare professionals treat patients and control
        the disease more efficiently and effectively.
      </p>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Results and Interpretations
      </h3>
      <table className="w-full text-sm border border-collapse border-gray-200 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8]">
            <th className="border border-gray-200 px-2 py-1 text-left">
              Result
            </th>
            <th className="border border-gray-200 px-2 py-1 text-left">
              Interpretation
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-200 px-2 py-1">&gt; 1.0</td>
            <td className="border border-gray-200 px-2 py-1">
              Covid-19 Antigen detected in the sample.
            </td>
          </tr>
          <tr>
            <td className="border border-gray-200 px-2 py-1">&lt; 1.0</td>
            <td className="border border-gray-200 px-2 py-1">
              Covid-19 Antigen not detected/ below detectable levels.
            </td>
          </tr>
        </tbody>
      </table>

      <h3 className="text-lg font-semibold text-[#845BB3]">Note</h3>
      <ul className="list-disc list-inside">
        <li>
          Inadequate collection and handling may affect the results adversely.
        </li>
        <li>Humidity and temperature may affect the test results adversely.</li>
        <li>
          A negative result despite the presence of symptoms doesn’t rule out
          the presence of infection but suggests the presence is under
          detectable limits.
        </li>
      </ul>
      <div className="border-t-2 border-gray-300 my-4"></div>
      <p className="text-center text-gray-500 text-sm">
        This report should be interpreted by a qualified healthcare professional
        in conjunction with the patient's medical history and other diagnostic
        findings.
      </p>
    </div>
  );
};
export const rsvag = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">
        RSV Ag (Respiratory Syncytial Virus)
      </h2>
      <h3 className="text-lg font-semibold text-[#845BB3]">Summary</h3>
      <p className="mb-4">
        Respiratory syncytial virus (RSV) causes infections of the lungs and
        respiratory tract. It’s so common that most children have been infected
        with the virus by age 2. Respiratory syncytial virus can also infect
        adults. RSV can cause severe infection in some people, including babies
        12 months and younger (infants), especially premature infants, older
        adults, people with heart and lung disease, or anyone with a weak immune
        system (immunocompromised).
      </p>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Results and Interpretations
      </h3>
      <table className="w-full text-sm border border-collapse border-gray-200 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8]">
            <th className="border border-gray-200 px-2 py-1 text-left">
              Result
            </th>
            <th className="border border-gray-200 px-2 py-1 text-left">
              Interpretation
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-200 px-2 py-1">&gt; 1.0</td>
            <td className="border border-gray-200 px-2 py-1">
              Respiratory Syncytial Virus Antigen detected in the sample.
            </td>
          </tr>
          <tr>
            <td className="border border-gray-200 px-2 py-1">&lt; 1.0</td>
            <td className="border border-gray-200 px-2 py-1">
              Respiratory Syncytial Virus Antigen not detected/ below detectable
              levels.
            </td>
          </tr>
        </tbody>
      </table>

      <h3 className="text-lg font-semibold text-[#845BB3]">Note</h3>
      <ul className="list-disc list-inside">
        <li>
          Inadequate collection and handling may affect the results adversely.
        </li>
        <li>Humidity and temperature may affect the test results adversely.</li>
        <li>
          A negative result despite the presence of symptoms doesn’t rule out
          the presence of infection but suggests the presence is under
          detectable limits.
        </li>
      </ul>
      <div className="border-t-2 border-gray-300 my-4"></div>
      <p className="text-center text-gray-500 text-sm">
        This report should be interpreted by a qualified healthcare professional
        in conjunction with the patient's medical history and other diagnostic
        findings.
      </p>
    </div>
  );
};
export const legionellaag = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">
        Legionella Antigen Test
      </h2>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Summary</h3>
      <p className="mb-4">
        Legionnaires' disease is a severe form of pneumonia — lung inflammation
        usually caused by infection. It's caused by a bacterium known as
        legionella. The legionella bacterium also causes Pontiac fever, a milder
        illness resembling the flu. Pontiac fever usually clears on its own, but
        untreated Legionnaires' disease can be fatal. Although prompt treatment
        with antibiotics usually cures Legionnaires' disease, some people
        continue to have problems after treatment.
      </p>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Results and Interpretations
      </h3>
      <table className="w-full text-sm border border-collapse border-gray-200 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8]">
            <th className="border border-gray-200 px-2 py-1 text-left">
              Result
            </th>
            <th className="border border-gray-200 px-2 py-1 text-left">
              Interpretation
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-200 px-2 py-1">&gt; 1.0</td>
            <td className="border border-gray-200 px-2 py-1">
              Legionella Antigen detected in the sample.
            </td>
          </tr>
          <tr>
            <td className="border border-gray-200 px-2 py-1">&lt; 1.0</td>
            <td className="border border-gray-200 px-2 py-1">
              Legionella Antigen not detected/ below detectable levels.
            </td>
          </tr>
        </tbody>
      </table>

      <h3 className="text-lg font-semibold text-[#845BB3]">Note</h3>
      <ul className="list-disc list-inside">
        <li>
          Inadequate collection and handling may affect the results adversely.
        </li>
        <li>Humidity and temperature may affect the test results adversely.</li>
        <li>
          A negative result despite the presence of symptoms doesn’t rule out
          the presence of infection but suggests the presence is under
          detectable limits.
        </li>
      </ul>
      <div className="border-t-2 border-gray-300 my-4"></div>
      <p className="text-center text-gray-500 text-sm">
        This report should be interpreted by a qualified healthcare professional
        in conjunction with the patient's medical history and other diagnostic
        findings.
      </p>
    </div>
  );
};
export const spneumoniaag = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">
        Pneumococcal Antigen Test
      </h2>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Summary</h3>
      <p className="mb-4">
        Pneumococcal infections are caused by <i>Streptococcus pneumonia</i>, a
        gram-positive, catalase-negative organism commonly referred to as
        pneumococcus. <i>S. pneumonia</i> is the most common cause of
        community-acquired pneumonia (CAP), bacterial meningitis, bacteraemia,
        and otitis media, as well as an important cause of sinusitis, septic
        arthritis, osteomyelitis, peritonitis, and endocarditis. Complications
        of each of these diagnoses are common.
      </p>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Results and Interpretations
      </h3>
      <table className="w-full text-sm border border-collapse border-gray-200 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8]">
            <th className="border border-gray-200 px-2 py-1 text-left">
              Result
            </th>
            <th className="border border-gray-200 px-2 py-1 text-left">
              Interpretation
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-200 px-2 py-1">&gt; 1.0</td>
            <td className="border border-gray-200 px-2 py-1">
              Streptococcus Pneumonia Antigen detected in the sample.
            </td>
          </tr>
          <tr>
            <td className="border border-gray-200 px-2 py-1">&lt; 1.0</td>
            <td className="border border-gray-200 px-2 py-1">
              Streptococcus Pneumonia Antigen not detected/ below detectable
              levels.
            </td>
          </tr>
        </tbody>
      </table>

      <h3 className="text-lg font-semibold text-[#845BB3]">Note</h3>
      <ul className="list-disc list-inside">
        <li>
          Inadequate collection and handling may affect the results adversely.
        </li>
        <li>Humidity and temperature may affect the test results adversely.</li>
        <li>
          A negative result despite the presence of symptoms doesn’t rule out
          the presence of infection but suggests the presence is under
          detectable limits.
        </li>
      </ul>
    </div>
  );
};

export const hiv = () => {
  return (
    <div className="mx-auto bg-white shadow-md p-6 rounded-lg">
      {/* Biological Reference Interval */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Detailed Biological Reference Interval:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          <strong>HIV 1:</strong>
          <ul className="list-disc pl-6">
            <li>Non-Reactive: No evidence of HIV 1 infection.</li>
            <li>
              Reactive: Indicates possible infection with HIV 1. Requires
              confirmatory testing.
            </li>
          </ul>
        </li>
        <li>
          <strong>HIV 2:</strong>
          <ul className="list-disc pl-6">
            <li>Non-Reactive: No evidence of HIV 2 infection.</li>
            <li>
              Reactive: Indicates possible infection with HIV 2. Requires
              confirmatory testing.
            </li>
          </ul>
        </li>
      </ul>

      {/* Interpretation */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Interpretation:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          <strong>Non-Reactive for Both HIV 1 & HIV 2:</strong> No evidence of
          HIV infection at the time of testing.
        </li>
        <li>
          <strong>Reactive for HIV 1 or HIV 2:</strong> Indicates possible
          infection. Requires confirmation with Western Blot, ELISA, or PCR.
        </li>
        <li>
          <strong>Indeterminate Results:</strong> In rare cases, results may be
          inconclusive. Repeat testing or advanced diagnostics are recommended.
        </li>
      </ul>

      {/* Clinical Use */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Clinical Use:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          Screening for HIV 1 & 2 infections in symptomatic or high-risk
          individuals.
        </li>

        <li>Confirmatory testing is mandatory for reactive results.</li>
        <li>
          Early detection aids in timely treatment and prevention of
          transmission.
        </li>
      </ul>

      {/* Additional Notes */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Additional Notes:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          This test is qualitative and provides rapid screening results for HIV
          antibodies.
        </li>
        <li>
          A non-reactive result does not exclude recent infection. For high-risk
          exposure, repeat testing after the window period (2–12 weeks) is
          advised.
        </li>
        <li>
          Counseling and follow-up testing are essential for reactive results.
        </li>
      </ul>

      {/* Disclaimer */}
      <div className="border-t-2 border-gray-300 my-4"></div>
      <p className="text-center text-gray-500 text-sm">
        This test result should be interpreted by a healthcare professional in
        conjunction with the patient’s clinical history, symptoms, and other
        diagnostic findings. Reactive results must be confirmed with additional
        laboratory tests.
      </p>
    </div>
  );
};

export const hepatitisbag = () => {
  return (
    <div className="mx-auto bg-white shadow-md p-6 rounded-lg">
      {/* Biological Reference Interval */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Detailed Biological Reference Interval:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          <strong>Negative:</strong> No evidence of active Hepatitis B
          infection.
        </li>
        <li>
          <strong>Positive:</strong> Indicates the presence of Hepatitis B
          infection. Requires confirmation through advanced diagnostic methods
          such as ELISA or PCR.
        </li>
      </ul>

      {/* Interpretation */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Interpretation:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          <strong>Negative Result:</strong> Indicates no active Hepatitis B
          infection.
        </li>
        <li>
          <strong>Positive Result:</strong> Suggests the presence of Hepatitis B
          virus. Further testing and clinical correlation are required to
          confirm diagnosis and determine disease stage.
        </li>
      </ul>

      {/* Clinical Use */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Clinical Use:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          Screening for Hepatitis B Virus (HBV) infection in symptomatic or
          high-risk individuals.
        </li>
        <li>
          Early detection aids in treatment initiation and prevention of disease
          transmission.
        </li>
      </ul>

      {/* Additional Notes */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Additional Notes:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          This is a qualitative rapid test designed to detect HBsAg in serum or
          plasma.
        </li>
        <li>The test requires only 50 µL of the sample.</li>
        <li>
          Sensitivity is 100%, and specificity is 99.74%, ensuring high
          accuracy.
        </li>
        <li>
          Positive results must be confirmed with quantitative laboratory tests
          like ELISA or PCR.
        </li>
      </ul>

      {/* Disclaimer */}
      {/* <div className="border-t-2 border-gray-300 my-4"></div> */}
      {/* <p className="text-center text-gray-500 text-sm">
        This test result should be interpreted by a healthcare professional in conjunction with the patient’s clinical history, symptoms, and other diagnostic findings.
      </p> */}
    </div>
  );
};

// export const hepatitisc = () => {
//   return (
//     <div className="mb-8">
//       <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
//         Hepatitis C Virus, Ultra DOT
//       </h3>
//       <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
//         <thead>
//           <tr>
//             <th className="border border-gray-300 px-4 py-2">Test Name</th>
//             <th className="border border-gray-300 px-4 py-2">Results</th>
//             <th className="border border-gray-300 px-4 py-2">Unit</th>
//             <th className="border border-gray-300 px-4 py-2">
//               Bio. Ref. Intv.
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               Hepatitis C Virus, Ultra DOT
//             </td>
//             <td className="border border-gray-300 px-4 py-2">Reactive</td>
//             <td className="border border-gray-300 px-4 py-2">NA</td>
//             <td className="border border-gray-300 px-4 py-2">Negative</td>
//           </tr>
//         </tbody>
//       </table>

//       <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
//         Interpretation
//       </h3>
//       <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
//         <thead>
//           <tr>
//             <th className="border border-gray-300 px-4 py-2">Result</th>
//             <th className="border border-gray-300 px-4 py-2">Remark</th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">Reactive</td>
//             <td className="border border-gray-300 px-4 py-2">
//               Indicates presence of antibodies against to HCV
//             </td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">Non - Reactive</td>
//             <td className="border border-gray-300 px-4 py-2">
//               Indicates absence of antibodies to HCV
//             </td>
//           </tr>
//         </tbody>
//       </table>

//       <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Comments</h3>
//       <p className="mb-4">
//         1. Used to diagnose suspected HCV infection in risk group.
//       </p>
//       <p className="mb-4">
//         2. Prenatal screening of pregnant women and pre-surgical/interventional
//         procedures work up.
//       </p>
//     </div>
//   );
// };

//Cadiac

export const hepatitisc = () => {
  return (
    <div className="mx-auto bg-white shadow-md p-6 rounded-lg">
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Detailed Biological Reference Interval:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          <strong>Negative:</strong> No evidence of active Hepatitis C Virus
          (HCV) infection.
        </li>
        <li>
          <strong>Positive:</strong> Indicates active HCV infection. Requires
          confirmation through advanced diagnostic methods like PCR.
        </li>
      </ul>

      {/* Interpretation */}

      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Interpretation:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          <strong>Negative Result:</strong> No active HCV infection at the time
          of testing.
        </li>
        <li>
          <strong>Positive Result:</strong> Suggests an active Hepatitis C
          infection. Further testing, such as HCV RNA PCR, is necessary to
          confirm the infection and determine viral load.
        </li>
      </ul>

      {/* Clinical Use */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Clinical Use:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          Screening for active HCV infection in symptomatic or high-risk
          individuals.
        </li>
        <li>
          Early detection aids in initiating antiviral therapy to prevent
          progression to chronic liver disease.
        </li>
        <li>
          Useful in monitoring treatment response in HCV-infected patients.
        </li>
      </ul>

      {/* Additional Notes */}

      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Additional Notes:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          This test detects HCV antigen in serum/plasma and provides early
          evidence of active infection.
        </li>
        <li>
          The antigen test is typically less sensitive than HCV RNA testing and
          is often used as a supplementary diagnostic tool.
        </li>
        <li>
          Positive results must be confirmed with HCV RNA testing for accurate
          diagnosis and staging.
        </li>
      </ul>

      {/* Disclaimer */}
      <div className="border-t-2 border-gray-300 my-4"></div>
      <p className="text-center text-gray-500 text-sm">
        This test result should be interpreted by a healthcare professional in
        conjunction with the patient’s clinical history, symptoms, and other
        diagnostic findings.
      </p>
    </div>
  );
};

export const syphillis = () => {
  return (
    <div className="mx-auto bg-white shadow-md p-6 rounded-lg">
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Detailed Biological Reference Interval (Bio. Ref. Intv.)
      </h3>
      <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Parameter</th>
            <th className="border border-gray-300 px-4 py-2">Non-Reactive</th>
            <th className="border border-gray-300 px-4 py-2">Reactive</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              HIV-1 Antibodies
            </td>
            <td className="border border-gray-300 px-4 py-2">
              No evidence of HIV-1 infection.
            </td>
            <td className="border border-gray-300 px-4 py-2">
              Indicates possible HIV-1 infection. Requires confirmatory testing.
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              HIV-2 Antibodies
            </td>
            <td className="border border-gray-300 px-4 py-2">
              No evidence of HIV-2 infection.
            </td>
            <td className="border border-gray-300 px-4 py-2">
              Indicates possible HIV-2 infection. Requires confirmatory testing.
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Syphilis Antibodies (TPP)
            </td>
            <td className="border border-gray-300 px-4 py-2">
              No evidence of current or past syphilis infection.
            </td>
            <td className="border border-gray-300 px-4 py-2">
              Indicates exposure to Treponema pallidum. Confirmation required.
            </td>
          </tr>
        </tbody>
      </table>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation
      </h3>
      <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Result</th>
            <th className="border border-gray-300 px-4 py-2">Remark</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Non-Reactive for All Parameters
            </td>
            <td className="border border-gray-300 px-4 py-2">
              No evidence of HIV-1, HIV-2, or syphilis infection.
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Reactive for HIV-1 or HIV-2
            </td>
            <td className="border border-gray-300 px-4 py-2">
              Indicates possible HIV infection. Confirm with laboratory-based
              tests.
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Reactive for Syphilis Antibodies
            </td>
            <td className="border border-gray-300 px-4 py-2">
              Suggests current or past syphilis infection. Requires
              confirmation.
            </td>
          </tr>
        </tbody>
      </table>

      {/* Clinical Use */}

      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Clinical Use:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          Screening for HIV-1, HIV-2, and syphilis infections in high-risk
          individuals or during routine health checkups.
        </li>
        <li>
          Combines detection of HIV antibodies and syphilis-specific antibodies
          for comprehensive testing.
        </li>
        <li>Rapid and efficient tool for point-of-care diagnosis.</li>
      </ul>

      {/* Additional Notes */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Additional Notes:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          This is an immunochromatographic assay designed to detect antibodies
          specific to HIV-1, HIV-2, and syphilis.
        </li>
        <li>Requires 10 µL of serum/plasma or 20 µL of whole blood.</li>
        <li>
          Performance: Sensitivity and specificity for HIV-1, HIV-2, and
          syphilis are 100%.
        </li>
        <li>
          Positive results must be confirmed with more specific diagnostic tests
          for each infection.
        </li>
      </ul>

      {/* Disclaimer */}
      <div className="border-t-2 border-gray-300 my-4"></div>
      <p className="text-center text-gray-500 text-sm">
        This test result should be interpreted by a healthcare professional in
        conjunction with the patient’s clinical history, symptoms, and other
        diagnostic findings. Reactive results should be confirmed with advanced
        diagnostic tests.
      </p>
    </div>
  );
};

export const leishmania = () => {
  return (
    <div className="mx-auto bg-white shadow-md p-6 rounded-lg">
      {/* Biological Reference Interval */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Detailed Biological Reference Interval:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          <strong>Leishmania Antigen:</strong>
          <br />
          Negative: No evidence of active Leishmania infection.
          <br />
          Positive: Indicates the presence of Leishmania parasite antigen,
          suggesting active infection.
        </li>
      </ul>

      {/* Interpretation */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Interpretation:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          <strong>Negative Result:</strong> No active Leishmaniasis infection
          detected.
        </li>
        <li>
          <strong>Positive Result:</strong> Suggests active Leishmania infection
          (e.g., visceral leishmaniasis or kala-azar). Requires confirmatory
          testing (e.g., microscopic examination, PCR, or serological tests) for
          accurate diagnosis.
        </li>
      </ul>

      {/* Clinical Use */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Clinical Use:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          Screening and diagnosis of Leishmaniasis, particularly visceral
          leishmaniasis (kala-azar).
        </li>
        <li>
          Rapid identification of cases in endemic areas to initiate early
          treatment.
        </li>
      </ul>

      {/* Additional Notes */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Additional Notes:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          This is a qualitative rapid test designed to detect antigens specific
          to the Leishmania parasite in whole blood, serum, or plasma.
        </li>
        <li>
          Positive results indicate active infection and should prompt further
          diagnostic confirmation.
        </li>
        <li>
          Early diagnosis and treatment are critical to preventing complications
          such as organ damage.
        </li>
      </ul>

      {/* Disclaimer */}
      <div className="border-t-2 border-gray-300 my-4"></div>
      <p className="text-center text-gray-500 text-sm">
        This test result should be interpreted by a healthcare professional in
        conjunction with the patient’s clinical history, symptoms, and other
        diagnostic findings.
      </p>
    </div>
  );
};

export const malariapfpan = () => {
  return (
    <div className="mx-auto bg-white shadow-md p-6 rounded-lg">
      {/* Biological Reference Interval */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Detailed Biological Reference Interval:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          <strong>Plasmodium falciparum (HRP-II):</strong>
          <br />
          Negative: No evidence of active Plasmodium falciparum infection.
          <br />
          Positive: Indicates active infection with Plasmodium falciparum.
        </li>
        <li>
          <strong>Plasmodium species (pLDH):</strong>
          <br />
          Negative: No evidence of active infection by other Plasmodium species
          (e.g., P. vivax, P. malariae, P. ovale).
          <br />
          Positive: Indicates active infection with non-falciparum Plasmodium
          species.
        </li>
      </ul>

      {/* Interpretation */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Interpretation:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          <strong>Negative for Both HRP-II and pLDH:</strong> No evidence of
          active malaria infection.
        </li>
        <li>
          <strong>Positive for HRP-II:</strong> Suggests active infection with
          Plasmodium falciparum.
        </li>
        <li>
          <strong>Positive for pLDH:</strong> Indicates active infection with
          non-falciparum Plasmodium species (e.g., P. vivax).
        </li>
        <li>
          <strong>Positive for Both HRP-II and pLDH:</strong> Suggests mixed
          infection with Plasmodium falciparum and other Plasmodium species.
        </li>
      </ul>

      {/* Clinical Use */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Clinical Use:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          Rapid detection of malaria caused by Plasmodium falciparum or
          non-falciparum species.
        </li>
        <li>
          Useful in endemic regions for quick diagnosis and timely treatment.
        </li>
        <li>
          Assists in ruling out malaria in febrile patients with unknown
          etiology.
        </li>
      </ul>

      {/* Additional Notes */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Additional Notes:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          This is a qualitative immunochromatographic assay requiring only 3 µL
          of whole blood.
        </li>
        <li>
          Test detects HRP-II from Plasmodium falciparum and pLDH (lactate
          dehydrogenase) from other Plasmodium species.
        </li>
        <li>Performance: Sensitivity: 100%; Specificity: 100%.</li>
        <li>
          Results should be confirmed with microscopy or PCR, especially in
          cases of mixed infections or suspected treatment failures.
        </li>
      </ul>

      {/* Disclaimer */}
      <div className="border-t-2 border-gray-300 my-4"></div>
      <p className="text-center text-gray-500 text-sm">
        This test result should be interpreted by a healthcare professional in
        conjunction with the patient’s clinical history, symptoms, and other
        diagnostic findings.
      </p>
    </div>
  );
};

// export const cholera = () => {
//   return (
//     <div className="mb-8">
//       <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
//         Vibrio cholerae Antigen Test
//       </h3>
//       <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
//         <thead>
//           <tr className="bg-[#D7D4F8] text-[#845BB3]">
//             <th className="border border-gray-300 px-4 py-2">Parameter</th>
//             <th className="border border-gray-300 px-4 py-2">Results</th>
//             <th className="border border-gray-300 px-4 py-2">Unit</th>
//             <th className="border border-gray-300 px-4 py-2">
//               Reference Range
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr className="">
//             <td className="border border-gray-300 px-4 py-2">
//               Vibrio cholerae O1 Antigen
//             </td>
//             <td className="border border-gray-300 px-4 py-2">Negative</td>
//             <td className="border border-gray-300 px-4 py-2">-</td>
//             <td className="border border-gray-300 px-4 py-2">Negative</td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               Vibrio cholerae O139 Antigen
//             </td>
//             <td className="border border-gray-300 px-4 py-2">Negative</td>
//             <td className="border border-gray-300 px-4 py-2">-</td>
//             <td className="border border-gray-300 px-4 py-2">Negative</td>
//           </tr>
//         </tbody>
//       </table>

//       <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
//         Detailed Biological Reference Interval (Bio. Ref. Intv.)
//       </h3>
//       <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
//         <thead>
//           <tr className="bg-[#D7D4F8] text-[#845BB3]">
//             <th className="border border-gray-300 px-4 py-2">Parameter</th>
//             <th className="border border-gray-300 px-4 py-2">Negative</th>
//             <th className="border border-gray-300 px-4 py-2">Positive</th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               Vibrio cholerae O1 Antigen
//             </td>
//             <td className="border border-gray-300 px-4 py-2">
//               No current infection with Vibrio cholerae O1 strain.
//             </td>
//             <td className="border border-gray-300 px-4 py-2">
//               Indicates active infection with Vibrio cholerae O1, the most
//               common strain causing cholera.
//             </td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               Vibrio cholerae O139 Antigen
//             </td>
//             <td className="border border-gray-300 px-4 py-2">
//               No current infection with Vibrio cholerae O139 strain.
//             </td>
//             <td className="border border-gray-300 px-4 py-2">
//               Indicates active infection with Vibrio cholerae O139, another
//               significant strain of cholera.
//             </td>
//           </tr>
//         </tbody>
//       </table>

//       <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
//         Interpretation
//       </h3>
//       <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
//         <thead>
//           <tr className="bg-[#D7D4F8] text-[#845BB3]">
//             <th className="border border-gray-300 px-4 py-2">Result</th>
//             <th className="border border-gray-300 px-4 py-2">Remark</th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               Positive for O1 or O139 Antigen
//             </td>
//             <td className="border border-gray-300 px-4 py-2">
//               Indicates an active infection with Vibrio cholerae, requiring
//               immediate medical attention.
//             </td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               Negative for Both Antigens
//             </td>
//             <td className="border border-gray-300 px-4 py-2">
//               Suggests no active cholera infection. Clinical correlation and
//               repeat testing may be needed in symptomatic patients.
//             </td>
//           </tr>
//         </tbody>
//       </table>

//       <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Comments</h3>
//       <p className="mb-4">
//         This is a qualitative test and detects the presence of cholera antigens
//         in stool. Negative results do not exclude cholera, especially in early
//         or mild infections. Further testing (e.g., stool culture) is recommended
//         for confirmation. Positive results should prompt immediate hydration
//         therapy and public health measures to prevent outbreaks.
//       </p>
//       <p className="mb-4">
//         Disclaimer: This test result should be interpreted by a healthcare
//         professional in conjunction with the patient’s clinical history,
//         symptoms, and other diagnostic findings.
//       </p>
//     </div>
//   )
// }

export const cholera = () => {
  return (
    <div className="mx-auto bg-white shadow-md p-6 rounded-lg">
      {/* Biological Reference Interval */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Detailed Biological Reference Interval:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          <strong>Vibrio cholerae O1 Antigen:</strong>
          <ul className="list-disc pl-6">
            <li>
              Negative: No current infection with Vibrio cholerae O1 strain.
            </li>
            <li>
              Positive: Indicates active infection with Vibrio cholerae O1, the
              most common strain causing cholera.
            </li>
          </ul>
        </li>
        <li>
          <strong>Vibrio cholerae O139 Antigen:</strong>
          <ul className="list-disc pl-6">
            <li>
              Negative: No current infection with Vibrio cholerae O139 strain.
            </li>
            <li>
              Positive: Indicates active infection with Vibrio cholerae O139,
              another significant strain of cholera.
            </li>
          </ul>
        </li>
      </ul>

      {/* Interpretation */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Interpretation:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          <strong>Positive for O1 or O139 Antigen:</strong> Indicates an active
          infection with Vibrio cholerae, requiring immediate medical attention.
        </li>
        <li>
          <strong>Negative for Both Antigens:</strong> Suggests no active
          cholera infection. However, clinical correlation and repeat testing
          may be needed in symptomatic patients.
        </li>
      </ul>

      {/* Clinical Use */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Clinical Use:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          Rapid diagnosis of cholera in patients with acute diarrhea and
          suspected exposure to contaminated water or food.
        </li>
        <li>Screening during cholera outbreaks or in endemic regions.</li>
        <li>Helps in initiating timely treatment and containment measures.</li>
      </ul>

      {/* Additional Notes */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Additional Notes:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          This is a qualitative test and detects the presence of cholera
          antigens in stool.
        </li>
        <li>
          <strong>Negative results:</strong> Do not exclude cholera, especially
          in early or mild infections. Further testing (e.g., stool culture) is
          recommended for confirmation.
        </li>
        <li>
          <strong>Positive results:</strong> Should prompt immediate hydration
          therapy and public health measures to prevent outbreaks.
        </li>
      </ul>

      {/* Disclaimer */}
      <div className="border-t-2 border-gray-300 my-4"></div>
      <p className="text-center text-gray-500 text-sm">
        This test result should be interpreted by a qualified healthcare
        professional in conjunction with the patient’s medical history and other
        diagnostic findings.
      </p>
    </div>
  );
};
export const leptospira = () => {
  return (
    <div className="mb-8">
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Detailed Biological Reference Interval (Bio. Ref. Intv.)
      </h3>
      <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Parameter</th>
            <th className="border border-gray-300 px-4 py-2">Negative</th>
            <th className="border border-gray-300 px-4 py-2">Positive</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">IgM</td>
            <td className="border border-gray-300 px-4 py-2">
              No active or recent Leptospira infection.
            </td>
            <td className="border border-gray-300 px-4 py-2">
              Indicates a current or recent infection with Leptospira spp.
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">IgG</td>
            <td className="border border-gray-300 px-4 py-2">
              No past exposure or immunity.
            </td>
            <td className="border border-gray-300 px-4 py-2">
              Indicates past infection or immunity from prior exposure.
            </td>
          </tr>
        </tbody>
      </table>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation
      </h3>
      <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Result</th>
            <th className="border border-gray-300 px-4 py-2">Remark</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Positive IgM and Negative IgG
            </td>
            <td className="border border-gray-300 px-4 py-2">
              Suggests an active or recent Leptospira infection.
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Positive IgM and Positive IgG
            </td>
            <td className="border border-gray-300 px-4 py-2">
              Indicates an ongoing infection with evidence of past exposure or
              reinfection.
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Negative IgM and Positive IgG
            </td>
            <td className="border border-gray-300 px-4 py-2">
              Suggests past infection or long-term immunity from prior exposure.
              No active infection.
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Negative IgM and Negative IgG
            </td>
            <td className="border border-gray-300 px-4 py-2">
              Indicates no evidence of current or past Leptospira infection.
            </td>
          </tr>
        </tbody>
      </table>

      {/* Clinical Use */}
      <h2 className="text-xl font-semibold text-purple-700 mb-3">
        Clinical Use:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          Diagnosis of leptospirosis in patients presenting with symptoms such
          as fever, muscle pain, jaundice, or kidney involvement.
        </li>
        <li>
          Differentiation between recent infection (IgM) and past exposure
          (IgG).
        </li>
        <li>Rapid screening in endemic regions or during outbreaks.</li>
      </ul>

      {/* Additional Notes */}
      <h2 className="text-xl font-semibold text-purple-700 mb-3">
        Additional Notes:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          This is a qualitative test, and results should be confirmed with more
          sensitive and specific methods, such as MAT (Microscopic Agglutination
          Test), PCR, or ELISA.
        </li>
        <li>
          Cross-reactivity with other bacterial infections may occur; clinical
          correlation is essential.
        </li>
        <li>
          Positive IgM or high clinical suspicion should prompt timely
          antibiotic treatment.
        </li>
      </ul>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Disclaimer</h3>
      <p className="mb-4">
        Disclaimer: This test result should be interpreted by a healthcare
        professional in conjunction with the patient’s clinical history,
        symptoms, and other diagnostic findings.
      </p>
    </div>
  );
};

export const hpylori = () => {
  return (
    <div className="mx-auto bg-white shadow-md p-6 rounded-lg">
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Detailed Biological Reference Interval (Bio. Ref. Intv.)
      </h3>
      <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Parameter</th>
            <th className="border border-gray-300 px-4 py-2">Negative</th>
            <th className="border border-gray-300 px-4 py-2">Positive</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              H. Pylori Antigen
            </td>
            <td className="border border-gray-300 px-4 py-2">
              No evidence of current Helicobacter pylori infection.
            </td>
            <td className="border border-gray-300 px-4 py-2">
              Indicates active infection with Helicobacter pylori, a common
              cause of peptic ulcers and gastritis.
            </td>
          </tr>
        </tbody>
      </table>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation
      </h3>
      <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Result</th>
            <th className="border border-gray-300 px-4 py-2">Remark</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Negative</td>
            <td className="border border-gray-300 px-4 py-2">
              No evidence of active H. pylori infection.
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Positive</td>
            <td className="border border-gray-300 px-4 py-2">
              Indicates the presence of H. pylori antigen, suggesting an ongoing
              infection. Requires clinical correlation and consideration for
              eradication therapy.
            </td>
          </tr>
        </tbody>
      </table>

      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Clinical Use:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          Detection of active H. pylori infection in patients with symptoms such
          as abdominal pain, gastritis, or peptic ulcers.
        </li>
        <li>Monitoring response to eradication therapy post-treatment.</li>
        <li>
          Rapid, non-invasive alternative to endoscopic biopsy for H. pylori
          testing.
        </li>
      </ul>

      {/* Additional Notes */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Additional Notes:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          This test detects H. pylori antigen in stool using a
          fluorescence-based method for higher sensitivity and specificity.
        </li>
        <li>
          False negatives may occur if the patient is on antibiotics, proton
          pump inhibitors (PPIs), or bismuth compounds. Discontinue such
          medications 2 weeks prior to testing, if possible.
        </li>
        <li>
          Positive results should prompt appropriate treatment to prevent
          complications like ulcers or gastric cancer.
        </li>
      </ul>

      {/* Disclaimer */}
      <div className="border-t-2 border-gray-300 my-4"></div>
      <p className="text-center text-gray-500 text-sm">
        This test result should be interpreted by a healthcare professional in
        conjunction with the patient’s clinical history, symptoms, and other
        diagnostic findings.
      </p>
    </div>
  );
};

export const scrubtyphus = () => {
  return (
    <div className="mx-auto bg-white shadow-md p-6 rounded-lg">
      {/* Biological Reference Interval */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Detailed Biological Reference Interval:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          <strong>IgM Negative:</strong> No evidence of recent or active Scrub
          Typhus infection.
        </li>
        <li>
          <strong>IgM Positive:</strong> Indicates a current or recent infection
          with Orientia tsutsugamushi.
        </li>
        <li>
          <strong>IgG Negative:</strong> No past exposure or immunity.
        </li>
        <li>
          <strong>IgG Positive:</strong> Suggests past infection or possible
          long-term immunity.
        </li>
      </ul>

      {/* Interpretation */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Interpretation:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          <strong>Positive IgM and Negative IgG:</strong> Indicates an active or
          recent Scrub Typhus infection.
        </li>
        <li>
          <strong>Positive IgM and Positive IgG:</strong> Suggests ongoing
          infection or recent re-exposure to the pathogen.
        </li>
        <li>
          <strong>Negative IgM and Positive IgG:</strong> Suggests past
          infection and possible immunity. No active infection.
        </li>
        <li>
          <strong>Negative IgM and Negative IgG:</strong> No evidence of current
          or past infection with Scrub Typhus.
        </li>
      </ul>

      {/* Clinical Use */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Clinical Use:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          Diagnosis of Scrub Typhus infection in patients presenting with
          symptoms such as fever, rash, eschar, and lymphadenopathy.
        </li>
        <li>
          Differentiation between recent infection (IgM) and past exposure
          (IgG).
        </li>
        <li>
          Useful in endemic regions or during outbreaks for rapid detection.
        </li>
      </ul>

      {/* Additional Notes */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Additional Notes:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          Scrub Typhus is caused by Orientia tsutsugamushi and transmitted by
          chigger bites.
        </li>
        <li>
          Rapid tests are qualitative and should be confirmed with additional
          methods like IFA (Indirect Fluorescent Antibody Test) or PCR for
          higher sensitivity and specificity.
        </li>
        <li>
          Early diagnosis is critical for initiating timely treatment to prevent
          severe complications such as multi-organ failure.
        </li>
      </ul>

      {/* Disclaimer */}
      <div className="border-t-2 border-gray-300 my-4"></div>
      <p className="text-center text-gray-500 text-sm">
        This test result should be interpreted by a healthcare professional in
        conjunction with the patient’s clinical history, symptoms, and other
        diagnostic findings.
      </p>
    </div>
  );
};

export const elephantiasis = () => {
  return (
    <div className="mx-auto bg-white shadow-md p-6 rounded-lg">
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Detailed Biological Reference Interval (Bio. Ref. Intv.)
      </h3>
      <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Parameter</th>
            <th className="border border-gray-300 px-4 py-2">Negative</th>
            <th className="border border-gray-300 px-4 py-2">Positive</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Filarial Antigen Detection
            </td>
            <td className="border border-gray-300 px-4 py-2">
              No evidence of active filarial infection.
            </td>
            <td className="border border-gray-300 px-4 py-2">
              Indicates the presence of <i>Wuchereria bancrofti</i> or{" "}
              <i>Brugia malayi</i> antigens, suggesting active infection.
            </td>
          </tr>
        </tbody>
      </table>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation
      </h3>
      <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Result</th>
            <th className="border border-gray-300 px-4 py-2">Remark</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Negative</td>
            <td className="border border-gray-300 px-4 py-2">
              No active filarial infection detected.
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Positive</td>
            <td className="border border-gray-300 px-4 py-2">
              Indicates active infection with filarial parasites, commonly
              associated with lymphatic filariasis (elephantiasis). Requires
              clinical correlation and possible follow-up testing.
            </td>
          </tr>
        </tbody>
      </table>

      {/* Clinical Use */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Clinical Use:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          Screening and diagnosis of lymphatic filariasis caused by Wuchereria
          bancrofti or Brugia malayi.
        </li>
        <li>
          Monitoring treatment response in patients undergoing antifilarial
          therapy.
        </li>
        <li>
          Useful for mass surveillance in endemic regions to control and
          eliminate lymphatic filariasis.
        </li>
      </ul>

      {/* Additional Notes */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Additional Notes:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          This is a qualitative test that detects filarial antigens in the
          blood.
        </li>
        <li>
          The test is highly specific for active infections and is often used in
          endemic areas for rapid diagnosis.
        </li>
        <li>
          Positive results should prompt further evaluation, including clinical
          examination and imaging, to assess the extent of lymphatic
          involvement.
        </li>
      </ul>

      {/* Disclaimer */}
      <div className="border-t-2 border-gray-300 my-4"></div>
      <p className="text-center text-gray-500 text-sm">
        This test result should be interpreted by a healthcare professional in
        conjunction with the patient’s clinical history, symptoms, and other
        diagnostic findings.
      </p>
    </div>
  );
};

export const malariapfpv = () => {
  return (
    <div className="mx-auto bg-white shadow-md p-6 rounded-lg">
      {/* Biological Reference Interval */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Detailed Biological Reference Interval:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          <strong>Plasmodium falciparum (HRP-II):</strong>
          <br />
          Negative: No evidence of active infection with Plasmodium falciparum.
          <br />
          Positive: Indicates active infection with Plasmodium falciparum.
        </li>
        <li>
          <strong>Plasmodium vivax (pLDH):</strong>
          <br />
          Negative: No evidence of active infection with Plasmodium vivax.
          <br />
          Positive: Indicates active infection with Plasmodium vivax.
        </li>
      </ul>

      {/* Interpretation */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Interpretation:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          <strong>Negative for Both HRP-II and pLDH:</strong> No evidence of
          active malaria infection.
        </li>
        <li>
          <strong>Positive for HRP-II:</strong> Suggests active infection with
          Plasmodium falciparum.
        </li>
        <li>
          <strong>Positive for pLDH:</strong> Indicates active infection with
          Plasmodium vivax.
        </li>
        <li>
          <strong>Positive for Both HRP-II and pLDH:</strong> Suggests mixed
          infection with Plasmodium falciparum and Plasmodium vivax.
        </li>
      </ul>

      {/* Clinical Use */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Clinical Use:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          Detection of malaria caused by Plasmodium falciparum or Plasmodium
          vivax.
        </li>
        <li>
          Useful in endemic regions for rapid diagnosis and initiation of
          treatment.
        </li>
        <li>Assists in ruling out malaria in febrile illnesses.</li>
      </ul>

      {/* Additional Notes */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Additional Notes:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          This qualitative immunochromatographic assay requires only 3 µL of
          whole blood.
        </li>
        <li>
          Test detects HRP-II from Plasmodium falciparum and pLDH (lactate
          dehydrogenase) from Plasmodium vivax.
        </li>
        <li>Performance: Sensitivity: 100%; Specificity: 100%.</li>
        <li>
          Positive results should be confirmed with microscopy or molecular
          diagnostics for precise diagnosis and monitoring.
        </li>
      </ul>
    </div>
  );
};

//Cadiac
export const ntprobnp = () => {
  return (
    <div className="mb-8">
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Category</h3>
      <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
        <thead>
          <tr class="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Age Group</th>
            <th className="border border-gray-300 px-4 py-2">
              Bio. Ref. Intv. (pg/mL)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              &lt;75 Years Old
            </td>
            <td className="border border-gray-300 px-4 py-2">&lt;125</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              &gt;75 Years Old
            </td>
            <td className="border border-gray-300 px-4 py-2">&lt;450</td>
          </tr>
        </tbody>
      </table>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation in Acute Dyspnea
      </h3>
      <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
        <thead>
          <tr class="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Category</th>
            <th className="border border-gray-300 px-4 py-2">
              Cut Off (pg/mL)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Rule In Cut Off
            </td>
            <td className="border border-gray-300 px-4 py-2">
              &lt;50 Years: &gt;450
              <br />
              50–75 Years: &gt;900
              <br />
              &gt;75 Years: &gt;1800
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Rule Out Cut Off
            </td>
            <td className="border border-gray-300 px-4 py-2">&lt;300</td>
          </tr>
        </tbody>
      </table>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Note</h3>
      <p className="mb-4">
        NT-proBNP value increases with age. Elevated levels can be seen in
        apparently healthy individuals with increasing age. Values need to be
        interpreted alongside medical history, clinical findings, and other
        information.
      </p>
      <p className="mb-4">
        NT-proBNP &lt;125 pg/mL excludes cardiac dysfunction with high certainty
        in patients presenting with dyspnea. Lack of elevation may occur in
        acute cases or ventricular inflow obstruction.
      </p>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Comments</h3>
      <p className="mb-4">
        NT-proBNP is a marker of atrial and ventricular distension due to
        increased intra-cardiac pressure, aiding in CHF diagnosis. While highly
        sensitive for ruling out heart failure, higher values suggest higher
        likelihood of heart failure.
      </p>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Clinical Uses
      </h3>
      <ul className="list-disc pl-6 mb-4">
        <li>Diagnosis of suspected CHF</li>
        <li>Detection of mild cardiac dysfunction</li>
        <li>Assessing severity of heart failure</li>
        <li>Risk stratification in ACS and CHF</li>
        <li>Monitoring therapy in left ventricular dysfunction</li>
      </ul>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Limitations of NT-proBNP
      </h3>
      <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Higher Levels</th>
            <th className="border border-gray-300 px-4 py-2">Lower Levels</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Increasing Age, ACS, Renal Insufficiency, RV Dysfunction,
              Pulmonary Hypertension, Pulmonary Embolism, Anemia, Sepsis, Mitral
              Regurgitation
            </td>
            <td className="border border-gray-300 px-4 py-2">
              Obesity, Pulmonary Edema, Pericarditis/Tamponade, Genetic
              Polymorphism
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const ddimer = () => {
  return (
    <div className="mb-8">
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Reference Range
      </h3>
      <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
        <thead>
          <tr class="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Category</th>
            <th className="border border-gray-300 px-4 py-2">Range (ng/mL)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Normal</td>
            <td className="border border-gray-300 px-4 py-2">&lt;500</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">High</td>
            <td className="border border-gray-300 px-4 py-2">&gt;500</td>
          </tr>
        </tbody>
      </table>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Overview</h3>
      <p className="mb-4">
        D-Dimer is a fibrin degradation product, a small protein fragment
        present in blood after a blood clot is degraded by fibrinolysis. It is
        increased in conditions like deep venous thrombosis (DVT), pulmonary
        embolism (PE), or disseminated intravascular coagulation (DIC).
      </p>
      <p className="mb-4">
        A negative D-Dimer report virtually rules out thromboembolism. However,
        fake positive readings can occur due to liver disease, high rheumatoid
        factor, inflammation, malignancy, trauma, pregnancy, recent surgery, or
        advanced age.
      </p>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Note</h3>
      <p className="mb-4">
        - The degree of D-Dimer increase does not necessarily correlate with the
        severity of the associated disease state. <br />
        - Increased levels have a high probability of venous thromboembolism
        (VTE) and require clinical correlation. <br />- Lipemia can falsely
        decrease D-Dimer levels.
      </p>

      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Comments</h3>
      <p className="mb-4">
        D-Dimer is a measurable by-product of fibrinolytic system activation. It
        is particularly valuable for excluding venous thromboembolism (VTE)
        diagnoses among high-risk patients. Elevated levels may also occur in
        large vessel thrombosis, soft tissue hematomas, pulmonary embolism,
        recent surgery, bleeding, pregnancy, liver disease, malignancy, and
        hyper-coagulable states.
      </p>
    </div>
  );
};

export const tni = () => {
  return (
    <div className="mb-8">
      {/* Summary Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Summary</h3>
      <p className="mb-4">
        Troponins are a family of proteins found in skeletal and cardiac muscle
        fibers responsible for muscle contraction. Troponin-I detects troponin
        presence in the blood, and excessive levels may indicate a heart attack.
      </p>

      {/* Status Table */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Status</h3>
      <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
        <thead>
          <tr class="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Category</th>
            <th className="border border-gray-300 px-4 py-2">
              Bio Reference Interval (ng/mL)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Normal Levels</td>
            <td className="border border-gray-300 px-4 py-2">&lt;0.05</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Observational Zone / Above Optimal
            </td>
            <td className="border border-gray-300 px-4 py-2">0.05 – 0.50</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              High / Requires Attention
            </td>
            <td className="border border-gray-300 px-4 py-2">&gt;0.50</td>
          </tr>
        </tbody>
      </table>

      {/* Interpretation Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretations
      </h3>
      <p className="mb-4">
        A high troponin level and even slight elevations can indicate some
        degree of heart damage. If there is a significant elevation in troponin
        levels, particularly a rise over several hours, it likely points to a
        recent heart attack.
      </p>
      <p className="mb-4">
        Normal troponin values measured over specific intervals rule out the
        possibility of heart damage. In such cases, symptoms could stem from
        non-cardiac causes.
      </p>
    </div>
  );
};

export const creatinine = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      {/* Biological Reference Interval */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Detailed Biological Reference Interval (Bio. Ref. Intv.)
      </h3>
      <table className="min-w-full bg-white border border-gray-300 mb-6">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">
              Reference Group
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Reference Range (mg/dL)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Adults</td>
            <td className="border border-gray-300 px-4 py-2">0.5 – 1.2</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Children (1–12 years)
            </td>
            <td className="border border-gray-300 px-4 py-2">0.3 – 0.7</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Infants (&lt;1 year)
            </td>
            <td className="border border-gray-300 px-4 py-2">0.2 – 0.4</td>
          </tr>
        </tbody>
      </table>

      {/* Interpretation Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          <strong>Elevated Creatinine Levels:</strong> May indicate kidney
          dysfunction, such as chronic kidney disease (CKD), acute kidney injury
          (AKI), or glomerulonephritis. Other causes include dehydration,
          high-protein diets, or severe muscle breakdown (e.g., rhabdomyolysis).
        </li>
        <li>
          <strong>Low Creatinine Levels:</strong> Often seen in individuals with
          reduced muscle mass, advanced liver disease, or during pregnancy.
        </li>
        <li>
          <strong>Normal Creatinine Levels:</strong> Suggest normal kidney
          filtration and healthy muscle metabolism.
        </li>
      </ul>

      {/* Clinical Use Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Clinical Use
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Assessment of kidney function, particularly in conditions like
          diabetes, hypertension, or chronic kidney disease (CKD).
        </li>
        <li>
          Calculation of eGFR (Estimated Glomerular Filtration Rate) for kidney
          health evaluation.
        </li>
        <li>
          Monitoring renal function in patients on nephrotoxic drugs or those
          with a history of kidney disease.
        </li>
      </ul>

      {/* Additional Notes */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Additional Notes
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Serum creatinine is a byproduct of muscle metabolism, eliminated
          through the kidneys.
        </li>
        <li>
          It is often evaluated alongside BUN (Blood Urea Nitrogen) and eGFR to
          provide a comprehensive understanding of kidney function.
        </li>
        <li>
          Temporary elevations may occur after intense physical activity,
          dehydration, or high-protein intake.
        </li>
        <li>
          GFR, estimated (eGFR) calculated using the 2021 CKD-EPI creatinine
          equation and GFR Category reported as per KDIGO guideline 2012.
        </li>
        <li>
          eGFR category G1 or G2 does not fulfill the criteria for CKD, in the
          absence of evidence of kidney damage.
        </li>
      </ul>

      {/* Disclaimer */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Disclaimer</h3>
      <p>
        This test result should be interpreted by a healthcare professional in
        conjunction with the patient’s clinical history and other diagnostic
        findings.
      </p>
    </div>
  );
};

export const sgotast = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      {/* Biological Reference Interval */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Biological Reference Interval (Bio. Ref. Intv.)
      </h3>
      <table className="min-w-full bg-white border border-gray-300 mb-6">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">
              Reference Group
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Reference Range (U/L)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Adult Male</td>
            <td className="border border-gray-300 px-4 py-2">10 – 40</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Adult Female</td>
            <td className="border border-gray-300 px-4 py-2">10 – 35</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Children</td>
            <td className="border border-gray-300 px-4 py-2">10 – 50</td>
          </tr>
        </tbody>
      </table>

      {/* Interpretation Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          <strong>Elevated SGOT Levels:</strong> Could indicate liver damage or
          diseases such as hepatitis, cirrhosis, or fatty liver. May also be
          elevated in conditions like myocardial infarction, muscle injuries, or
          pancreatitis.
        </li>
        <li>
          <strong>Normal SGOT Levels:</strong> Reflect proper liver function and
          absence of significant muscle or heart damage.
        </li>
        <li>
          <strong>Low SGOT Levels:</strong> Rarely clinically significant but
          could be associated with vitamin B6 deficiency.
        </li>
      </ul>

      {/* Clinical Use Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Clinical Use
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>Assessment of liver health and function.</li>
        <li>
          Diagnosis and monitoring of liver diseases and muscle disorders.
        </li>
        <li>
          Differentiation of liver versus non-liver sources of elevated enzymes
          (when used with SGPT/ALT).
        </li>
      </ul>

      {/* Additional Notes */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Additional Notes
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Always evaluate SGOT levels alongside SGPT (ALT) and other liver
          function tests for a comprehensive assessment.
        </li>
        <li>
          Temporary elevation of SGOT can occur after intense physical activity
          or muscle injuries.
        </li>
      </ul>

      {/* Disclaimer */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Disclaimer</h3>
      <p>
        This test result should be interpreted by a healthcare professional in
        conjunction with clinical history and other diagnostic findings.
      </p>
    </div>
  );
};

export const bloodmarkers = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">
        CBC Test Report
      </h2>

      {/* Test Results Table */}
      {/*<table className="min-w-full bg-white border border-gray-300 mb-6">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Test Name</th>
            <th className="border border-gray-300 px-4 py-2">Results</th>
            <th className="border border-gray-300 px-4 py-2">Unit</th>
            <th className="border border-gray-300 px-4 py-2">
              Bio. Ref. Intv.
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Total Leucocyte Count
            </td>
            <td className="border border-gray-300 px-4 py-2">4.5 - 10.5</td>
            <td className="border border-gray-300 px-4 py-2">10^3/uL</td>
            <td className="border border-gray-300 px-4 py-2">
              Male: 4.5 - 10.5, Female: 4.5 - 10.5
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">RBC Count</td>
            <td className="border border-gray-300 px-4 py-2">4.7 - 6.1</td>
            <td className="border border-gray-300 px-4 py-2">mill/uL</td>
            <td className="border border-gray-300 px-4 py-2">
              Male: 4.7 - 6.1, Female: 4.2 - 5.4
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Hemoglobin</td>
            <td className="border border-gray-300 px-4 py-2">13.5 - 17.5</td>
            <td className="border border-gray-300 px-4 py-2">g/dL</td>
            <td className="border border-gray-300 px-4 py-2">
              Male: 13.5 - 17.5, Female: 12.0 - 15.5
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Hematocrit</td>
            <td className="border border-gray-300 px-4 py-2">41.0 - 50.0</td>
            <td className="border border-gray-300 px-4 py-2">%</td>
            <td className="border border-gray-300 px-4 py-2">
              Male: 41.0 - 50.0, Female: 36.0 - 48.0
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Mean Corpuscular Volume
            </td>
            <td className="border border-gray-300 px-4 py-2">80.0 - 100.0</td>
            <td className="border border-gray-300 px-4 py-2">fL</td>
            <td className="border border-gray-300 px-4 py-2">
              Male & Female: 80.0 - 100.0
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Mean Corpuscular Hemoglobin
            </td>
            <td className="border border-gray-300 px-4 py-2">27.5 - 33.2</td>
            <td className="border border-gray-300 px-4 py-2">pg</td>
            <td className="border border-gray-300 px-4 py-2">
              Male & Female: 27.5 - 33.2
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Mean Corpuscular Hemoglobin Concentration
            </td>
            <td className="border border-gray-300 px-4 py-2">33.4 - 35.5</td>
            <td className="border border-gray-300 px-4 py-2">g/dL</td>
            <td className="border border-gray-300 px-4 py-2">
              Male & Female: 33.4 - 35.5
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Red Blood Cell Distribution Width - CV
            </td>
            <td className="border border-gray-300 px-4 py-2">11.5 - 15.4</td>
            <td className="border border-gray-300 px-4 py-2">%</td>
            <td className="border border-gray-300 px-4 py-2">
              Male & Female: 11.5 - 15.4
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Red Blood Cell Distribution Width - SD
            </td>
            <td className="border border-gray-300 px-4 py-2">35.0 - 56.0</td>
            <td className="border border-gray-300 px-4 py-2">fL</td>
            <td className="border border-gray-300 px-4 py-2">
              Male & Female: 35.0 - 56.0
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Platelet Count</td>
            <td className="border border-gray-300 px-4 py-2">150.0 - 450.0</td>
            <td className="border border-gray-300 px-4 py-2">10^3/uL</td>
            <td className="border border-gray-300 px-4 py-2">
              Male & Female: 150.0 - 450.0
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Mean Platelet Volume
            </td>
            <td className="border border-gray-300 px-4 py-2">8.6 - 15.5</td>
            <td className="border border-gray-300 px-4 py-2">fL</td>
            <td className="border border-gray-300 px-4 py-2">
              Male & Female: 8.6 - 15.5
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Plateletcrit</td>
            <td className="border border-gray-300 px-4 py-2">0.20 - 0.36</td>
            <td className="border border-gray-300 px-4 py-2">%</td>
            <td className="border border-gray-300 px-4 py-2">
              Male & Female: 0.20 - 0.36
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Platelet Distribution Width - CV
            </td>
            <td className="border border-gray-300 px-4 py-2">8.1 - 20.0</td>
            <td className="border border-gray-300 px-4 py-2">%</td>
            <td className="border border-gray-300 px-4 py-2">
              Male & Female: 8.1 - 20.0
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Platelet Distribution Width - SD
            </td>
            <td className="border border-gray-300 px-4 py-2">9.0 - 17.0</td>
            <td className="border border-gray-300 px-4 py-2">fL</td>
            <td className="border border-gray-300 px-4 py-2">
              Male & Female: 9.0 - 17.0
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Platelet - Large Cell Ratio
            </td>
            <td className="border border-gray-300 px-4 py-2">11.0 - 45.0</td>
            <td className="border border-gray-300 px-4 py-2">%</td>
            <td className="border border-gray-300 px-4 py-2">
              Male & Female: 11.0 - 45.0
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const haemoglobin = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">
        Haemoglobin Test Report
      </h2>

      {/* Test Results Table */}
      {/*<table className="min-w-full bg-white border border-gray-300 mb-6">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Test Name</th>
            <th className="border border-gray-300 px-4 py-2">Results</th>
            <th className="border border-gray-300 px-4 py-2">Unit</th>
            <th className="border border-gray-300 px-4 py-2">
              Bio. Ref. Intv.
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Haemoglobin</td>
            <td className="border border-gray-300 px-4 py-2">13.6</td>
            <td className="border border-gray-300 px-4 py-2">g/dL</td>
            <td className="border border-gray-300 px-4 py-2">&lt;100</td>
          </tr>
        </tbody>
      </table>

      {/* Biological Reference Interval */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Biological Reference Interval (Bio. Ref. Intv.)
      </h3>
      <table className="min-w-full bg-white border border-gray-300 mb-6">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">
              Reference Group
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Reference Range (g/dL)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Adult Male</td>
            <td className="border border-gray-300 px-4 py-2">13.0 – 17.0</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Adult Female</td>
            <td className="border border-gray-300 px-4 py-2">12.0 – 15.5</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Pregnant Women</td>
            <td className="border border-gray-300 px-4 py-2">11.0 – 14.0</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Children (6–12 years)
            </td>
            <td className="border border-gray-300 px-4 py-2">11.5 – 15.5</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Children (1–6 years)
            </td>
            <td className="border border-gray-300 px-4 py-2">10.5 – 14.0</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Infants (0.5–1 year)
            </td>
            <td className="border border-gray-300 px-4 py-2">11.0 – 13.5</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Newborns (0–0.5 months)
            </td>
            <td className="border border-gray-300 px-4 py-2">14.0 – 24.0</td>
          </tr>
        </tbody>
      </table>

      {/* Interpretation Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          <strong>Low Haemoglobin (Anemia):</strong> May indicate conditions
          such as iron deficiency, vitamin B12 or folate deficiency, chronic
          diseases, or bone marrow disorders.
        </li>
        <li>
          <strong>High Haemoglobin (Polycythemia):</strong> Could suggest
          dehydration, lung diseases, or disorders such as polycythemia vera.
        </li>
        <li>
          <strong>Normal Haemoglobin Levels:</strong> Reflect adequate
          oxygen-carrying capacity of the blood.
        </li>
      </ul>

      {/* Clinical Use Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Clinical Use
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>Screening and diagnosis of anemia or polycythemia.</li>
        <li>
          Monitoring Haemoglobin levels during pregnancy or after blood loss.
        </li>
        <li>
          Evaluating treatment response in conditions affecting Haemoglobin
          levels.
        </li>
      </ul>

      {/* Additional Notes */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Additional Notes
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Haemoglobin levels may vary with age, sex, altitude, and hydration
          status.
        </li>
        <li>
          For accurate results, ensure the patient is hydrated, and any
          interfering conditions are noted before testing.
        </li>
      </ul>

      {/* Disclaimer */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Disclaimer</h3>
      <p>
        This report should be interpreted by a qualified healthcare professional
        in conjunction with the patient's medical history and other diagnostic
        findings.
      </p>
    </div>
  );
};
export const completerenalprofile = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">
        Complete Renal Profile
      </h2>

      {/* Test Results Table */}
      {/*<table className="min-w-full bg-white border border-gray-300 mb-6">
        

      {/* Biological Reference Interval */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Detailed Biological Reference Interval (Bio. Ref. Intv.):
      </h3>
      <h2>1. Urea and BUN:</h2>
      <ul>
        <li>
          <strong>Urea Reference Range:</strong> 15 – 45 mg/dL.
        </li>
        <li>
          <strong>BUN Reference Range:</strong> 7 – 20 mg/dL (calculated as Urea
          ÷ 2.14).
        </li>
        <li>
          <strong>Elevated:</strong> Indicates kidney dysfunction, dehydration,
          or high protein intake.
        </li>
        <li>
          <strong>Low:</strong> May suggest malnutrition or liver disease.
        </li>
      </ul>

      <h2>2. Creatinine and eGFR:</h2>
      <ul>
        <li>
          <strong>Creatinine Reference Range:</strong> 0.5 – 1.2 mg/dL.
        </li>
        <li>
          <strong>eGFR Reference Range:</strong> greater than 60 mL/min/1.73m².
        </li>
        <li>Lower eGFR values indicate kidney dysfunction.</li>
        <li>
          <strong>GFR Category:</strong>
        </li>
        <ul>
          <li>G1: Normal (greate than 90 mL/min).</li>
          <li>G2: Mild decrease (60–89 mL/min).</li>
          <li>G3a: Mild to moderate (45–59 mL/min).</li>
          <li>G3b: Moderate to severe (30–44 mL/min).</li>
          <li>G4: Severe (15–29 mL/min).</li>
          <li>G5: Kidney failure (less than 15 mL/min).</li>
        </ul>
        <h2>3. BUN : Creatinine Ratio:</h2>
        <ul>
          <li>
            <strong>Normal Range:</strong> 10:1 – 20:1.
          </li>
          <li>
            <strong>Elevated:</strong> May indicate dehydration or high
            catabolic state.
          </li>
          <li>
            <strong>Low:</strong> May suggest low protein intake or liver
            disease.
          </li>
        </ul>

        <h2>4. Uric Acid:</h2>
        <ul>
          <li>
            <strong>Normal Range:</strong> 3.5 – 7.0 mg/dL.
          </li>
          <li>
            <strong>Elevated:</strong> Associated with gout or kidney
            dysfunction.
          </li>
        </ul>

        <h2>5. Total Protein, Albumin, Globulin, and A:G Ratio:</h2>
        <ul>
          <li>
            <strong>Total Protein:</strong> 6.0 – 8.3 g/dL.
          </li>
          <li>
            <strong>Albumin:</strong> 3.5 – 5.0 g/dL.
          </li>
          <li>
            <strong>Globulin (Calculated):</strong> Total Protein – Albumin (2.5
            – 3.5 g/dL).
          </li>
          <li>
            <strong>Albumin/Globulin Ratio:</strong> 1.1 – 2.2.
          </li>
          <li>
            <strong>Low A:G Ratio:</strong> Indicates chronic inflammation or
            liver dysfunction.
          </li>
        </ul>

        <h2>6. Calcium and Phosphorus:</h2>
        <ul>
          <li>
            <strong>Calcium Reference Range:</strong> 8.5 – 10.5 mg/dL.
          </li>
          <li>
            <strong>Phosphorus Reference Range:</strong> 2.5 – 4.5 mg/dL.
          </li>
          <li>
            Abnormal levels indicate kidney dysfunction or parathyroid
            disorders.
          </li>
        </ul>

        <h2>7. Electrolytes (Sodium, Potassium, Chloride):</h2>
        <ul>
          <li>
            <strong>Sodium:</strong> 135 – 145 mEq/L.
          </li>
          <li>
            <strong>Potassium:</strong> 3.5 – 5.1 mEq/L.
          </li>
          <li>
            <strong>Chloride:</strong> 98 – 107 mEq/L.
          </li>
          <li>
            Imbalances indicate fluid shifts, dehydration, or kidney
            dysfunction.
          </li>
        </ul>
      </ul>

      {/* Clinical Use */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Clinical Use
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>Evaluation of kidney function and electrolyte balance.</li>
        <li>
          Monitoring conditions like chronic kidney disease (CKD), hypertension,
          or diabetes.
        </li>
        <li>
          Assessment of protein metabolism, acid-base balance, and
          calcium-phosphorus regulation.
        </li>
      </ul>

      {/* Additional Notes */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Additional Notes
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          eGFR is a calculated value and should be interpreted alongside age,
          sex, and clinical symptoms.
        </li>
        <li>
          Persistent abnormalities require further investigation with imaging or
          advanced renal tests.
        </li>
      </ul>

      {/* Disclaimer */}
      <p className="text-sm text-gray-500">
        This test result should be interpreted by a healthcare professional in
        conjunction with the patient’s clinical history, symptoms, and other
        diagnostic findings.
      </p>
    </div>
  );
};

export const kidneymarkers = () => {
  <div className="p-6 bg-white rounded-lg shadow-md">
    <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">
      Urinalysis Test Report
    </h2>
      {/* Biological Reference Interval */}
    <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
      Detailed Biological Reference Interval (Bio. Ref. Intv.)
    </h3>
    <ul className="list-disc ml-6 mb-4">
      <li>
        <strong>Color:</strong> Normal: Pale yellow to amber. Abnormal: Dark
        yellow or brown (bilirubin), red (hematuria), or cloudy (infection).
      </li>
      <li>
        <strong>Specific Gravity:</strong> Normal Range: 1.005 – 1.030. Low
        values suggest dilute urine; high values indicate concentrated urine or
        dehydration.
      </li>
      <li>
        <strong>pH:</strong> Normal Range: 4.5 – 8.0. Acidic urine (pH less than
        4.5): Metabolic acidosis, diarrhea. Alkaline urine (pH greate than 8.0):
        UTI, renal tubular acidosis.
      </li>
      <li>
        <strong>Protein:</strong> Normal: Negative. Proteinuria indicates kidney
        dysfunction, infection, or glomerular damage.
      </li>
      <li>
        <strong>Glucose:</strong> Normal: Negative. Glycosuria suggests
        hyperglycemia or diabetes.
      </li>
      <li>
        <strong>Ketones:</strong> Normal: Negative. Positive in uncontrolled
        diabetes, starvation, or ketogenic diets.
      </li>
      <li>
        <strong>Bilirubin:</strong> Normal: Negative. Positive in liver diseases
        or bile duct obstruction.
      </li>
      <li>
        <strong>Urobilinogen:</strong> Normal Range: 0.1 – 1.0 EU/dL. Elevated
        in liver disease or hemolysis.
      </li>
      <li>
        <strong>Blood (Haemoglobin):</strong> Normal: Negative. Hematuria
        indicates trauma, stones, or glomerulonephritis.
      </li>
      <li>
        <strong>Leukocytes (WBCs):</strong> Normal: Negative. Positive in UTI or
        inflammation.
      </li>
      <li>
        <strong>Nitrites:</strong> Normal: Negative. Positive in bacterial
        infections (e.g., E. coli).
      </li>
    </ul>

    {/* Clinical Use */}
    <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Clinical Use</h3>
    <ul className="list-disc ml-6 mb-4">
      <li>
        Evaluation of urinary system health for detecting infections, liver
        conditions, or kidney diseases.
      </li>
      <li>
        Screening for UTIs, diabetes, liver dysfunction, or metabolic disorders.
      </li>
      <li>Monitoring in chronic diseases or pregnancy.</li>
    </ul>

    {/* Additional Notes */}
    <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
      Additional Notes
    </h3>
    <ul className="list-disc ml-6 mb-4">
      <li>
        Abnormal findings may require confirmatory testing, such as microscopy,
        urine culture, or imaging studies.
      </li>
      <li>
        Ensure proper sample collection (midstream, clean catch) to avoid
        contamination.
      </li>
    </ul>

    {/* Disclaimer */}
    <p className="text-sm text-gray-500">
      This test result should be interpreted by a healthcare professional in
      conjunction with the patient’s clinical history, symptoms, and other
      diagnostic findings.
    </p>
  </div>;
};

export const routinepanel = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">
        Urinalysis Test Report
      </h2>

      {/* Test Results Table */}
      {/*<table className="min-w-full bg-white border border-gray-300 mb-6">
        <thead>
          <tr className="bg-[#D8F2DF] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Parameter</th>
            <th className="border border-gray-300 px-4 py-2">Results</th>
            <th className="border border-gray-300 px-4 py-2">Unit</th>
            <th className="border border-gray-300 px-4 py-2">
              Reference Range
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Color</td>
            <td className="border border-gray-300 px-4 py-2">
              [Patient's Value]
            </td>
            <td className="border border-gray-300 px-4 py-2">-</td>
            <td className="border border-gray-300 px-4 py-2">
              Pale yellow to amber
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Specific Gravity
            </td>
            <td className="border border-gray-300 px-4 py-2">
              [Patient's Value]
            </td>
            <td className="border border-gray-300 px-4 py-2">-</td>
            <td className="border border-gray-300 px-4 py-2">1.005 – 1.030</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">pH</td>
            <td className="border border-gray-300 px-4 py-2">
              [Patient's Value]
            </td>
            <td className="border border-gray-300 px-4 py-2">-</td>
            <td className="border border-gray-300 px-4 py-2">4.5 – 8.0</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Protein</td>
            <td className="border border-gray-300 px-4 py-2">
              [Patient's Value]
            </td>
            <td className="border border-gray-300 px-4 py-2">mg/dL</td>
            <td className="border border-gray-300 px-4 py-2">Negative</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Glucose</td>
            <td className="border border-gray-300 px-4 py-2">
              [Patient's Value]
            </td>
            <td className="border border-gray-300 px-4 py-2">mg/dL</td>
            <td className="border border-gray-300 px-4 py-2">Negative</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Ketones</td>
            <td className="border border-gray-300 px-4 py-2">
              [Patient's Value]
            </td>
            <td className="border border-gray-300 px-4 py-2">mg/dL</td>
            <td className="border border-gray-300 px-4 py-2">Negative</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Bilirubin</td>
            <td className="border border-gray-300 px-4 py-2">
              [Patient's Value]
            </td>
            <td className="border border-gray-300 px-4 py-2">-</td>
            <td className="border border-gray-300 px-4 py-2">Negative</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Urobilinogen</td>
            <td className="border border-gray-300 px-4 py-2">
              [Patient's Value]
            </td>
            <td className="border border-gray-300 px-4 py-2">EU/dL</td>
            <td className="border border-gray-300 px-4 py-2">Negative</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Blood (Haemoglobin)
            </td>
            <td className="border border-gray-300 px-4 py-2">
              [Patient's Value]
            </td>
            <td className="border border-gray-300 px-4 py-2">-</td>
            <td className="border border-gray-300 px-4 py-2">Negative</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Leukocytes (WBCs)
            </td>
            <td className="border border-gray-300 px-4 py-2">
              [Patient's Value]
            </td>
            <td className="border border-gray-300 px-4 py-2">-</td>
            <td className="border border-gray-300 px-4 py-2">Negative</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Nitrites</td>
            <td className="border border-gray-300 px-4 py-2">
              [Patient's Value]
            </td>
            <td className="border border-gray-300 px-4 py-2">-</td>
            <td className="border border-gray-300 px-4 py-2">Negative</td>
          </tr>
        </tbody>
      </table>

      {/* Biological Reference Interval */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Detailed Biological Reference Interval (Bio. Ref. Intv.)
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          <strong>Color:</strong> Normal: Pale yellow to amber. Abnormal: Dark
          yellow or brown (bilirubin), red (hematuria), or cloudy (infection).
        </li>
        <li>
          <strong>Specific Gravity:</strong> Normal Range: 1.005 – 1.030. Low
          values suggest dilute urine; high values indicate concentrated urine
          or dehydration.
        </li>
        <li>
          <strong>pH:</strong> Normal Range: 4.5 – 8.0. Acidic urine (pH less
          than 4.5): Metabolic acidosis, diarrhea. Alkaline urine (pH greate
          than 8.0): UTI, renal tubular acidosis.
        </li>
        <li>
          <strong>Protein:</strong> Normal: Negative. Proteinuria indicates
          kidney dysfunction, infection, or glomerular damage.
        </li>
        <li>
          <strong>Glucose:</strong> Normal: Negative. Glycosuria suggests
          hyperglycemia or diabetes.
        </li>
        <li>
          <strong>Ketones:</strong> Normal: Negative. Positive in uncontrolled
          diabetes, starvation, or ketogenic diets.
        </li>
        <li>
          <strong>Bilirubin:</strong> Normal: Negative. Positive in liver
          diseases or bile duct obstruction.
        </li>
        <li>
          <strong>Urobilinogen:</strong> Normal Range: 0.1 – 1.0 EU/dL. Elevated
          in liver disease or hemolysis.
        </li>
        <li>
          <strong>Blood (Haemoglobin):</strong> Normal: Negative. Hematuria
          indicates trauma, stones, or glomerulonephritis.
        </li>
        <li>
          <strong>Leukocytes (WBCs):</strong> Normal: Negative. Positive in UTI
          or inflammation.
        </li>
        <li>
          <strong>Nitrites:</strong> Normal: Negative. Positive in bacterial
          infections (e.g., E. coli).
        </li>
      </ul>

      {/* Clinical Use */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Clinical Use
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Evaluation of urinary system health for detecting infections, liver
          conditions, or kidney diseases.
        </li>
        <li>
          Screening for UTIs, diabetes, liver dysfunction, or metabolic
          disorders.
        </li>
        <li>Monitoring in chronic diseases or pregnancy.</li>
      </ul>

      {/* Additional Notes */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Additional Notes
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Abnormal findings may require confirmatory testing, such as
          microscopy, urine culture, or imaging studies.
        </li>
        <li>
          Ensure proper sample collection (midstream, clean catch) to avoid
          contamination.
        </li>
      </ul>

      {/* Disclaimer */}
      <p className="text-sm text-gray-500">
        This test result should be interpreted by a healthcare professional in
        conjunction with the patient’s clinical history, symptoms, and other
        diagnostic findings.
      </p>
    </div>
  );
};

export const potassium = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      {/* Biological Reference Interval */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Detailed Biological Reference Interval (Bio. Ref. Intv.)
      </h3>
      <table className="min-w-full bg-white border border-gray-300 mb-6">
        <thead>
          <tr className="bg-[#EAF7ED] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">
              Reference Group
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Reference Range (mEq/L)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Adults</td>
            <td className="border border-gray-300 px-4 py-2">3.5 – 5.1</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Children</td>
            <td className="border border-gray-300 px-4 py-2">3.4 – 4.7</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Newborns</td>
            <td className="border border-gray-300 px-4 py-2">3.7 – 5.9</td>
          </tr>
        </tbody>
      </table>

      {/* Interpretation Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          <strong>Elevated Potassium Levels (Hyperkalemia):</strong> May
          indicate kidney dysfunction, Addison’s disease, or cell breakdown
          (e.g., rhabdomyolysis or hemolysis). Can also occur due to medications
          like potassium-sparing diuretics, ACE inhibitors, or overuse of
          potassium supplements.
        </li>
        <li>
          <strong>Low Potassium Levels (Hypokalemia):</strong> Could result from
          conditions like prolonged vomiting, diarrhea, or use of certain
          diuretics. May also indicate hyperaldosteronism, malnutrition, or
          excessive insulin use.
        </li>
        <li>
          <strong>Normal Potassium Levels:</strong> Reflect a healthy balance of
          potassium, which is crucial for nerve and muscle function.
        </li>
      </ul>

      {/* Clinical Use */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Clinical Use
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Assessment of electrolyte balance in kidney diseases, adrenal
          disorders, or metabolic acidosis/alkalosis.
        </li>
        <li>
          Monitoring potassium levels in patients on diuretics, ACE inhibitors,
          or potassium supplements.
        </li>
        <li>
          Evaluation of symptoms like muscle weakness, arrhythmias, or fatigue.
        </li>
      </ul>

      {/* Additional Notes */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Additional Notes
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Potassium plays a critical role in maintaining cell membrane potential
          and proper heart and muscle function.
        </li>
        <li>
          Serum potassium levels should be interpreted alongside other
          electrolytes (e.g., sodium) for accurate diagnosis.
        </li>
        <li>
          Hemolysis during sample collection can falsely elevate potassium
          levels.
        </li>
      </ul>

      {/* Disclaimer */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Disclaimer</h3>
      <p>
        This test result should be interpreted by a healthcare professional in
        conjunction with the patient’s clinical history, symptoms, and other
        diagnostic findings.
      </p>
    </div>
  );
};

export const sodium = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      {/* Biological Reference Interval */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Detailed Biological Reference Interval (Bio. Ref. Intv.)
      </h3>
      <table className="min-w-full bg-white border border-gray-300 mb-6">
        <thead>
          <tr className="bg-[#E7E5FC] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">
              Reference Group
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Reference Range (mEq/L)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Adults</td>
            <td className="border border-gray-300 px-4 py-2">135 – 145</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Children</td>
            <td className="border border-gray-300 px-4 py-2">135 – 145</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Newborns</td>
            <td className="border border-gray-300 px-4 py-2">133 – 146</td>
          </tr>
        </tbody>
      </table>

      {/* Interpretation Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          <strong>Elevated Sodium Levels (Hypernatremia):</strong> May indicate
          dehydration, diabetes insipidus, excessive salt intake, or loss of
          free water (e.g., due to burns, fever, or sweating). Common in
          conditions like hyperaldosteronism or kidney dysfunction.
        </li>
        <li>
          <strong>Low Sodium Levels (Hyponatremia):</strong> Could be caused by
          conditions such as heart failure, kidney disease, liver cirrhosis, or
          SIADH (syndrome of inappropriate antidiuretic hormone). May also occur
          due to excessive water intake, diuretics, or gastrointestinal losses
          (vomiting, diarrhea).
        </li>
        <li>
          <strong>Normal Sodium Levels:</strong> Indicate balanced electrolyte
          and fluid homeostasis.
        </li>
      </ul>

      {/* Clinical Use */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Clinical Use
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Assessment of electrolyte balance in dehydration, overhydration, or
          suspected electrolyte disorders.
        </li>
        <li>
          Monitoring conditions like kidney dysfunction, heart failure, and
          liver disease.
        </li>
        <li>
          Essential for evaluating symptoms such as confusion, fatigue,
          seizures, or muscle weakness.
        </li>
      </ul>

      {/* Additional Notes */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Additional Notes
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Sodium is a key electrolyte that helps regulate water balance, blood
          pressure, and nerve and muscle function.
        </li>
        <li>
          Abnormal sodium levels must be assessed with other electrolytes (e.g.,
          potassium) and clinical context for accurate diagnosis.
        </li>
      </ul>

      {/* Disclaimer */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Disclaimer</h3>
      <p>
        This test result should be interpreted by a healthcare professional in
        conjunction with the patient’s clinical history, symptoms, and other
        diagnostic findings.
      </p>
    </div>
  );
};

export const uricacid = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      {/* Biological Reference Interval */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Detailed Biological Reference Interval (Bio. Ref. Intv.)
      </h3>
      <table className="min-w-full bg-white border border-gray-300 mb-6">
        <thead>
          <tr className="bg-[#E7E5FC] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">
              Reference Group
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Reference Range (mg/dL)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Adults (Male)</td>
            <td className="border border-gray-300 px-4 py-2">3.5 – 7.2</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Adults (Female)
            </td>
            <td className="border border-gray-300 px-4 py-2">2.6 – 6.0</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Children</td>
            <td className="border border-gray-300 px-4 py-2">2.0 – 5.5</td>
          </tr>
        </tbody>
      </table>

      {/* Interpretation Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          <strong>Elevated Uric Acid Levels (Hyperuricemia):</strong> May
          indicate gout, kidney dysfunction, or overproduction of uric acid due
          to high-purine diets or conditions like tumor lysis syndrome. Can also
          be associated with metabolic syndrome, obesity, or certain medications
          (e.g., diuretics).
        </li>
        <li>
          <strong>Low Uric Acid Levels (Hypouricemia):</strong> May be seen in
          conditions like liver disease, Fanconi syndrome, or the use of
          medications such as allopurinol or high-dose aspirin.
        </li>
        <li>
          <strong>Normal Uric Acid Levels:</strong> Reflect balanced purine
          metabolism and effective excretion by the kidneys.
        </li>
      </ul>

      {/* Clinical Use */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Clinical Use
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Diagnosis and management of gout and monitoring therapy effectiveness.
        </li>
        <li>
          Evaluation of kidney function in cases of suspected renal impairment.
        </li>
        <li>
          Monitoring conditions associated with high cell turnover, such as
          cancer treatment (chemotherapy/radiation) or hematological disorders.
        </li>
      </ul>

      {/* Additional Notes */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Additional Notes
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Uric acid is the end product of purine metabolism, excreted by the
          kidneys.
        </li>
        <li>
          Temporary increases in uric acid may occur after a high-purine meal,
          intense physical activity, or dehydration.
        </li>
        <li>
          Lifestyle modifications like a low-purine diet and adequate hydration
          can help manage elevated levels.
        </li>
      </ul>

      {/* Disclaimer */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Disclaimer</h3>
      <p>
        This test result should be interpreted by a healthcare professional in
        conjunction with the patient’s clinical history, symptoms, and other
        diagnostic findings.
      </p>
    </div>
  );
};

export const urea = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      {/* Biological Reference Interval */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Detailed Biological Reference Interval (Bio. Ref. Intv.)
      </h3>
      <table className="min-w-full bg-white border border-gray-300 mb-6">
        <thead>
          <tr className="bg-[#E7E5FC] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">
              Reference Group
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Reference Range (mg/dL)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Adults</td>
            <td className="border border-gray-300 px-4 py-2">15 – 45</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Children</td>
            <td className="border border-gray-300 px-4 py-2">10 – 40</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Newborns</td>
            <td className="border border-gray-300 px-4 py-2">5 – 20</td>
          </tr>
        </tbody>
      </table>

      {/* Interpretation Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          <strong>Elevated Urea Levels (Azotemia):</strong> Commonly indicates
          reduced kidney function, such as in chronic kidney disease (CKD) or
          acute kidney injury (AKI). Other causes include dehydration,
          gastrointestinal bleeding, high-protein diets, or catabolic states
          (e.g., severe infection or burns).
        </li>
        <li>
          <strong>Low Urea Levels:</strong> May be due to malnutrition, liver
          failure (reduced urea synthesis), or overhydration.
        </li>
        <li>
          <strong>Normal Urea Levels:</strong> Reflect healthy kidney function
          and normal protein metabolism.
        </li>
      </ul>

      {/* Clinical Use */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Clinical Use
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Assessment of kidney function, particularly in diagnosing and
          monitoring renal disorders.
        </li>
        <li>
          Often used in combination with creatinine to calculate the
          BUN/Creatinine ratio, which helps differentiate between renal and
          non-renal causes of dysfunction.
        </li>
        <li>
          Monitoring urea levels in critical conditions, such as dehydration or
          severe illness.
        </li>
      </ul>

      {/* Additional Notes */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Additional Notes
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Urea is a byproduct of protein metabolism, synthesized in the liver,
          and excreted by the kidneys.
        </li>
        <li>
          Abnormal levels must be interpreted alongside other parameters like
          creatinine and clinical history for accurate diagnosis.
        </li>
      </ul>

      {/* Disclaimer */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Disclaimer</h3>
      <p>
        This test result should be interpreted by a healthcare professional in
        conjunction with the patient’s clinical history, symptoms, and other
        diagnostic findings.
      </p>
    </div>
  );
};

export const liverprofile = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">
        Liver Function Test Report
      </h2>

      {/* Test Results Table */}
      {/*<table className="min-w-full bg-white border border-gray-300 mb-6">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Test</th>
            <th className="border border-gray-300 px-4 py-2">Results</th>
            <th className="border border-gray-300 px-4 py-2">Unit</th>
            <th className="border border-gray-300 px-4 py-2">
              Reference Range
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Total Bilirubin
            </td>
            <td className="border border-gray-300 px-4 py-2">
              [Patient's Value]
            </td>
            <td className="border border-gray-300 px-4 py-2">mg/dL</td>
            <td className="border border-gray-300 px-4 py-2">0.3 – 1.2</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Direct Bilirubin
            </td>
            <td className="border border-gray-300 px-4 py-2">
              [Patient's Value]
            </td>
            <td className="border border-gray-300 px-4 py-2">mg/dL</td>
            <td className="border border-gray-300 px-4 py-2">0.0 – 0.3</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Indirect Bilirubin
            </td>
            <td className="border border-gray-300 px-4 py-2">
              [Patient's Value]
            </td>
            <td className="border border-gray-300 px-4 py-2">mg/dL</td>
            <td className="border border-gray-300 px-4 py-2">&lt;1.10</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">SGOT (AST)</td>
            <td className="border border-gray-300 px-4 py-2">
              [Patient's Value]
            </td>
            <td className="border border-gray-300 px-4 py-2">U/L</td>
            <td className="border border-gray-300 px-4 py-2">10 – 40</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">SGPT (ALT)</td>
            <td className="border border-gray-300 px-4 py-2">
              [Patient's Value]
            </td>
            <td className="border border-gray-300 px-4 py-2">U/L</td>
            <td className="border border-gray-300 px-4 py-2">10 – 49</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              ALP (Alkaline Phosphatase)
            </td>
            <td className="border border-gray-300 px-4 py-2">
              [Patient's Value]
            </td>
            <td className="border border-gray-300 px-4 py-2">U/L</td>
            <td className="border border-gray-300 px-4 py-2">
              30 - 120 {`{40 – 129 (Male) / 35 – 104 (Female)}`}
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">GGTP</td>
            <td className="border border-gray-300 px-4 py-2">
              [Patient's Value]
            </td>
            <td className="border border-gray-300 px-4 py-2">U/L</td>
            <td className="border border-gray-300 px-4 py-2">
              0 - 73 {`{10 – 71 (Male) / 6 – 42 (Female)}`}
            </td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Total Protein</td>
            <td className="border border-gray-300 px-4 py-2">
              [Patient's Value]
            </td>
            <td className="border border-gray-300 px-4 py-2">g/dL</td>
            <td className="border border-gray-300 px-4 py-2">5.7 - 8.3</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Albumin</td>
            <td className="border border-gray-300 px-4 py-2">
              [Patient's Value]
            </td>
            <td className="border border-gray-300 px-4 py-2">g/dL</td>
            <td className="border border-gray-300 px-4 py-2">3.2 – 5.0</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Globulin</td>
            <td className="border border-gray-300 px-4 py-2">
              [Patient's Value]
            </td>
            <td className="border border-gray-300 px-4 py-2">g/dL</td>
            <td className="border border-gray-300 px-4 py-2">2.0 - 3.5</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">AST/ALT Ratio</td>
            <td className="border border-gray-300 px-4 py-2"></td>
            <td className="border border-gray-300 px-4 py-2">Ratio</td>
            <td className="border border-gray-300 px-4 py-2">&lt;1.0</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">A/G Ratio</td>
            <td className="border border-gray-300 px-4 py-2">
              [Patient's Value]
            </td>
            <td className="border border-gray-300 px-4 py-2">Ratio</td>
            <td className="border border-gray-300 px-4 py-2">0.9 - 2.2</td>
          </tr>
        </tbody>
      </table>

      {/* Interpretation Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          <strong>Bilirubin:</strong> Elevated levels may indicate liver
          dysfunction, bile duct obstruction, or hemolysis.
        </li>
        <li>
          <strong>SGOT (AST) & SGPT (ALT):</strong> Elevated levels suggest
          liver cell injury, such as hepatitis or fatty liver.
        </li>
        <li>
          <strong>Alkaline Phosphatase (ALP):</strong> High levels may indicate
          bile duct obstruction or bone disorders.
        </li>
        <li>
          <strong>GGTP:</strong> Elevated GGTP confirms liver or bile duct
          dysfunction.
        </li>
        <li>
          <strong>Protein Levels:</strong> Low albumin levels indicate
          malnutrition or liver/kidney issues. High globulin may suggest chronic
          inflammation.
        </li>
      </ul>

      {/* Clinical Use */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Clinical Use
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>Diagnosis and monitoring of liver diseases.</li>
        <li>Assessment of bile duct obstruction and alcoholic liver damage.</li>
        <li>Evaluation of nutritional or immune-related conditions.</li>
      </ul>

      {/* Additional Notes */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Additional Notes
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>Liver enzymes and proteins should be interpreted collectively.</li>
        <li>
          Temporary elevations can result from medications or physical activity.
        </li>
        <li>Further testing may be required if abnormalities are detected.</li>
      </ul>

      {/* Disclaimer */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Disclaimer</h3>
      <p>
        This test result should be interpreted by a healthcare professional in
        conjunction with the patient’s clinical history and other diagnostic
        findings.
      </p>
    </div>
  );
};

export const livermarkers = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">
        Liver Function Test Report
      </h2>

      {/* Test Results Table */}
      {/*<table className="min-w-full bg-white border border-gray-300 mb-6">

      {/* Interpretation Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          <strong>Bilirubin:</strong> Elevated levels may indicate liver
          dysfunction, bile duct obstruction, or hemolysis.
        </li>
        <li>
          <strong>SGOT (AST) & SGPT (ALT):</strong> Elevated levels suggest
          liver cell injury, such as hepatitis or fatty liver.
        </li>
        <li>
          <strong>Alkaline Phosphatase (ALP):</strong> High levels may indicate
          bile duct obstruction or bone disorders.
        </li>
        <li>
          <strong>GGTP:</strong> Elevated GGTP confirms liver or bile duct
          dysfunction.
        </li>
        <li>
          <strong>Protein Levels:</strong> Low albumin levels indicate
          malnutrition or liver/kidney issues. High globulin may suggest chronic
          inflammation.
        </li>
      </ul>

      {/* Clinical Use */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Clinical Use
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>Diagnosis and monitoring of liver diseases.</li>
        <li>Assessment of bile duct obstruction and alcoholic liver damage.</li>
        <li>Evaluation of nutritional or immune-related conditions.</li>
      </ul>

      {/* Additional Notes */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Additional Notes
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>Liver enzymes and proteins should be interpreted collectively.</li>
        <li>
          Temporary elevations can result from medications or physical activity.
        </li>
        <li>Further testing may be required if abnormalities are detected.</li>
      </ul>

      {/* Disclaimer */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Disclaimer</h3>
      <p>
        This test result should be interpreted by a healthcare professional in
        conjunction with the patient’s clinical history and other diagnostic
        findings.
      </p>
    </div>
  );
};

export const lipase = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      {/* Biological Reference Interval */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Biological Reference Interval (Bio. Ref. Intv.)
      </h3>
      <table className="min-w-full bg-white border border-gray-300 mb-6">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">
              Reference Group
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Reference Range (U/L)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Adults</td>
            <td className="border border-gray-300 px-4 py-2">0 – 160</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Children</td>
            <td className="border border-gray-300 px-4 py-2">0 – 130</td>
          </tr>
        </tbody>
      </table>

      {/* Interpretation Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          <strong>Elevated Lipase Levels (Hyperlipasemia):</strong> Commonly
          indicates acute pancreatitis, pancreatic duct obstruction, or
          pancreatic tumors. May also be associated with kidney disease,
          gallstones, or certain gastrointestinal disorders.
        </li>
        <li>
          <strong>Low Lipase Levels:</strong> Rarely clinically significant but
          could indicate chronic pancreatitis or severe pancreatic
          insufficiency.
        </li>
        <li>
          <strong>Normal Lipase Levels:</strong> Reflect normal pancreatic
          function and the absence of significant pancreatic or gastrointestinal
          disorders.
        </li>
      </ul>

      {/* Clinical Use Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Clinical Use
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Diagnosis and monitoring of acute pancreatitis, with lipase being more
          specific and longer-lasting than amylase.
        </li>
        <li>
          Assessment of pancreatic function in the presence of abdominal pain or
          suspected pancreatic disorders.
        </li>
        <li>
          Helps differentiate pancreatic from non-pancreatic causes of elevated
          amylase.
        </li>
      </ul>

      {/* Additional Notes */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Additional Notes
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Lipase levels typically rise within 4–8 hours of pancreatic injury,
          peak at 24 hours, and normalize within 8–14 days.
        </li>
        <li>
          Measurement of both amylase and lipase levels increases diagnostic
          accuracy in pancreatic disorders.
        </li>
        <li>
          Elevated lipase levels may also result from non-pancreatic causes such
          as kidney dysfunction or certain medications.
        </li>
      </ul>

      {/* Disclaimer */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Disclaimer</h3>
      <p>
        This test result should be interpreted by a healthcare professional in
        conjunction with the patient’s clinical history and other diagnostic
        findings.
      </p>
    </div>
  );
};

export const amylase = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      {/* Biological Reference Interval */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Biological Reference Interval (Bio. Ref. Intv.)
      </h3>
      <table className="min-w-full bg-white border border-gray-300 mb-6">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">
              Reference Group
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Reference Range (U/L)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Adults</td>
            <td className="border border-gray-300 px-4 py-2">30 – 110</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Children</td>
            <td className="border border-gray-300 px-4 py-2">20 – 100</td>
          </tr>
        </tbody>
      </table>

      {/* Interpretation Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          <strong>Elevated Amylase Levels (Hyperamylasemia):</strong> Commonly
          associated with acute pancreatitis, pancreatic duct obstruction, or
          pancreatic cancer. May also occur in salivary gland disorders (e.g.,
          mumps), gallbladder disease, or renal failure.
        </li>
        <li>
          <strong>Low Amylase Levels (Hypoamylasemia):</strong> May be seen in
          chronic pancreatitis or severe pancreatic insufficiency. Rarely
          significant unless accompanied by clinical symptoms.
        </li>
        <li>
          <strong>Normal Amylase Levels:</strong> Indicate normal pancreatic and
          salivary gland function.
        </li>
      </ul>

      {/* Clinical Use Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Clinical Use
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Diagnosis of acute pancreatitis and monitoring of its progression.
        </li>
        <li>
          Evaluation of abdominal pain, particularly related to pancreatic or
          salivary gland conditions.
        </li>
        <li>
          Assists in diagnosing other conditions affecting the pancreas, such as
          tumors or chronic inflammation.
        </li>
      </ul>

      {/* Additional Notes */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Additional Notes
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Serum amylase levels rise rapidly within hours of pancreatic injury
          and may remain elevated for 3–5 days in acute pancreatitis.
        </li>
        <li>
          Lipase levels are often measured alongside amylase for greater
          diagnostic accuracy.
        </li>
        <li>
          Non-pancreatic causes of elevated amylase should be ruled out if no
          pancreatic disorder is suspected.
        </li>
      </ul>

      {/* Disclaimer */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Disclaimer</h3>
      <p>
        This test result should be interpreted by a healthcare professional in
        conjunction with the patient’s clinical history and other diagnostic
        findings.
      </p>
    </div>
  );
};

export const albumin = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">
        Albumin Test Report
      </h2>

      {/* Test Results Table */}
      {/*<table className="min-w-full bg-white border border-gray-300 mb-6">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Test Name</th>
            <th className="border border-gray-300 px-4 py-2">Results</th>
            <th className="border border-gray-300 px-4 py-2">Unit</th>
            <th className="border border-gray-300 px-4 py-2">
              Bio. Ref. Intv.
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Albumin</td>
            <td className="border border-gray-300 px-4 py-2">0.09</td>
            <td className="border border-gray-300 px-4 py-2">g/dL</td>
            <td className="border border-gray-300 px-4 py-2">3.2 – 5.0</td>
          </tr>
        </tbody>
      </table>*/}

      {/* Biological Reference Interval */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Biological Reference Interval (Bio. Ref. Intv.)
      </h3>
      <table className="min-w-full bg-white border border-gray-300 mb-6">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">
              Reference Group
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Reference Range (g/dL)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Adults</td>
            <td className="border border-gray-300 px-4 py-2">3.5 – 5.0</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Children</td>
            <td className="border border-gray-300 px-4 py-2">3.8 – 5.4</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Newborns</td>
            <td className="border border-gray-300 px-4 py-2">2.8 – 4.4</td>
          </tr>
        </tbody>
      </table>

      {/* Interpretation Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          <strong>Elevated Albumin Levels (Hyperalbuminemia):</strong> Typically
          due to dehydration or high protein intake. Rarely associated with
          specific diseases.
        </li>
        <li>
          <strong>Low Albumin Levels (Hypoalbuminemia):</strong> May indicate
          liver diseases (e.g., cirrhosis), kidney disorders (e.g., nephrotic
          syndrome), malnutrition, or gastrointestinal protein loss. Chronic
          diseases, infections, or inflammation may also result in reduced
          albumin levels.
        </li>
        <li>
          <strong>Normal Albumin Levels:</strong> Reflect adequate protein
          intake and normal liver and kidney function.
        </li>
      </ul>

      {/* Clinical Use Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Clinical Use
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>Assessment of nutritional status.</li>
        <li>
          Monitoring of liver and kidney function, particularly in chronic
          diseases.
        </li>
        <li>
          Helps determine the cause of edema or other conditions related to
          protein imbalances.
        </li>
      </ul>

      {/* Additional Notes */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Additional Notes
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Albumin is the most abundant serum protein, primarily synthesized by
          the liver. It plays a critical role in maintaining oncotic pressure
          and transporting substances like hormones and drugs.
        </li>
        <li>
          If albumin levels are abnormal, it is recommended to evaluate total
          protein and globulin levels for a comprehensive assessment.
        </li>
      </ul>

      {/* Disclaimer */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Disclaimer</h3>
      <p>
        This test result should be interpreted by a healthcare professional in
        conjunction with the patient’s clinical history and other laboratory
        findings.
      </p>
    </div>
  );
};

export const totalprotein = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      {/* Biological Reference Interval */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Biological Reference Interval (Bio. Ref. Intv.)
      </h3>
      <table className="min-w-full bg-white border border-gray-300 mb-6">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">
              Reference Group
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Reference Range (g/dL)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Adults</td>
            <td className="border border-gray-300 px-4 py-2">6.0 – 8.3</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Children</td>
            <td className="border border-gray-300 px-4 py-2">6.2 – 8.0</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Newborns</td>
            <td className="border border-gray-300 px-4 py-2">4.6 – 7.2</td>
          </tr>
        </tbody>
      </table>

      {/* Interpretation Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          <strong>Elevated Total Protein Levels (Hyperproteinemia):</strong> May
          indicate dehydration, chronic inflammation, or conditions like
          multiple myeloma or monoclonal gammopathy.
        </li>
        <li>
          <strong>Low Total Protein Levels (Hypoproteinemia):</strong> May be
          caused by malnutrition, liver diseases (e.g., cirrhosis), kidney
          diseases (e.g., nephrotic syndrome), or gastrointestinal
          malabsorption. Can also occur in acute or chronic illnesses leading to
          protein loss or reduced synthesis.
        </li>
        <li>
          <strong>Normal Total Protein Levels:</strong> Reflect a healthy
          balance of albumin and globulin, indicating normal nutritional and
          liver function.
        </li>
      </ul>

      {/* Clinical Use Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Clinical Use
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Evaluation of overall health, nutritional status, and liver or kidney
          function.
        </li>
        <li>
          Monitoring chronic illnesses like liver diseases, kidney diseases, or
          immune disorders.
        </li>
        <li>
          Assessment of protein levels as part of liver function tests or
          diagnostic panels for specific diseases.
        </li>
      </ul>

      {/* Additional Notes */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Additional Notes
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Total Protein consists of albumin and globulin fractions. If abnormal,
          further fractionation into albumin and globulin may help identify the
          underlying cause.
        </li>
        <li>
          Temporary variations may occur with hydration status, physical
          activity, or certain medications.
        </li>
      </ul>

      {/* Disclaimer */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Disclaimer</h3>
      <p>
        This test result should be interpreted by a healthcare professional in
        conjunction with the patient’s clinical history and other diagnostic
        findings.
      </p>
    </div>
  );
};

export const bilirubindirect = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      {/* Biological Reference Interval */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Biological Reference Interval (Bio. Ref. Intv.)
      </h3>
      <table className="min-w-full bg-white border border-gray-300 mb-6">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">
              Reference Group
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Reference Range (mg/dL)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Adults</td>
            <td className="border border-gray-300 px-4 py-2">0.0 – 0.3</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Newborns</td>
            <td className="border border-gray-300 px-4 py-2">0.0 – 0.5</td>
          </tr>
        </tbody>
      </table>

      {/* Interpretation Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          <strong>Elevated Direct Bilirubin Levels:</strong> Suggestive of liver
          or biliary obstruction, leading to impaired bilirubin excretion.
          Common conditions include cholestasis, bile duct obstruction,
          gallstones, or liver diseases such as hepatitis or cirrhosis. High
          direct bilirubin can also occur in Dubin-Johnson syndrome or Rotor
          syndrome.
        </li>
        <li>
          <strong>Normal Direct Bilirubin Levels:</strong> Indicate proper liver
          function and normal bile metabolism.
        </li>
        <li>
          <strong>Low Direct Bilirubin Levels:</strong> Rarely clinically
          significant unless total bilirubin is also abnormal.
        </li>
      </ul>

      {/* Clinical Use Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Clinical Use
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Helps differentiate types of jaundice, especially obstructive
          (cholestatic) or liver-related causes.
        </li>
        <li>
          Assists in diagnosing bile duct obstructions or hepatobiliary
          dysfunctions.
        </li>
        <li>Provides insight into liver excretion efficiency.</li>
      </ul>

      {/* Additional Notes */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Additional Notes
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Direct Bilirubin is the conjugated, water-soluble form of bilirubin,
          excreted in bile.
        </li>
        <li>
          When Direct Bilirubin is elevated, it typically reflects a
          post-hepatic cause (e.g., biliary obstruction) or intra-hepatic
          cholestasis.
        </li>
      </ul>

      {/* Disclaimer */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Disclaimer</h3>
      <p>
        This test result should be interpreted by a healthcare professional in
        conjunction with the patient’s clinical history, other diagnostic
        findings, and Total Bilirubin levels.
      </p>
    </div>
  );
};

export const bilirubintotal = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      {/* Biological Reference Interval */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Biological Reference Interval (Bio. Ref. Intv.)
      </h3>
      <table className="min-w-full bg-white border border-gray-300 mb-6">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">
              Reference Group
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Reference Range (mg/dL)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Adults</td>
            <td className="border border-gray-300 px-4 py-2">0.3 – 1.2</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Newborns (0–2 days)
            </td>
            <td className="border border-gray-300 px-4 py-2">1.4 – 8.7</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Newborns (3–5 days)
            </td>
            <td className="border border-gray-300 px-4 py-2">1.5 – 12.0</td>
          </tr>
        </tbody>
      </table>

      {/* Interpretation Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          <strong>Elevated Total Bilirubin Levels (Hyperbilirubinemia):</strong>{" "}
          Indicates liver dysfunction or biliary obstruction. Conditions may
          include hepatitis, cirrhosis, gallstones, or hemolytic anemia.
          Physiological jaundice is common in newborns and typically resolves
          within a few days.
        </li>
        <li>
          <strong>Normal Total Bilirubin Levels:</strong> Reflect proper liver
          function and normal breakdown of red blood cells.
        </li>
        <li>
          <strong>Low Total Bilirubin Levels:</strong> Rarely significant but
          could indicate a reduced breakdown of red blood cells or high fluid
          intake.
        </li>
      </ul>

      {/* Clinical Use Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Clinical Use
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>Assessment of liver function and bile metabolism.</li>
        <li>
          Diagnosis and monitoring of conditions like jaundice, hepatitis, or
          hemolytic disorders.
        </li>
        <li>Evaluating bile duct obstruction or liver damage.</li>
      </ul>

      {/* Additional Notes */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Additional Notes
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Total Bilirubin includes both direct (conjugated) and indirect
          (unconjugated) bilirubin. If Total Bilirubin is elevated, further
          fractionation may help identify the underlying cause.
        </li>
        <li>
          Temporary increases in bilirubin may occur after fasting or strenuous
          exercise.
        </li>
      </ul>

      {/* Disclaimer */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Disclaimer</h3>
      <p>
        This test result should be interpreted by a healthcare professional in
        conjunction with the patient’s clinical history and other diagnostic
        findings.
      </p>
    </div>
  );
};

export const alp = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      {/* Biological Reference Interval */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Biological Reference Interval (Bio. Ref. Intv.)
      </h3>
      <table className="min-w-full bg-white border border-gray-300 mb-6">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">
              Reference Group
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Reference Range (U/L)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Adult Male</td>
            <td className="border border-gray-300 px-4 py-2">40 – 129</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Adult Female</td>
            <td className="border border-gray-300 px-4 py-2">35 – 104</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Children (1–10 years)
            </td>
            <td className="border border-gray-300 px-4 py-2">150 – 380</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Adolescents (11–17 years)
            </td>
            <td className="border border-gray-300 px-4 py-2">130 – 550</td>
          </tr>
        </tbody>
      </table>

      {/* Interpretation Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          <strong>Elevated ALP Levels:</strong> Associated with liver disorders,
          such as bile duct obstruction, liver diseases (hepatitis, cirrhosis),
          or gallstones. May also indicate bone disorders, such as Paget's
          disease, bone metastasis, rickets, or healing fractures. Raised ALP is
          common during growth phases in children and adolescents due to bone
          activity.
        </li>
        <li>
          <strong>Normal ALP Levels:</strong> Indicate proper liver and bone
          health.
        </li>
        <li>
          <strong>Low ALP Levels:</strong> May suggest conditions such as
          malnutrition, hypothyroidism, or specific genetic disorders like
          hypophosphatasia.
        </li>
      </ul>

      {/* Clinical Use Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Clinical Use
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>Evaluation of liver and bile duct health.</li>
        <li>
          Assessment of bone metabolism and disorders, especially in growing
          children or conditions like osteomalacia or fractures.
        </li>
        <li>
          Differentiation between liver and bone origin when evaluated alongside
          GGTP (elevated in liver conditions) or calcium levels.
        </li>
      </ul>

      {/* Additional Notes */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Additional Notes
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Elevated ALP alone is non-specific; correlation with other tests
          (e.g., GGTP, calcium, or phosphate) is essential to identify the
          source (liver vs. bone).
        </li>
        <li>
          Physiological increases in ALP are common in growing children,
          adolescents, and pregnant women (placental ALP).
        </li>
      </ul>

      {/* Disclaimer */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Disclaimer</h3>
      <p>
        This test result should be interpreted by a healthcare professional in
        conjunction with the patient’s clinical history and other diagnostic
        findings.
      </p>
    </div>
  );
};

export const ggtp = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      {/* Biological Reference Interval */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Biological Reference Interval (Bio. Ref. Intv.)
      </h3>
      <table className="min-w-full bg-white border border-gray-300 mb-6">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">
              Reference Group
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Reference Range (U/L)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Adult Male</td>
            <td className="border border-gray-300 px-4 py-2">10 – 71</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Adult Female</td>
            <td className="border border-gray-300 px-4 py-2">6 – 42</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Children (1–17 years)
            </td>
            <td className="border border-gray-300 px-4 py-2">7 – 33</td>
          </tr>
        </tbody>
      </table>

      {/* Interpretation Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          <strong>Elevated GGTP Levels:</strong> Commonly associated with liver
          diseases, such as bile duct obstruction, fatty liver, hepatitis, or
          cirrhosis. May also indicate conditions like chronic alcohol abuse,
          pancreatic disorders, or certain drug toxicities. Elevated levels are
          often used to confirm liver involvement when ALP (Alkaline
          Phosphatase) is also raised.
        </li>
        <li>
          <strong>Normal GGTP Levels:</strong> Reflect proper liver and biliary
          function.
        </li>
        <li>
          <strong>Low GGTP Levels:</strong> Rarely significant but could be
          observed in certain genetic conditions or after specific treatments.
        </li>
      </ul>

      {/* Clinical Use Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Clinical Use
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Assessment of liver and biliary health, especially in detecting bile
          duct obstruction.
        </li>
        <li>
          Differentiation of liver vs. bone origin in elevated alkaline
          phosphatase (ALP) cases.
        </li>
        <li>
          Monitoring of alcohol consumption or damage caused by toxins and
          medications.
        </li>
      </ul>

      {/* Additional Notes */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Additional Notes
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          GGTP is a sensitive marker of liver dysfunction but is not specific to
          a particular condition; further evaluation is often required.
        </li>
        <li>
          Elevated GGTP alone does not necessarily indicate a severe condition
          and must be correlated with other tests like SGPT, SGOT, and ALP.
        </li>
      </ul>

      {/* Disclaimer */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Disclaimer</h3>
      <p>
        This test result should be interpreted by a healthcare professional in
        the context of the patient's clinical history and other laboratory
        findings.
      </p>
    </div>
  );
};

export const sgptalt = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      {/* Biological Reference Interval */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Biological Reference Interval (Bio. Ref. Intv.)
      </h3>
      <table className="min-w-full bg-white border border-gray-300 mb-6">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">
              Reference Group
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Reference Range (U/L)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Adult Male</td>
            <td className="border border-gray-300 px-4 py-2">10 – 40</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Adult Female</td>
            <td className="border border-gray-300 px-4 py-2">7 – 35</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Children</td>
            <td className="border border-gray-300 px-4 py-2">10 – 45</td>
          </tr>
        </tbody>
      </table>

      {/* Interpretation Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          <strong>Elevated SGPT Levels:</strong> Commonly associated with liver
          damage or diseases such as hepatitis, fatty liver, cirrhosis, or
          drug-induced liver injury. May also be elevated in conditions like
          alcohol abuse, bile duct obstruction, or muscle disorders.
        </li>
        <li>
          <strong>Normal SGPT Levels:</strong> Indicate healthy liver function
          and absence of significant liver or muscle injury.
        </li>
        <li>
          <strong>Low SGPT Levels:</strong> Rarely significant but could be
          observed in certain nutritional deficiencies like vitamin B6
          deficiency.
        </li>
      </ul>

      {/* Clinical Use Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Clinical Use
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>Primary marker of liver function and injury.</li>
        <li>
          Monitoring of liver diseases, particularly those caused by alcohol,
          drugs, or hepatitis.
        </li>
        <li>
          Differentiating liver conditions when analyzed alongside SGOT (AST)
          levels.
        </li>
      </ul>

      {/* Additional Notes */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Additional Notes
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>SGPT is more specific to liver injury than SGOT.</li>
        <li>
          Temporary elevation may occur after intense exercise, muscle injuries,
          or certain medications.
        </li>
        <li>
          Evaluate in conjunction with other liver function tests for a complete
          assessment.
        </li>
      </ul>

      {/* Disclaimer */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Disclaimer</h3>
      <p>
        This test result should be interpreted by a healthcare professional in
        conjunction with the patient's medical history and other diagnostic
        findings.
      </p>
    </div>
  );
};

export const ckmb = () => {
  return (
    <div className="mb-8">
      {/* Status Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Status</h3>
      <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
        <thead>
          <tr class="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Category</th>
            <th className="border border-gray-300 px-4 py-2">
              Bio Reference Interval (ng/mL)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Negative</td>
            <td className="border border-gray-300 px-4 py-2">&lt;5</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Positive</td>
            <td className="border border-gray-300 px-4 py-2">&gt;5</td>
          </tr>
        </tbody>
      </table>

      {/* Interpretation Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretations
      </h3>
      <p className="mb-4">
        CK-MB is normally present in minute quantities and is undetectable with
        standard tests. Elevated CK-MB levels, along with symptoms such as chest
        pain, indicate a recent heart attack. Fluctuating levels can suggest a
        second or ongoing heart attack.
      </p>
      <p className="mb-4">
        Increased CK-MB levels may indicate heart or kidney damage. Strenuous
        exercise can also elevate this enzyme.
      </p>

      {/* Comments Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Comments</h3>
      <p className="mb-4">
        CPK-MB is a cardiac marker aiding in the diagnosis of Acute Myocardial
        Infarction (AMI). Levels rise 3-5 hours after infarction onset, peak at
        16-20 hours, and normalize within 48-72 hours. Sensitivity depends on
        the timing of sample collection; samples are recommended at 0, 3, and 6
        hours after chest pain onset.
      </p>
      <p className="mb-4">
        A predictable decline in CPK-MB levels occurs. A sudden increase during
        this decline suggests re-infarction.
      </p>
      <p className="mb-4">
        <strong>Increased Levels:</strong> Acute Myocardial Infarction,
        Myocarditis, Rhabdomyolysis, Stroke, and Electric Cardioversion.
      </p>
    </div>
  );
};

//

//Others
export const typhoid = () => {
  return (
    <div className="mx-auto bg-white shadow-md rounded-lg">
      {/* Detailed Biological Reference Interval */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Detailed Biological Reference Interval (Bio. Ref. Intv.):
      </h2>
      <div className="mb-4">
        <p>
          <strong>IgM (Immunoglobulin M):</strong>
        </p>
        <ul className="list-disc pl-6 mb-3">
          <li>
            <strong>Negative:</strong> No active infection.
          </li>
          <li>
            <strong>Positive:</strong> Suggests a current or recent infection
            with Salmonella typhi or Salmonella paratyphi.
          </li>
        </ul>
        <p>
          <strong>IgG (Immunoglobulin G):</strong>
        </p>
        <ul className="list-disc pl-6 mb-3">
          <li>
            <strong>Negative:</strong> No past exposure or immunity.
          </li>
          <li>
            <strong>Positive:</strong> Indicates past infection or possible
            immunity from prior exposure or vaccination.
          </li>
        </ul>
      </div>

      {/* Interpretation */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Interpretation:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          <strong>Positive IgM and Negative IgG:</strong> Indicates a recent or
          active typhoid infection.
        </li>
        <li>
          <strong>Positive IgM and Positive IgG:</strong> Indicates ongoing
          infection with past exposure or recurrent infection.
        </li>
        <li>
          <strong>Negative IgM and Positive IgG:</strong> Suggests past
          infection or immunity due to prior exposure or vaccination. No active
          infection.
        </li>
        <li>
          <strong>Negative IgM and Negative IgG:</strong> No evidence of current
          or past infection with Salmonella typhi.
        </li>
      </ul>

      {/* Clinical Use */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Clinical Use:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>Diagnosis of typhoid fever during suspected acute infection.</li>
        <li>
          Differentiates between current/recent infection (IgM) and past
          exposure or immunity (IgG).
        </li>
        <li>Used as a screening tool in endemic areas or during outbreaks.</li>
      </ul>

      {/* Additional Notes */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Additional Notes:
      </h2>
      <p className="mb-4">
        This is a qualitative test, and results should be confirmed with
        additional diagnostic tools such as blood culture or Widal test for
        accurate diagnosis. Cross-reactivity with other febrile illnesses (e.g.,
        dengue, malaria) may lead to false positives.
      </p>

      {/* Disclaimer */}
      <div className="border-t-2 border-gray-300 my-4"></div>
      <p className="text-center text-gray-500 text-sm">
        This report should be interpreted by a qualified healthcare professional
        in conjunction with the patient's medical history and other diagnostic
        findings.
      </p>
    </div>
  );
};

// export const hemoglobin = () => {
//   return (
//     <div className="mx-auto bg-white shadow-md p-6 rounded-lg">
//       <h1 className="text-2xl font-bold text-purple-600 mb-4">
//         Hemoglobin Test Results
//       </h1>

//       {/* Test Results Table */}
//       <table className="min-w-full border-collapse border border-gray-300 mb-6">
//         <thead>
//           <tr className="bg-purple-100 text-purple-700">
//             <th className="border border-gray-300 px-4 py-2 text-left">
//               Test Name
//             </th>
//             <th className="border border-gray-300 px-4 py-2 text-left">
//               Results
//             </th>
//             <th className="border border-gray-300 px-4 py-2 text-left">Unit</th>
//             <th className="border border-gray-300 px-4 py-2 text-left">
//               Bio. Ref. Intv.
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">Hemoglobin</td>
//             <td className="border border-gray-300 px-4 py-2">13.6</td>
//             <td className="border border-gray-300 px-4 py-2">g/dL</td>
//             <td className="border border-gray-300 px-4 py-2">&lt;100</td>
//           </tr>
//         </tbody>
//       </table>

//       {/* Detailed Biological Reference Interval */}
//       <h2 className="text-xl font-semibold text-purple-700 mb-3">
//         Biological Reference Interval (Bio. Ref. Intv.):
//       </h2>
//       <table className="min-w-full border-collapse border border-gray-300 mb-6">
//         <thead>
//           <tr className="bg-purple-100 text-purple-700">
//             <th className="border border-gray-300 px-4 py-2 text-left">
//               Reference Group
//             </th>
//             <th className="border border-gray-300 px-4 py-2 text-left">
//               Reference Range (g/dL)
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">Adult Male</td>
//             <td className="border border-gray-300 px-4 py-2">13.0 – 17.0</td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">Adult Female</td>
//             <td className="border border-gray-300 px-4 py-2">12.0 – 15.5</td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">Pregnant Women</td>
//             <td className="border border-gray-300 px-4 py-2">11.0 – 14.0</td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               Children (6–12 years)
//             </td>
//             <td className="border border-gray-300 px-4 py-2">11.5 – 15.5</td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               Children (1–6 years)
//             </td>
//             <td className="border border-gray-300 px-4 py-2">10.5 – 14.0</td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               Infants (0.5–1 year)
//             </td>
//             <td className="border border-gray-300 px-4 py-2">11.0 – 13.5</td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               Newborns (0–0.5 months)
//             </td>
//             <td className="border border-gray-300 px-4 py-2">14.0 – 24.0</td>
//           </tr>
//         </tbody>
//       </table>

//       <h3 className="text-lg font-semibold text-[#845BB3]">Note</h3>
//       <ul className="list-disc list-inside">
//         <li>
//           Inadequate collection and handling may affect the results adversely.
//         </li>
//         <li>Humidity and temperature may affect the test results adversely.</li>
//         <li>
//           A negative result despite the presence of symptoms doesn’t rule out
//           the presence of infection but suggests the presence is under
//           detectable limits.
//         </li>
//       </ul>
//     </div>
//   );
// };

export const hemoglobin = () => {
  return (
    <div className="mx-auto bg-white shadow-md p-6 rounded-lg">
      <h2 className="text-xl font-semibold text-purple-700 mb-3">
        Biological Reference Interval:
      </h2>

      <table className="w-full border-collapse border border-purple-300 mb-6">
        <thead>
          <tr className="bg-purple-100 text-purple-700">
            <th className="border border-gray-300 px-4 py-2 text-left">
              Reference Group
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left">
              Reference Range (g/dL)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Adult Male</td>
            <td className="border border-gray-300 px-4 py-2">13.0 – 17.0</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Adult Female</td>
            <td className="border border-gray-300 px-4 py-2">12.0 – 15.5</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Pregnant Women</td>
            <td className="border border-gray-300 px-4 py-2">11.0 – 14.0</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Children (6–12 years)
            </td>
            <td className="border border-gray-300 px-4 py-2">11.5 – 15.5</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Children (1–6 years)
            </td>
            <td className="border border-gray-300 px-4 py-2">10.5 – 14.0</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Infants (0.5–1 year)
            </td>
            <td className="border border-gray-300 px-4 py-2">11.0 – 13.5</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Newborns (0–0.5 months)
            </td>
            <td className="border border-gray-300 px-4 py-2">14.0 – 24.0</td>
          </tr>
        </tbody>
      </table>

      {/* Interpretation */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Interpretation:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          <strong>Low Hemoglobin (Anemia):</strong> May indicate conditions such
          as iron deficiency, vitamin B12 or folate deficiency, chronic
          diseases, or bone marrow disorders.
        </li>
        <li>
          <strong>High Hemoglobin (Polycythemia):</strong> Could suggest
          dehydration, lung diseases, or disorders such as polycythemia vera.
        </li>
        <li>
          <strong>Normal Hemoglobin Levels:</strong> Reflect adequate
          oxygen-carrying capacity of the blood.
        </li>
      </ul>

      {/* Clinical Use */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Clinical Use:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>Screening and diagnosis of anemia or polycythemia.</li>
        <li>
          Monitoring hemoglobin levels during pregnancy or after blood loss.
        </li>
        <li>
          Evaluating treatment response in conditions affecting hemoglobin
          levels.
        </li>
      </ul>

      {/* Additional Notes */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Additional Notes:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          Hemoglobin levels may vary with age, sex, altitude, and hydration
          status.
        </li>
        <li>
          For accurate results, ensure the patient is hydrated, and any
          interfering conditions are noted before testing.
        </li>
      </ul>

      {/* Disclaimer */}
      <div className="border-t-2 border-gray-300 my-4"></div>
      <p className="text-center text-gray-500 text-sm">
        This report should be interpreted by a qualified healthcare professional
        in conjunction with the patient's medical history and other diagnostic
        findings.
      </p>
    </div>
  );
};

export const streptococcusaag = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">
        SGOT (Serum Glutamic-Oxaloacetic Transaminase) Test Report
      </h2>

      {/* Test Results Table */}
      {/*<table className="min-w-full bg-white border border-gray-300 mb-6">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">Test Name</th>
            <th className="border border-gray-300 px-4 py-2">Results</th>
            <th className="border border-gray-300 px-4 py-2">Unit</th>
            <th className="border border-gray-300 px-4 py-2">
              Bio. Ref. Intv.
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              SGOT (Serum Glutamic-Oxaloacetic Transaminase)
              <br />
              <span className="text-sm text-gray-500">
                (Also known as AST - Aspartate Aminotransferase)
              </span>
            </td>
            <td className="border border-gray-300 px-4 py-2">30</td>
            <td className="border border-gray-300 px-4 py-2">U/L</td>
            <td className="border border-gray-300 px-4 py-2">10 – 40</td>
          </tr>
        </tbody>
      </table>

      {/* Biological Reference Interval */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Biological Reference Interval (Bio. Ref. Intv.)
      </h3>
      <table className="min-w-full bg-white border border-gray-300 mb-6">
        <thead>
          <tr className="bg-[#D7D4F8] text-[#845BB3]">
            <th className="border border-gray-300 px-4 py-2">
              Reference Group
            </th>
            <th className="border border-gray-300 px-4 py-2">
              Reference Range (U/L)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Adult Male</td>
            <td className="border border-gray-300 px-4 py-2">10 – 40</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Adult Female</td>
            <td className="border border-gray-300 px-4 py-2">10 – 35</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Children</td>
            <td className="border border-gray-300 px-4 py-2">10 – 50</td>
          </tr>
        </tbody>
      </table>

      {/* Interpretation Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Interpretation
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          <strong>Elevated SGOT Levels:</strong> Could indicate liver damage or
          diseases such as hepatitis, cirrhosis, or fatty liver. May also be
          elevated in conditions like myocardial infarction, muscle injuries, or
          pancreatitis.
        </li>
        <li>
          <strong>Normal SGOT Levels:</strong> Reflect proper liver function and
          absence of significant muscle or heart damage.
        </li>
        <li>
          <strong>Low SGOT Levels:</strong> Rarely clinically significant but
          could be associated with vitamin B6 deficiency.
        </li>
      </ul>

      {/* Clinical Use Section */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Clinical Use
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>Assessment of liver health and function.</li>
        <li>
          Diagnosis and monitoring of liver diseases and muscle disorders.
        </li>
        <li>
          Differentiation of liver versus non-liver sources of elevated enzymes
          (when used with SGPT/ALT).
        </li>
      </ul>

      {/* Additional Notes */}
      <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
        Additional Notes
      </h3>
      <ul className="list-disc ml-6 mb-4">
        <li>
          Always evaluate SGOT levels alongside SGPT (ALT) and other liver
          function tests for a comprehensive assessment.
        </li>
        <li>
          Temporary elevation of SGOT can occur after intense physical activity
          or muscle injuries.
        </li>
      </ul>

      {/* Disclaimer */}
      <div className="border-t-2 border-gray-300 my-4"></div>
      <p className="text-center text-gray-500 text-sm">
        This report should be interpreted by a qualified healthcare professional
        in conjunction with the patient's medical history and other diagnostic
        findings.
      </p>
    </div>
  );
};

// export const bloodmarkers = () => {
//   return (
//     <div className="p-6 bg-white rounded-lg shadow-md">
//       <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">
//         CBC Test Report
//       </h2>

//       {/* Test Results Table */}
//       <table className="min-w-full bg-white border border-gray-300 mb-6">
//         <thead>
//           <tr className="bg-[#D7D4F8] text-[#845BB3]">
//             <th className="border border-gray-300 px-4 py-2">Test Name</th>
//             <th className="border border-gray-300 px-4 py-2">Results</th>
//             <th className="border border-gray-300 px-4 py-2">Unit</th>
//             <th className="border border-gray-300 px-4 py-2">
//               Bio. Ref. Intv.
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               Total Leucocyte Count
//             </td>
//             <td className="border border-gray-300 px-4 py-2">4.5 - 10.5</td>
//             <td className="border border-gray-300 px-4 py-2">10^3/uL</td>
//             <td className="border border-gray-300 px-4 py-2">
//               Male: 4.5 - 10.5, Female: 4.5 - 10.5
//             </td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">RBC Count</td>
//             <td className="border border-gray-300 px-4 py-2">4.7 - 6.1</td>
//             <td className="border border-gray-300 px-4 py-2">mill/uL</td>
//             <td className="border border-gray-300 px-4 py-2">
//               Male: 4.7 - 6.1, Female: 4.2 - 5.4
//             </td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">Hemoglobin</td>
//             <td className="border border-gray-300 px-4 py-2">13.5 - 17.5</td>
//             <td className="border border-gray-300 px-4 py-2">g/dL</td>
//             <td className="border border-gray-300 px-4 py-2">
//               Male: 13.5 - 17.5, Female: 12.0 - 15.5
//             </td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">Hematocrit</td>
//             <td className="border border-gray-300 px-4 py-2">41.0 - 50.0</td>
//             <td className="border border-gray-300 px-4 py-2">%</td>
//             <td className="border border-gray-300 px-4 py-2">
//               Male: 41.0 - 50.0, Female: 36.0 - 48.0
//             </td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               Mean Corpuscular Volume
//             </td>
//             <td className="border border-gray-300 px-4 py-2">80.0 - 100.0</td>
//             <td className="border border-gray-300 px-4 py-2">fL</td>
//             <td className="border border-gray-300 px-4 py-2">
//               Male & Female: 80.0 - 100.0
//             </td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               Mean Corpuscular Hemoglobin
//             </td>
//             <td className="border border-gray-300 px-4 py-2">27.5 - 33.2</td>
//             <td className="border border-gray-300 px-4 py-2">pg</td>
//             <td className="border border-gray-300 px-4 py-2">
//               Male & Female: 27.5 - 33.2
//             </td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               Mean Corpuscular Hemoglobin Concentration
//             </td>
//             <td className="border border-gray-300 px-4 py-2">33.4 - 35.5</td>
//             <td className="border border-gray-300 px-4 py-2">g/dL</td>
//             <td className="border border-gray-300 px-4 py-2">
//               Male & Female: 33.4 - 35.5
//             </td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               Red Blood Cell Distribution Width - CV
//             </td>
//             <td className="border border-gray-300 px-4 py-2">11.5 - 15.4</td>
//             <td className="border border-gray-300 px-4 py-2">%</td>
//             <td className="border border-gray-300 px-4 py-2">
//               Male & Female: 11.5 - 15.4
//             </td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               Red Blood Cell Distribution Width - SD
//             </td>
//             <td className="border border-gray-300 px-4 py-2">35.0 - 56.0</td>
//             <td className="border border-gray-300 px-4 py-2">fL</td>
//             <td className="border border-gray-300 px-4 py-2">
//               Male & Female: 35.0 - 56.0
//             </td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">Platelet Count</td>
//             <td className="border border-gray-300 px-4 py-2">150.0 - 450.0</td>
//             <td className="border border-gray-300 px-4 py-2">10^3/uL</td>
//             <td className="border border-gray-300 px-4 py-2">
//               Male & Female: 150.0 - 450.0
//             </td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               Mean Platelet Volume
//             </td>
//             <td className="border border-gray-300 px-4 py-2">8.6 - 15.5</td>
//             <td className="border border-gray-300 px-4 py-2">fL</td>
//             <td className="border border-gray-300 px-4 py-2">
//               Male & Female: 8.6 - 15.5
//             </td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">Plateletcrit</td>
//             <td className="border border-gray-300 px-4 py-2">0.20 - 0.36</td>
//             <td className="border border-gray-300 px-4 py-2">%</td>
//             <td className="border border-gray-300 px-4 py-2">
//               Male & Female: 0.20 - 0.36
//             </td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               Platelet Distribution Width - CV
//             </td>
//             <td className="border border-gray-300 px-4 py-2">8.1 - 20.0</td>
//             <td className="border border-gray-300 px-4 py-2">%</td>
//             <td className="border border-gray-300 px-4 py-2">
//               Male & Female: 8.1 - 20.0
//             </td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               Platelet Distribution Width - SD
//             </td>
//             <td className="border border-gray-300 px-4 py-2">9.0 - 17.0</td>
//             <td className="border border-gray-300 px-4 py-2">fL</td>
//             <td className="border border-gray-300 px-4 py-2">
//               Male & Female: 9.0 - 17.0
//             </td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               Platelet - Large Cell Ratio
//             </td>
//             <td className="border border-gray-300 px-4 py-2">11.0 - 45.0</td>
//             <td className="border border-gray-300 px-4 py-2">%</td>
//             <td className="border border-gray-300 px-4 py-2">
//               Male & Female: 11.0 - 45.0
//             </td>
//           </tr>
//         </tbody>
//       </table>
//       <h3 className="text-lg font-semibold text-[#845BB3] mb-4">Notes:</h3>
//       <p className="text-[#845BB3]mb-6">
//         A complete blood count (CBC) is a blood test used to evaluate your
//         overall health and detect a wide range of disorders, including anemia,
//         infection, and leukemia. It measures components like red and white blood
//         cells, hemoglobin, hematocrit, and platelets. Abnormal increases or
//         decreases in cell counts may indicate underlying medical conditions that
//         need further evaluation.
//       </p>

//       {/* Detailed Notes */}
//       <h3 className="text-lg font-semibold text-[#845BB3] mb-4">
//         Detailed Notes:
//       </h3>
//       <ul className="list-disc pl-6 mb-6">
//         <li>
//           <span className="font-semibold">Red Blood Cells:</span> Carry oxygen
//           throughout the body.
//         </li>
//         <li>
//           <span className="font-semibold">White Blood Cells:</span> Fight
//           infections.
//         </li>
//         <li>
//           <span className="font-semibold">Hemoglobin:</span> Oxygen-carrying
//           protein in red blood cells.
//         </li>
//         <li>
//           <span className="font-semibold">Hematocrit:</span> Proportion of red
//           blood cells to plasma in the blood.
//         </li>
//         <li>
//           <span className="font-semibold">Platelets:</span> Help with blood
//           clotting.
//         </li>
//       </ul>
//     </div>
//   );
// };

// export const haemoglobin = () => {
//   return (
//     <div className="p-6 bg-white rounded-lg shadow-md">
//       <h2 className="text-2xl font-semibold text-[#845BB3] mb-4">
//         Haemoglobin Test Report
//       </h2>

//       {/* Test Results Table */}
//       <table className="min-w-full bg-white border border-gray-300 mb-6">
//         <thead>
//           <tr className="bg-[#D7D4F8] text-[#845BB3]">
//             <th className="border border-gray-300 px-4 py-2">Test Name</th>
//             <th className="border border-gray-300 px-4 py-2">Results</th>
//             <th className="border border-gray-300 px-4 py-2">Unit</th>
//             <th className="border border-gray-300 px-4 py-2">
//               Bio. Ref. Intv.
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">Haemoglobin</td>
//             <td className="border border-gray-300 px-4 py-2">13.6</td>
//             <td className="border border-gray-300 px-4 py-2">g/dL</td>
//             <td className="border border-gray-300 px-4 py-2">&lt;100</td>
//           </tr>
//         </tbody>
//       </table>

//       {/* Biological Reference Interval */}
//       <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
//         Biological Reference Interval (Bio. Ref. Intv.)
//       </h3>
//       <table className="min-w-full bg-white border border-gray-300 mb-6">
//         <thead>
//           <tr className="bg-[#D7D4F8] text-[#845BB3]">
//             <th className="border border-gray-300 px-4 py-2">
//               Reference Group
//             </th>
//             <th className="border border-gray-300 px-4 py-2">
//               Reference Range (g/dL)
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">Adult Male</td>
//             <td className="border border-gray-300 px-4 py-2">13.0 – 17.0</td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">Adult Female</td>
//             <td className="border border-gray-300 px-4 py-2">12.0 – 15.5</td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">Pregnant Women</td>
//             <td className="border border-gray-300 px-4 py-2">11.0 – 14.0</td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               Children (6–12 years)
//             </td>
//             <td className="border border-gray-300 px-4 py-2">11.5 – 15.5</td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               Children (1–6 years)
//             </td>
//             <td className="border border-gray-300 px-4 py-2">10.5 – 14.0</td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               Infants (0.5–1 year)
//             </td>
//             <td className="border border-gray-300 px-4 py-2">11.0 – 13.5</td>
//           </tr>
//           <tr>
//             <td className="border border-gray-300 px-4 py-2">
//               Newborns (0–0.5 months)
//             </td>
//             <td className="border border-gray-300 px-4 py-2">14.0 – 24.0</td>
//           </tr>
//         </tbody>
//       </table>

//       {/* Interpretation Section */}
//       <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
//         Interpretation
//       </h3>
//       <ul className="list-disc ml-6 mb-4">
//         <li>
//           <strong>Low Haemoglobin (Anemia):</strong> May indicate conditions
//           such as iron deficiency, vitamin B12 or folate deficiency, chronic
//           diseases, or bone marrow disorders.
//         </li>
//         <li>
//           <strong>High Haemoglobin (Polycythemia):</strong> Could suggest
//           dehydration, lung diseases, or disorders such as polycythemia vera.
//         </li>
//         <li>
//           <strong>Normal Haemoglobin Levels:</strong> Reflect adequate
//           oxygen-carrying capacity of the blood.
//         </li>
//       </ul>

//       {/* Clinical Use Section */}
//       <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
//         Clinical Use
//       </h3>
//       <ul className="list-disc ml-6 mb-4">
//         <li>Screening and diagnosis of anemia or polycythemia.</li>
//         <li>
//           Monitoring Haemoglobin levels during pregnancy or after blood loss.
//         </li>
//         <li>
//           Evaluating treatment response in conditions affecting Haemoglobin
//           levels.
//         </li>
//       </ul>

//       {/* Additional Notes */}
//       <h3 className="text-lg font-semibold text-[#845BB3] mb-2">
//         Additional Notes
//       </h3>
//       <ul className="list-disc ml-6 mb-4">
//         <li>
//           Haemoglobin levels may vary with age, sex, altitude, and hydration
//           status.
//         </li>
//         <li>
//           For accurate results, ensure the patient is hydrated, and any
//           interfering conditions are noted before testing.
//         </li>
//       </ul>

//       {/* Disclaimer */}
//       <h3 className="text-lg font-semibold text-[#845BB3] mb-2">Disclaimer</h3>
//       <p>
//         This report should be interpreted by a qualified healthcare professional
//         in conjunction with the patient's medical history and other diagnostic
//         findings.
//       </p>
//     </div>
//   );
// };
//
export const urinemicroalbumin = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      {/* Interpretation */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Interpretation:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          <strong>Low Hemoglobin (Anemia):</strong> May indicate conditions such
          as iron deficiency, vitamin B12 or folate deficiency, chronic
          diseases, or bone marrow disorders.
        </li>
        <li>
          <strong>High Hemoglobin (Polycythemia):</strong> Could suggest
          dehydration, lung diseases, or disorders such as polycythemia vera.
        </li>
        <li>
          <strong>Normal Hemoglobin Levels:</strong> Reflect adequate
          oxygen-carrying capacity of the blood.
        </li>
      </ul>

      {/* Clinical Use */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Clinical Use:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>Screening and diagnosis of anemia or polycythemia.</li>
        <li>
          Monitoring hemoglobin levels during pregnancy or after blood loss.
        </li>
        <li>
          Evaluating treatment response in conditions affecting hemoglobin
          levels.
        </li>
      </ul>

      {/* Additional Notes */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Additional Notes:
      </h2>
      <p className="mb-4">
        Hemoglobin levels may vary with age, sex, altitude, and hydration
        status. For accurate results, ensure the patient is hydrated, and any
        interfering conditions are noted before testing.
      </p>

      {/* Disclaimer */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">Disclaimer:</h2>
      <p>
        This report should be interpreted by a qualified healthcare professional
        in conjunction with the patient's medical history and other diagnostic
        findings.
      </p>
    </div>
  );
};

export const bloodpressure = () => {
  return (
    <div className="mx-auto bg-white shadow-md p-6 rounded-lg">
      {/* Summary */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">Summary:</h2>
      <p className="mb-4">
        Blood pressure is the force applied by the blood over the inner walls of
        the arteries. Although the average blood pressure for a person remains
        constant, it shows minor fluctuations throughout the day—declining while
        relaxing and momentarily increasing while being excited or under stress.
        An increase in the resting blood pressure can scar, stiffen, or harden
        the arteries. Blood pressure is written as systolic and diastolic
        values. Hence, BP 120/80 mm Hg means 120 is the systolic number, and 80
        is the diastolic number.
      </p>

      {/* Blood Pressure Category */}
      <h2 className="text-xl font-semibold text-purple-700 mb-3">
        Blood Pressure Category:
      </h2>
      <table className="min-w-full border-collapse border border-gray-300 mb-6">
        <thead>
          <tr className="bg-purple-100 text-purple-700">
            <th className="border border-gray-300 px-4 py-2 text-left">
              Category
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left">
              Systolic (mm Hg)
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left">
              Diastolic (mm Hg)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Low</td>
            <td className="border border-gray-300 px-4 py-2">&lt;90</td>
            <td className="border border-gray-300 px-4 py-2">&lt;50</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Normal</td>
            <td className="border border-gray-300 px-4 py-2">&lt;120</td>
            <td className="border border-gray-300 px-4 py-2">&lt;80</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Pre Hypertension
            </td>
            <td className="border border-gray-300 px-4 py-2">120 – 139</td>
            <td className="border border-gray-300 px-4 py-2">80 – 89</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              High Blood Pressure (Hypertension) Stage 1
            </td>
            <td className="border border-gray-300 px-4 py-2">140 – 159</td>
            <td className="border border-gray-300 px-4 py-2">90 – 99</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              High Blood Pressure (Hypertension) Stage 2
            </td>
            <td className="border border-gray-300 px-4 py-2">160 – 179</td>
            <td className="border border-gray-300 px-4 py-2">100 – 110</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Hypertensive Crisis
            </td>
            <td className="border border-gray-300 px-4 py-2">&gt;180</td>
            <td className="border border-gray-300 px-4 py-2">&gt;110</td>
          </tr>
        </tbody>
      </table>

      {/* Increased Blood Pressure */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Increased Blood Pressure:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>Heart Attack</li>
        <li>Stroke</li>
        <li>Vision Loss</li>
        <li>Heart Failure</li>
        <li>Kidney Failure</li>
        <li>Dementia</li>
        <li>Erectile Dysfunction</li>
      </ul>

      {/* Low Blood Pressure */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Low Blood Pressure:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>Dizziness</li>
        <li>Loss of consciousness</li>
        <li>Weakness</li>
        <li>Sharp and sustained drop may result in Hypoxia-like state</li>
      </ul>
    </div>
  );
};

export const temperature = () => {
  return (
    <div className="mx-auto bg-white shadow-md p-6 rounded-lg">
      {/* Summary */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">Summary:</h2>
      <p className="mb-4">
        The Body Temperature test measures the body's thermal state, which is a
        key indicator of overall health and metabolic activity. It helps
        identify fever, hypothermia, or other conditions affecting
        thermoregulation.
      </p>

      {/* Reference Range */}
      <h2 className="text-xl font-semibold text-purple-700 mb-3">
        Reference Range:
      </h2>
      <p className="mb-4">
        Normal body temperature is typically between 36.1°C and 37.2°C (97°F to
        99°F). Temperatures above 37.5°C (99.5°F) may indicate fever, while
        readings below 35°C (95°F) may suggest hypothermia.
      </p>

      {/* Significance */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Significance:
      </h2>
      <p className="mb-4">
        Abnormal body temperature can be a sign of infections, inflammatory
        conditions, or environmental factors. Timely detection aids in
        diagnosing and managing potential health issues effectively.
      </p>

      {/* Disclaimer */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">Disclaimer:</h2>
      <p>
        This test result is for informational purposes only. Consult a qualified
        healthcare professional for an accurate diagnosis and treatment plan.
      </p>
    </div>
  );
};

export const spo2 = () => {
  return (
    <div className="mx-auto bg-white shadow-md p-6 rounded-lg">
      <h1 className="text-2xl font-bold text-purple-600 mb-4">
        Blood Oxygen Saturation Test Results
      </h1>

      {/* Test Results Table */}
      {/*<table className="min-w-full border-collapse border border-gray-300 mb-6">
        <thead>
          <tr className="bg-purple-100 text-purple-700">
            <th className="border border-gray-300 px-4 py-2 text-left">
              Test Name
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left">
              Results
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left">Unit</th>
            <th className="border border-gray-300 px-4 py-2 text-left">
              Bio. Ref. Intv.
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Blood Oxygen Saturation
            </td>
            <td className="border border-gray-300 px-4 py-2">97</td>
            <td className="border border-gray-300 px-4 py-2">%</td>
            <td className="border border-gray-300 px-4 py-2">&gt;95</td>
          </tr>
        </tbody>
      </table>

      {/* Summary */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">Summary:</h2>
      <p className="mb-4">
        The Blood Oxygen Saturation (SpO₂) test measures the percentage of
        oxygen-saturated hemoglobin in the blood. This test is a critical
        indicator of respiratory and circulatory efficiency, commonly used to
        assess oxygen levels in individuals with conditions such as respiratory
        infections, chronic obstructive pulmonary disease (COPD), or
        cardiovascular issues.
      </p>

      {/* Reference Range */}
      <h2 className="text-xl font-semibold text-purple-700 mb-3">
        Reference Range:
      </h2>
      <p className="mb-4">
        Normal SpO₂ levels are typically between 95% and 100%. Levels below 90%
        may indicate hypoxemia, warranting medical attention.
      </p>

      {/* Significance */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Significance:
      </h2>
      <p className="mb-4">
        Maintaining optimal oxygen levels is vital for cellular function and
        overall health. Deviations from the normal range can indicate underlying
        conditions requiring further evaluation or intervention.
      </p>

      {/* Disclaimer */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">Disclaimer:</h2>
      <p>
        This test was conducted in compliance with NABL standards to ensure
        accuracy and reliability.
      </p>
    </div>
  );
};

export const ecg = () => {
  return (
    <div className="mx-auto bg-white shadow-md p-6 rounded-lg">
      <h1 className="text-2xl font-bold text-purple-600 mb-4">
        Electrocardiogram (ECG) Test Results
      </h1>

      {/* Test Results Table */}
      {/*<table className="min-w-full border-collapse border border-gray-300 mb-6">
        <thead>
          <tr className="bg-purple-100 text-purple-700">
            <th className="border border-gray-300 px-4 py-2 text-left">
              Test Name
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left">
              Results
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left">Unit</th>
            <th className="border border-gray-300 px-4 py-2 text-left">
              Bio. Ref. Intv.
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">
              Electrocardiogram
            </td>
            <td className="border border-gray-300 px-4 py-2">Below</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
            <td className="border border-gray-300 px-4 py-2">-</td>
          </tr>
        </tbody>
      </table>

      {/* Summary */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">Summary:</h2>
      <p className="mb-4">
        The Electrocardiogram (ECG) test records the electrical activity of the
        heart to evaluate its rhythm, rate, and overall function. It is a vital
        diagnostic tool for detecting arrhythmias, ischemia, or other cardiac
        abnormalities.
      </p>

      {/* Reference Parameters */}
      <h2 className="text-xl font-semibold text-purple-700 mb-3">
        Reference Parameters:
      </h2>
      <ul className="list-disc pl-6 mb-4">
        <li>
          <strong>Heart Rate:</strong> Normal range is 60-100 beats per minute
          (bpm).
        </li>
        <li>
          <strong>Rhythm:</strong> Normal sinus rhythm indicates regular
          electrical activity.
        </li>

        <li>
          <strong>Intervals:</strong> PR, QRS, and QT intervals are evaluated
          for abnormalities.
        </li>
      </ul>

      {/* Significance */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">
        Significance:
      </h2>
      <p className="mb-4">
        An ECG can help diagnose heart conditions such as arrhythmias,
        myocardial infarction (heart attack), or hypertrophy. It is also used
        for routine cardiac screening and monitoring.
      </p>

      {/* Disclaimer */}
      <h2 className="text-xl font-semibold text-[#845BB3] mb-3">Disclaimer:</h2>
      <p>
        This report is intended for informational purposes only. Please consult
        a healthcare professional for an accurate interpretation of the results
        and appropriate medical care.
      </p>
    </div>
  );
};
