import React, { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import help from "../../assets/help.svg";
import axios from "axios";
import sidevector from "../../assets/sidevector.svg";
import download from "../../assets/downloadpdf.svg";
import share from "../../assets/sharepdf.svg";
import whatsapp from "../../assets/whatsapp.svg";
import PatientReport from "../../Components/PatientReport";
import ReactToPrint from "react-to-print";
import html2pdf from "html2pdf.js";
import { useSelector } from "react-redux";
import { commentsData } from "../../translate/testInstruction";
import { samplelipidprofile } from "../../translate/testSummery";

export default function ReportPage() {
  // Access patient and test data from state
  const patientDetails = useSelector((state) => state.patientSlice.patientData);

  console.log("______________ patientDetails", patientDetails);
  const updatedTestData = useSelector((state) => state.testSlice.testsData);

  const testData = updatedTestData.map((item) => {
    const updatedFields = item.fields.flatMap(
      (arr) =>
        arr.subFields && arr.subFields.length > 0 ? arr.subFields : [arr] // Include subFields if they exist, otherwise include parent
    );
    return {
      ...item,
      fields: updatedFields,
    };
  });

  console.log("Updated Test Data with Conditional Flattening:", testData);

  // Now you can log the updated data
  // console.log("Updated Test Data:", testData);
  // console.log("Original Test Data:", testData);

  const testNames = [];
  commentsData.forEach((item) => {
    Object.keys(item).forEach((testName) => {
      const formattedTestName = testName
        .toLowerCase()
        .replace(/[^a-z0-9]/g, "");
      testNames.push(formattedTestName);
    });
  });
  console.log("_testNames = ", testNames);
  const processedTestData = [];

  if (testData.length > 0) {
    const testFieldsCount = testData[0].fields.length;
    console.log("Number of fields in the first test:", testFieldsCount);

    const testName = [];
    const fieldName = [];

    // Iterate over testData
    for (let i = 0; i < testData.length; i++) {
      let item1 = testData[i];

      // Check if fields array exists and has length > 0
      console.log("63 ---item1", item1);
      if (item1.fields.length > 0) {
        for (let j = 0; j < item1.fields.length; j++) {
          const item2 = item1.fields[j];
          console.log("item1---", item1);
          if (item1.fields[j].secfieldName) {
            console.log("secFieldName");
            const tname = item2.secfieldName;
            // console.log("tname", tname);

            testName.push(tname);

            // Check if tname exists in testNamesArray
            if (testNames.includes(tname)) {
              let index = item1.fields.findIndex(
                (obj) =>
                  obj.fieldName.toLowerCase().replace(/[^a-z0-9]/g, "") ===
                  tname
              );

              const shallowCopyItem = { ...item1 };
              console.log("84----shallowCopy", shallowCopyItem);

              const filteredFields = shallowCopyItem.fields.filter(
                (_, idx) => idx === index
              );
              processedTestData.push(shallowCopyItem);

              shallowCopyItem.fields = filteredFields;

              console.log("92 --- processData");

              if (index !== -1) {
                console.log(typeof item1);
                const updatedItem = {
                  ...item1,
                  fields: [
                    ...item1.fields.slice(0, index),
                    ...item1.fields.slice(index + 1),
                  ],
                };
                j--;
                item1 = updatedItem;
                console.log("item1 =", item1);
                console.log("Updated fields after removal:", updatedItem);
              }
            }
            const item1Size = item1.fields?.length ?? 0;
            if (item1Size <= 10 && item1Size > 0) {
              processedTestData.push(item1);
            }

            const fname = item2.fieldName.toLowerCase().replace(/\s+/g, "");
            fieldName.push(fname);
          } else {
            console.log("no secFieldName");
            const tname = item2.fieldName
              .toLowerCase()
              .replace(/[^a-z0-9]/g, "");
            // console.log("tname", tname);

            testName.push(tname);
            console.log("Final testName array:", testName);
            // Check if tname exists in testNamesArray
            if (testNames.includes(tname)) {
              let index = item1.fields.findIndex(
                (obj) =>
                  obj.fieldName.toLowerCase().replace(/[^a-z0-9]/g, "") ===
                  tname
              );

              const shallowCopyItem = { ...item1 };
              console.log("______shallowCopyItem", shallowCopyItem);

              const filteredFields = shallowCopyItem.fields.filter(
                (_, idx) => idx === index
              );

              shallowCopyItem.fields = filteredFields;

              processedTestData.push(shallowCopyItem);
              console.log("----lipid profile");

              if (tname === "pct") {
                const samplepct = {
                  fields: [
                    {
                      fieldName: "samplePCT",
                    },
                  ],
                  testFieldName: "Infection Marker",
                };
                processedTestData.push(samplepct);
              } else if (tname === "lipidprofile") {
                console.log("----lipid profile");
                const samplelipidprofile = {
                  fields: [
                    {
                      fieldName: "samplelipidprofile",
                    },
                  ],
                  testFieldName: "cardiac",
                };
                processedTestData.push(samplelipidprofile);
              }

              if (index !== -1) {
                console.log(typeof item1);
                const updatedItem = {
                  ...item1,
                  fields: [
                    ...item1.fields.slice(0, index),
                    ...item1.fields.slice(index + 1),
                  ],
                };
                j--;
                item1 = updatedItem;
                console.log("item1 =", item1);
                console.log("Updated fields after removal:", updatedItem);
              }
            }

            const fname = item2.fieldName.toLowerCase().replace(/\s+/g, "");
            fieldName.push(fname);
          }
        }
        const item1Size = item1.fields?.length ?? 0;

        if (item1Size <= 10 && item1Size > 0) {
          processedTestData.push(item1);
        } else {
          const item = item1;
          const fieldsSize = item1Size;
          if (fieldsSize > 10) {
            console.log(item1);
            console.log(item1.fields.length);
            let total = item1.fields.length;
            let count = 0;
            for (let k = 0; k < total; k++) {
              if (
                item1.fields[k].parentFieldName === "Complete Renal Profile"
              ) {
                count += 1;
              }
            }
            console.log(count, "&&", total);
            if (count === total) {
              processedTestData.push(item1);
            } else {
              // Split the fields array into two halves
              const halfSize = Math.ceil(fieldsSize / 2);
              const firstHalf = item.fields.slice(0, halfSize);
              const secondHalf = item.fields.slice(halfSize);

              // Create two new objects with split fields
              const firstPart = { ...item, fields: firstHalf };
              const secondPart = { ...item, fields: secondHalf };

              // Add the new objects to the processedTestData array
              processedTestData.push(firstPart, secondPart);
            }
          }
        }
      }
    }

    console.log("Final fieldName array:", fieldName);
    console.log("New Test Data Array:", processedTestData);
  } else {
    console.log("No test data available.");
  }

  // Split large field arrays

  // for (let i = 0; i < testData.length; i++) {
  //   const item = testData[i];
  //   const fieldsSize = item.fields?.length ?? 0;

  //   if (fieldsSize > 10) {
  //     // Split the fields array into two halves
  //     const halfSize = Math.ceil(fieldsSize / 2);
  //     const firstHalf = item.fields.slice(0, halfSize);
  //     const secondHalf = item.fields.slice(halfSize);

  //     // Create two new objects with split fields
  //     const firstPart = { ...item, fields: firstHalf };
  //     const secondPart = { ...item, fields: secondHalf };

  //     // Add the new objects to the processedTestData array
  //     processedTestData.push(firstPart, secondPart);
  //   }
  // }
  const submitTestResult = async () => {
    const url = "https://api.superceuticals.in/test/add-testResult";
    const body = {
      PatientID: patientDetails.PatientID,
      RefferedBy: "me",
      Ckmb: "1",
      Tnl: "1",
      Dimer: "1",
      proBnp: "1",
      hsCRP: "1",
      TechnicianID: "TECH433853",
      CentreID: "CEN18781001",
      MachineID: "MC0002",
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Test result submitted successfully:", result);
    } catch (error) {
      console.error("Error submitting test result:", error);
    }
  };
  console.log("Processed Test Data:", processedTestData);

  // Ref to the specific part (Patient Report) you want to print
  const patientReportRef = useRef();

  // Handle Share functionality (only share the report section as PDF)
  const handleShare = () => {
    const element = patientReportRef.current;

    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5],
      filename: "report.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: {
        scale: 4,
      },
      jsPDF: {
        unit: "in",
        format: "a4",
        orientation: "portrait",
      },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    };

    html2pdf()
      .set(opt)
      .from(element)
      .outputPdf("blob") // Generate PDF as Blob
      .then((pdfBlob) => {
        // Create a File object from the Blob
        const filesArray = [
          new File([pdfBlob], "report.pdf", {
            type: "application/pdf",
            lastModified: new Date().getTime(),
          }),
        ];

        // Check if the browser supports sharing files
        if (navigator.canShare && navigator.canShare({ files: filesArray })) {
          navigator
            .share({
              files: filesArray,
              title: "Patient Report",
              text: "Here is the patient report.",
            })
            .then(() => console.log("Share was successful."))
            .catch((error) => console.log("Sharing failed", error));
        } else {
          alert("Your device does not support sharing files.");
        }
      })
      .catch((error) => console.log("Error generating PDF", error));
  };

  const handlePdfUpload = () => {
    const element = patientReportRef.current;

    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5],
      filename: "report.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 4 },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };

    html2pdf()
      .set(opt)
      .from(element)
      .outputPdf("blob")
      .then((pdfBlob) => {
        // Convert the PDF Blob to a Base64 string
        const reader = new FileReader();
        reader.readAsDataURL(pdfBlob);
        reader.onloadend = () => {
          const base64String = reader.result.replace(
            "data:application/pdf;base64,",
            ""
          );
          // console.log("__________________ base^4 ",base64String);
          const postData = {
            pdf: base64String,
          };
          // console.log("+++++++++++ post data",postData);

          // Replace id with the actual id you want to use
          const reportId = patientDetails.PatientID;

          // Make the POST request
          axios
            .post(
              `https://api.superceuticals.in/report/add-report/${reportId}`,
              { postData },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization:
                    "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoiVEVTVE5PVyIsIlBhdGllbnRJRCI6IlNDMjNGMDAwMDAyIiwiY29udGFjdE51bWJlciI6Ijg4MDU5OTEwNzkiLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE3Mjk1OTI1NDZ9.nxGwqh-dGY4fpdbHLeq7Cli8Hb8xMdnNfvmECkW-CQs",
                },
              }
            )
            .then((response) => {
              const data = response.data;
              if (data.error) {
                console.error("Error uploading report:", data.message);
              } else {
                console.log("Report uploaded successfully:", data.message);
              }
            })
            .catch((error) => {
              console.error("Error uploading PDF:", error);
            });
        };
      })
      .catch((error) => console.log("Error generating PDF", error));
  };

  return (
    <>
      {/* Printable content: PatientReport components */}
      <div ref={patientReportRef}>
        {processedTestData.map((item, index) => (
          <div
            key={index}
            className="bg-[#C7CEE5] justify-center items-center flex min-h-fit py-10 px-6 z-10 relative"
            style={{ breakAfter: "page" }}
          >
            {/* Report Page PDF Viewer */}
            <div className="bg-white z-10 printable relative h-fit w-[1000px] mb-1 mx-auto">
              <PatientReport
                patientDetails={patientDetails}
                testResults={item} // Passing dynamic test results
              />
            </div>
            {/* Non-printable content: Buttons and Help Icon */}
            <div className="fixed bottom-8 right-8 lg:right-20 ">
              {/* Download button */}
              <ReactToPrint
                trigger={() => (
                  <img
                    src={download}
                    alt="Download PDF"
                    className="w-10 lg:w-12 cursor-pointer mb-4"
                  />
                )}
                content={() => patientReportRef.current}
                documentTitle="Patient Report"
              />
              <img
                src={share}
                alt="Share PDF"
                className="w-10 lg:w-12 cursor-pointer mb-4"
                onClick={handleShare}
              />
              {/* Share button */}
              <img
                src={whatsapp} // New icon
                alt="Upload PDF"
                className="w-10 lg:w-12 cursor-pointer mb-4"
                onClick={handlePdfUpload} // Call the new function
              />
              {/* Help Icon */}
              <Link to="../faq">
                <img src={help} alt="helpicon" className="w-10 lg:w-12" />
              </Link>
            </div>
          </div>
        ))}
      </div>

      {/* CSS Styles */}
      <style jsx>{`
        @media print {
          body * {
            visibility: hidden;
          }
          .printable,
          .printable * {
            visibility: visible;
          }
          .printable {
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      `}</style>
    </>
  );
}
