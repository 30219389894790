import { createSlice } from "@reduxjs/toolkit";

const diseaseSlice = createSlice({
  name: "diseaseSlice",
  initialState: {
    diseaseData: null
  },
  reducers: {
    addDiseaseData: (state, action) => {
      state.diseaseData = action.payload;
    },
    updateRiskScoreAPI: (state, action) => {
      state.riskscoreAPI = action.payload.riskscoreAPI;
    },
  },
});

export const { addDiseaseData ,updateRiskScoreAPI  } = diseaseSlice.actions;
export default diseaseSlice.reducer;
